import FetcherFactory from 'utils/FetcherFactory';
import { Knowledge } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchKnowledgeResponse = {
  data: Knowledge;
};
const fetchKnowledge = async (id: number): Promise<FetchKnowledgeResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withPublicToken().get(`/api/knowledges/${id}`, {
    'populate[cover][populate]': '*',
    'populate[localizations][populate][0]': 'title',
    'populate[localizations][populate][1]': 'content',
    'populate[localizations][populate][2]': 'locale',
  });
  const data = await res.json();
  return data;
};

export default fetchKnowledge;
