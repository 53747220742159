import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Dialog, Classes } from '@blueprintjs/core';

import { TeamProfile } from '@type';
import { fetchTeamProfiles } from 'api/teamProfiles';
import AppToaster from 'components/AppToaster';
import { MEDIA_LG, MEDIA_SM, MEDIA_MD } from 'styles/media';
import { locale2Lang } from 'utils/i18n';

import TeamProfileCard from './TeamProfileCard';

const Container = styled.div`
  text-align: center;
  position: relative;
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 480px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;
const CardContainer = styled.div`
  margin: auto;
  margin-top: 92px;
  position: relative;
  min-height: 226px;
  display: grid;
  gap: 46px;
  grid-template-columns: repeat(4, 1fr);

  &.fewer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    max-width: 300px;
    flex: 1;
    height: auto;
  }

  @media ${MEDIA_LG} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${MEDIA_MD} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 54px;
    padding: 0 40px;
  }

  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
    padding: 0 30px;
    grid-gap: 76px;
  }
`;
const StyledDialog = styled(Dialog)`
  white-space: pre-line;
  padding: 30px 20px;
  width: 50%;
  ${({ theme }) => theme.text.cardDescription};

  div {
    line-height: 30px;
  }

  @media ${MEDIA_MD} {
    width: 70%;
  }

  @media ${MEDIA_SM} {
    padding: 20px 10px;
    width: 85%;
  }
`;

type Props = {
  className?: string;
};
const TeamSection: FC<Props> = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState<TeamProfile[]>([]);
  const [content, setContent] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await fetchTeamProfiles({ size: 100 });
        setProfiles(result.data);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Container className={className}>
      <Header>{t('sections.team.header')}</Header>
      <Title>{t('sections.team.title')}</Title>
      <Description>{t('sections.team.description')}</Description>
      <CardContainer
        className={classnames({
          'bp4-skeleton': loading,
          fewer: profiles.length < 4,
        })}
      >
        {profiles.map((p, idx) => {
          const langData = p.attributes.localizations?.data?.find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <TeamProfileCard
              key={p.id}
              className="card"
              name={langData?.attributes?.name || p.attributes.name}
              title={langData?.attributes?.title || p.attributes.title}
              description={
                langData?.attributes?.description || p.attributes.description
              }
              profilePic={p.attributes.profile_pic?.data?.attributes?.url}
              delay={`${(idx % 4) * 100}ms`}
              onMoreClick={setContent}
            />
          );
        })}
      </CardContainer>
      <StyledDialog isOpen={Boolean(content)} onClose={() => setContent('')}>
        <div className={Classes.DIALOG_BODY}>{content}</div>
      </StyledDialog>
    </Container>
  );
};

export default TeamSection;
