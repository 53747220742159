const SKIP_REMINDER_KEY = 'SKIP_REMINDER';

class ReminderMemo {
  cache: Record<number, string> = {};

  constructor() {
    this.cache = JSON.parse(localStorage.getItem(SKIP_REMINDER_KEY) || '{}');
  }

  shouldShowReminder(docId: number, updateTime: string): boolean {
    const ver = this.cache[docId];
    if (!ver) return true;
    return ver < updateTime;
  }

  skip(docId: number) {
    this.cache[docId] = new Date().toISOString();
    localStorage.setItem(SKIP_REMINDER_KEY, JSON.stringify(this.cache));
  }
}

export default new ReminderMemo();
