import { FC, useEffect } from 'react';
import styled from 'styled-components';

import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import {
  AccountSection,
  ChangePasswordSection,
  AccountBalanceSection,
  PaymentHistorySection,
} from 'containers';
import Tracker from 'utils/tracker';
import { useRefreshMe } from 'hooks';

const Container = styled.div`
  padding: 0 56px;
  padding-bottom: 170px;
  flex: 1;
  min-width: 0;

  > * + * {
    margin-top: 60px;

    @media ${MEDIA_MD} {
      margin-top: 28px;
    }
  }

  @media ${MEDIA_MD} {
    margin-top: 89px;
  }

  @media ${MEDIA_SM} {
    margin-top: 69px;
    padding: 0 10px;
    padding-bottom: 80px;
  }
`;

const UserAccountPage: FC<{}> = () => {
  const refreshMe = useRefreshMe();
  useEffect(() => {
    Tracker.track('Visited My Account');
    refreshMe();
  }, []);
  return (
    <Container>
      <AccountSection />
      <ChangePasswordSection />
      <AccountBalanceSection />
      <PaymentHistorySection />
    </Container>
  );
};

export default UserAccountPage;
