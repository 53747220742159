import FetcherFactory from 'utils/FetcherFactory';
import { OpenRoles } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchOpenRoleResponse = {
  data: OpenRoles;
};
const fetchOpenRole = async (id: number): Promise<FetchOpenRoleResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withPublicToken().get(`/api/open-roles/${id}`, {
    'populate[cover][populate]': '*',
    'populate[localizations][populate][0]': 'title',
    'populate[localizations][populate][1]': 'content',
    'populate[localizations][populate][2]': 'locale',
  });
  const data = await res.json();
  return data;
};

export default fetchOpenRole;
