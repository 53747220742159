import { FC, useCallback, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import FormField from './FormField';

const StyledFormField = styled(FormField)`
  width: 360px;
  flex: 0 !important;
`;

const Container = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  canvas,
  img,
  div {
    width: 360px;
    height: 100px;
    background: white;
    border: 1px solid #dddfe1;
    border-radius: 8px;
  }
`;
const DummyMask = styled.div`
  width: 360px;
  height: 100px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  cursor: not-allowed;
`;

const canvasProps = { width: 360, height: 100 };
type Props = {
  className?: string;
  label?: React.ReactNode;
  name: string;
  touched?: boolean;
  error?: string;
  readonly?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: (n: string, v: string) => void;
  onBlur?: (n: string) => void;
};
const SignatureField: FC<Props> = ({
  className,
  label,
  name,
  touched,
  error,
  readonly,
  disabled,
  value,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation();
  const ref = useRef<SignatureCanvas>(null);
  const handleEnd = useCallback(() => {
    if (ref.current && onChange) {
      const v = ref.current.toDataURL();
      onChange(name, v);
    }
    if (onBlur) onBlur(name);
  }, [onChange, name, onBlur]);
  const handleClear = useCallback(() => {
    ref.current?.clear();
    if (onChange) onChange(name, '');
  }, [onChange, name]);

  const RenderRead = useMemo(
    () =>
      function renderRead() {
        return (
          <Container>
            {value ? <img src={value} alt={name} /> : <div />}
          </Container>
        );
      },
    [value, name],
  );

  useEffect(() => {
    if (!value) return;
    const current = ref.current?.toDataURL();
    if (current !== value) ref.current?.fromDataURL(value, canvasProps);
  }, [value]);

  return (
    <StyledFormField
      className={className}
      label={label}
      labelSuffix={
        !readonly && (
          <Button
            minimal
            intent="success"
            onClick={handleClear}
            disabled={disabled}
            text={t('actions.clear')}
          />
        )
      }
      name={name}
      touched={touched}
      error={error}
      readonly={readonly}
      disabled={disabled}
      renderRead={RenderRead}
    >
      <Container>
        <SignatureCanvas
          ref={ref}
          canvasProps={canvasProps}
          onEnd={handleEnd}
        />
        {disabled && <DummyMask />}
      </Container>
    </StyledFormField>
  );
};

export default SignatureField;
