import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const forgetPassword = async (email: string): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.json().post(`/api/public-user/forgetPassword`, {
    email,
  });
  const data = await res.json();
  return data;
};

export default forgetPassword;
