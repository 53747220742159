import { FC } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AppToaster, Button, TextField } from 'components';
import OrderDetailBlock from './OrderDetailBlock';
import PromoBlock from './PromoBlock';

const Form = styled.form`
  position: relative;
  display: flex;
  gap: 30px;
`;

const LeftBlock = styled.div`
  flex: 4;
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.0809);
  border-radius: 100px 24px 24px 24px;
  padding: 70px 54px 54px 75px;

  > * + * {
    margin-top: 45px;
  }
`;
const Title = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme }) => theme.primary.text};
  margin-bottom: 18px;
`;
const RightBlock = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledPromoBlock = styled(PromoBlock)`
  margin-top: 20px;
`;
const ActionBar = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 24px;
`;
const BottomButton = styled(Button)`
  flex: 1;
`;
const BillingDetails = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
`;
const LongTextField = styled(TextField)`
  grid-column: 1/4;
`;

const schema = yup.object().shape({
  name: yup.string().required('* Required'),
  line1: yup.string().required('* Required'),
  city: yup.string(),
  state: yup.string(),
  zip: yup.string().required('* Required'),
});

type Props = {
  className?: string;
  subsId: string;
  isSetup: boolean;
  planId: number;
};
const PaymentForm: FC<Props> = ({ className, subsId, isSetup, planId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { values, touched, errors, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: '',
        line1: '',
        city: '',
        state: '',
        zip: '',
      },
      validationSchema: schema,
      onSubmit: async (data) => {
        if (elements == null) {
          return;
        }
        const params = {
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/user/payments/${subsId}/complete`,
            payment_method_data: {
              billing_details: {
                name: data.name,
                address: {
                  line1: data.line1,
                  city: data.city,
                  state: data.state,
                  postal_code: data.zip,
                },
              },
            },
          },
        };
        let error;
        if (isSetup) {
          error = (await stripe!.confirmSetup(params)).error;
        } else {
          error = (await stripe!.confirmPayment(params)).error;
        }
        if (error) {
          AppToaster.error(error?.message || 'Strapi Error');
          console.error(error);
        }
      },
    });

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <LeftBlock>
        <div>
          <Title>{t('forms.paymentForm.title')}</Title>
          <PaymentElement />
        </div>
        <BillingDetails>
          <Title>{t('forms.paymentForm.billingTitle')}</Title>
          <LongTextField
            name="name"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('forms.paymentForm.name')}
          />
          <LongTextField
            name="line1"
            value={values.line1}
            touched={touched.line1}
            error={errors.line1}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('forms.paymentForm.line1')}
          />
          <TextField
            name="city"
            value={values.city}
            touched={touched.city}
            error={errors.city}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('forms.paymentForm.city')}
          />
          <TextField
            name="state"
            value={values.state}
            touched={touched.state}
            error={errors.state}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('forms.paymentForm.state')}
          />
          <TextField
            name="zip"
            value={values.zip}
            touched={touched.zip}
            error={errors.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('forms.paymentForm.zip')}
          />
        </BillingDetails>
      </LeftBlock>
      <RightBlock>
        <div>
          <OrderDetailBlock planId={planId} />
          <StyledPromoBlock />
        </div>
        <ActionBar>
          <BottomButton
            type="button"
            text={t('forms.paymentForm.cancel')}
            round
            intent="success"
            outlined
            onClick={() => navigate('/user/payments')}
          />
          <BottomButton
            type="submit"
            text={t('forms.paymentForm.submit')}
            round
            intent="success"
          />
        </ActionBar>
      </RightBlock>
    </Form>
  );
};

export default PaymentForm;
