import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDocumentCategories } from 'api';
import { addDocumentCategories, getAllCategory } from 'state/documents';
import { AppToaster } from 'components';

const useRefreshDocumentCate = () => {
  const dispatch = useDispatch();
  const categories = useSelector(getAllCategory);
  const [loading, setLoading] = useState(false);

  const refreshDocumentCate = useCallback(async () => {
    try {
      setLoading(true);
      const result = await fetchDocumentCategories();
      dispatch(addDocumentCategories(result || []));
    } catch (e: any) {
      AppToaster.apiError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    categories,
    refreshDocumentCate,
  };
};

export default useRefreshDocumentCate;
