import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, InputAdornment } from '@mui/material';
import { Button } from '@blueprintjs/core';
import { Link, useSearchParams } from 'react-router-dom';

import { MEDIA_SM, MEDIA_MD } from 'styles/media';
import { resetPassword } from 'api';
import { AppToaster } from 'components';

import useRedirectUserEffect from './useRedirectUserEffect';

const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 18px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin-bottom: 0;
  }
`;
const Description = styled.p`
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #222222;
  opacity: 0.4;
`;

const InputField: typeof TextField = styled(TextField)`
  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;
const Form = styled.form`
  margin-top: 50px;
  color: ${({ theme }) => theme.primary.text};
  max-width: 360px;

  @media ${MEDIA_MD} {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 80px;
  }

  ${InputField as any} + ${InputField as any} {
    margin-top: 46px;
  }
`;
const SubmitButton = styled(Button)`
  margin-top: 28px;

  &.bp4-button {
    height: 60px;
    background: ${({ theme }) => theme.success.text};
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
  }

  &.bp4-button:hover {
    background: ${({ theme }) => theme.success.textHover};
  }
`;
const LeftFoot = styled.div`
  margin-top: 40px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #abafb3;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
`;
const SuccessBlock = styled.div`
  max-width: 360px;
  margin-top: 60px;
`;
const SuccessMessage = styled.div`
  ${({ theme }) => theme.text.description};
`;
const ReturnLink = styled(Link)`
  display: block;
  margin-top: 24px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffae10 !important;
`;

// eslint-disable-next-line complexity
const LoginPage: FC = () => {
  const [params] = useSearchParams();
  const token = params.get('token') || '';
  const { t, i18n } = useTranslation();
  const [done, setDone] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup
          .string()
          .required(t('validate.required', { ns: 'errors' }))
          .min(8),
        confirmPassword: yup
          .string()
          .required(t('validate.required', { ns: 'errors' }))
          .oneOf(
            [yup.ref('password')],
            t('validate.samePassword', { ns: 'errors' }),
          ),
      }),
    [i18n.language],
  );

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        password: '',
        confirmPassword: '',
      },
      validateOnChange: true,
      validationSchema: schema,
      onSubmit: async ({ password }) => {
        try {
          setLoading(true);
          await resetPassword(token, password);
          setDone(true);
        } catch (e: any) {
          AppToaster.apiError(e);
        } finally {
          setLoading(false);
        }
      },
    });
  useRedirectUserEffect();

  return (
    <>
      <Title>{t('pages.resetPasswordPage.title')}</Title>
      <Description>{t('pages.resetPasswordPage.description')}</Description>
      {done ? (
        <SuccessBlock>
          <SuccessMessage>{t('forms.resetPassword.success')}</SuccessMessage>
          <ReturnLink to="/login">
            {t('forms.forgetPassword.return')}
          </ReturnLink>
        </SuccessBlock>
      ) : (
        <Form onSubmit={handleSubmit}>
          <InputField
            type={visible ? 'text' : 'password'}
            variant="outlined"
            label={t('forms.resetPassword.password')}
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            fullWidth
            helperText={touched.password ? errors.password : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    minimal
                    onClick={() => setVisible((v) => !v)}
                    icon={visible ? 'eye-open' : 'eye-off'}
                  />
                </InputAdornment>
              ),
            }}
          />
          <InputField
            type={visible2 ? 'text' : 'password'}
            variant="outlined"
            label={t('forms.resetPassword.confirmPassword')}
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword ? errors.confirmPassword : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    minimal
                    onClick={() => setVisible2((v) => !v)}
                    icon={visible2 ? 'eye-open' : 'eye-off'}
                  />
                </InputAdornment>
              ),
            }}
          />
          <SubmitButton
            type="submit"
            large
            fill
            loading={loading}
            text={t('forms.resetPassword.submit')}
          />
          <LeftFoot>{t('forms.resetPassword.footer')}</LeftFoot>
        </Form>
      )}
    </>
  );
};

export default LoginPage;
