import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MEDIA_LG } from 'styles/media';
import * as img from 'assets/images';

import BulletBlock from '../BulletBlock';

const StyledBulletBlock = styled(BulletBlock)`
  .media-box {
    background: transparent;
    overflow: hidden;

    .inner-media {
      background: #ffd075;
    }
  }

  @media ${MEDIA_LG} {
    .text-box {
      background: #ffd075;
    }
  }
`;

type Props = {
  className?: string;
};
const FinancialSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <StyledBulletBlock
      ltr={false}
      className={className}
      header={t('sections.financial.header')}
      title={t('sections.financial.title')}
      description={t('sections.financial.description')}
      Media={<img src={img.serviceFinancial} alt="financial" />}
    />
  );
};

export default FinancialSection;
