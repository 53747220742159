import { FC, useState, useEffect } from 'react';
import { Store } from 'redux';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import { Spinner } from '@blueprintjs/core';

import ErrorBoundary from './components/ErrorBoundary';
import AppToaster from './components/AppToaster';
import AppModal from './components/AppModal';
import InitBlock from './containers/InitBlock';
import ChatWidget from './containers/ChatWidget';
import { createStore, RootState } from './state/store';
import { Provider as ThemeProvider } from './styles/theme';
import { WrappedBrowserRouter } from './utils/history';
import './utils/i18n';
import AppRoute from './routes';

const Container = styled.div`
  background: ${({ theme }) => theme.primary.background};
  color: ${({ theme }) => theme.primary.text};
  min-height: 100%;
  width: 100%;
`;

const App: FC<{}> = () => {
  const [store, setStore] = useState<Store<RootState> | null>(null);

  useEffect(() => {
    if (store) return;
    (async () => {
      try {
        const s = await createStore();
        setStore(s);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [store]);

  if (!store) return <Spinner />;

  return (
    <Provider store={store}>
      <ThemeProvider>
        <ErrorBoundary>
          <Container>
            <InitBlock />
            {ReactDOM.createPortal(
              <ChatWidget />,
              document.getElementById('bubble-box') || document.body,
            )}
            <WrappedBrowserRouter>
              <AppRoute />
            </WrappedBrowserRouter>
          </Container>
          <AppToaster />
          <AppModal />
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
