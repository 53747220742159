import {
  FC,
  useCallback,
  FormEvent,
  ReactNode,
  useMemo,
  Fragment,
} from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import { FormErrors, FormTouched, Question } from '@type';
import { MEDIA_SM } from 'styles/media';

import Checkbox from '../inputs/Checkbox';
import Radio from '../inputs/Radio';
import FormField from './FormField';
import InfoTooltip from '../InfoTooltip';
// eslint-disable-next-line import/no-cycle
import QuestionRender from './QuestionRender';

const StyledFormField = styled(FormField)`
  .question-render {
    padding-top: 8px;
    padding-left: 32px;
  }

  &.form-field--readonly .question-render {
    padding-top: 0;
    padding-left: 16px;
  }
`;
const StyledRadio = styled(Radio)``;
const StyledCheckBox = styled(Checkbox)``;
const StyledRadioGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  > ${StyledRadio}
    + ${StyledRadio},
    > ${StyledCheckBox}
    + ${StyledCheckBox},
    > .question-render
    + ${StyledRadio},
    > .question-render
    + ${StyledCheckBox} {
    margin-top: 24px !important;

    @media ${MEDIA_SM} {
      margin-top: 12px !important;
    }
  }
`;
const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 4px;
`;

const Value = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }

  ${({ theme }) => theme.text.previewText};
`;
const ValueGroup = styled.div``;

type Props<T = any> = {
  className?: string;
  label?: ReactNode;
  name: string;
  subName: string;
  touched?: FormTouched<T>;
  error?: FormErrors<T>;
  readonly?: boolean;
  disabled?: boolean;
  value?: any;
  multi?: boolean;
  options?: {
    label: string;
    value: T;
    namePrefix: string | null;
    question: {
      data: Question | null;
    };
    tooltip?: null | string;
  }[];
  langOptions?: {
    tooltip?: null | string;
    label: string;
    value: T;
  }[];
  allLangOptions?: Record<
    string,
    {
      label: string;
      value: T;
    }[]
  >;
  onChange?: (n: string, v: T) => void;
  onBlur?: (n: string) => void;
  onClear?: (n: string) => void;
};
const RadioField: FC<Props> = ({
  className,
  label,
  name,
  subName,
  touched,
  error,
  readonly,
  disabled,
  value,
  multi,
  options = [],
  langOptions = [],
  allLangOptions,
  onChange,
  onBlur,
  onClear,
}) => {
  const radioName = [name, subName].filter(Boolean).join('.');
  const radioValue: any[] = value?.[subName] || [];

  const populateLangData = useCallback(
    (vs: string[]) => {
      if (!allLangOptions) return;
      const temp: {
        value: any;
        label?: string;
        locale: string;
      }[] = [];
      vs.forEach((v) => {
        temp.push({
          label: options.find((o) => o.value === v)?.label,
          value: v,
          locale: 'en',
        });
      });
      Object.entries(allLangOptions).forEach(([locale, ops]) => {
        const lookup = ops.reduce((memo, o) => {
          // eslint-disable-next-line no-param-reassign
          memo[o.value] = o.label;
          return memo;
        }, {} as Record<string, string>);
        vs.forEach((v) => {
          if (lookup[v]) {
            temp.push({ label: lookup[v], value: v, locale });
          }
        });
      });
      onChange?.(`%locale%.${radioName}`, temp);
    },
    [allLangOptions, onChange, radioName, options],
  );

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const v = e.currentTarget.value;
      if (onChange) {
        if (multi) {
          let next = radioValue.filter((x) => x !== v);
          if (e.currentTarget.checked) {
            next = [...next, v];
          }
          onChange(e.currentTarget.name, next);
          populateLangData(next);
        } else {
          onChange(e.currentTarget.name, [v]);
          populateLangData([v]);
        }
      }
      // At the time blur, value is not set
      // use event cycle to trigger setFieldTouced
      const n = e.currentTarget.name;
      if (onBlur) setTimeout(() => onBlur(n), 0);
    },
    [onChange, radioValue, multi, populateLangData],
  );

  const Comp = multi ? StyledCheckBox : StyledRadio;

  const langLookup = langOptions.reduce((memo, o) => {
    // eslint-disable-next-line no-param-reassign
    memo[o.value] = o.label;
    return memo;
  }, {} as Record<string, string>);
  const tooltipLookup = langOptions.reduce((memo, o) => {
    // eslint-disable-next-line no-param-reassign
    memo[o.value] = o.tooltip;
    return memo;
  }, {} as Record<string, string | null | undefined>);

  const RenderRead = useMemo(() => {
    return function renderRead() {
      return (
        <ValueGroup>
          {radioValue.map((r) => {
            const selected = options.find((o) => o.value === r);
            return (
              <Fragment key={r}>
                <Value>{langLookup[r] || selected?.label || r}</Value>
                {selected?.question?.data?.id && (
                  <QuestionRender
                    className="question-render"
                    key={selected.question.data.id}
                    questionId={selected.question.data.id}
                    prefix={selected.namePrefix || ''}
                    name={`${radioName}$Nested`}
                    value={get(value, `${subName}$Nested`)}
                    readonly={readonly}
                    disabled={disabled}
                  />
                )}
              </Fragment>
            );
          })}
        </ValueGroup>
      );
    };
  }, []);

  return (
    <StyledFormField
      className={className}
      label={label}
      name={radioName}
      touched={touched?.[subName] as boolean}
      error={error?.[subName] as string}
      readonly={readonly}
      disabled={disabled}
      renderRead={RenderRead}
    >
      <StyledRadioGroup>
        {options.map((o) => (
          <Fragment key={o.value}>
            <Comp
              key={o.value}
              name={radioName}
              intent="success"
              checked={radioValue?.includes?.(o.value)}
              label={
                <span>
                  <span>{langLookup[o.value] || o.label}</span>
                  {Boolean(o.tooltip) && (
                    <StyledInfoTooltip
                      content={String(tooltipLookup[o.value] || o.tooltip)}
                    />
                  )}
                </span>
              }
              value={o.value}
              onChange={(e) => {
                handleChange(e);

                if (!multi) {
                  onClear?.(`${radioName}$Nested`);
                } else if (o.question?.data && !e.currentTarget.checked) {
                  const p = [
                    `${radioName}$Nested`,
                    `${o.namePrefix || ''}${o.question?.data.attributes.name}`,
                  ]
                    .filter(Boolean)
                    .join('.');
                  onClear?.(p);
                }
              }}
              disabled={disabled || readonly}
            />
            {o.question?.data?.id && (
              <QuestionRender
                className="question-render"
                key={o.question.data.id}
                questionId={o.question.data.id}
                prefix={o.namePrefix || ''}
                name={`${radioName}$Nested`}
                touched={get(touched, `${subName}$Nested`) as any}
                error={get(error, `${subName}$Nested`) as any}
                value={get(value, `${subName}$Nested`)}
                onBlur={onBlur}
                onChange={onChange}
                onClear={onClear}
                readonly={readonly}
                disabled={disabled || !radioValue?.includes?.(o.value)}
              />
            )}
          </Fragment>
        ))}
      </StyledRadioGroup>
    </StyledFormField>
  );
};

export default RadioField;
