import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { WrappedPlayer } from 'components';
import ThumbsUpBlock from '../ThumbsUpBlock';

const StyledThumbsUpBlock = styled(ThumbsUpBlock)`
  .main {
    background: linear-gradient(135deg, #e3fefb 0%, #e6f7e2 100%);
  }
`;

type Props = {
  className?: string;
};
const SMESection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <StyledThumbsUpBlock
      className={className}
      header={t('sections.sme.header')}
      title={t('sections.sme.title')}
      description={t('sections.sme.description')}
      Media={
        <WrappedPlayer
          width="100%"
          height="100%"
          url={t('sections.sme.videoUrl')}
        />
      }
    />
  );
};

export default SMESection;
