import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { TextField, TextAreaField, Button, AppToaster } from 'components';
import { sendFeedback } from 'api';
import { MEDIA_SM } from 'styles/media';

const Form = styled.form`
  position: relative;

  @media ${MEDIA_SM} {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const Content = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;
const Description = styled.div`
  ${({ theme }) => theme.text.cardDescription};
`;
const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

type Props = {
  className?: string;
  afterSend?: () => void;
};
const FeedbackForm: FC<Props> = ({ className, afterSend }) => {
  const { t, i18n } = useTranslation();
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .required(t('validate.required', { ns: 'errors' })),
        name: yup.string().required(t('validate.required', { ns: 'errors' })),
        message: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
      }),
    [i18n.language],
  );
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
        name: '',
        message: '',
      },
      validationSchema: schema,
      onSubmit: async (data, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          await sendFeedback(data);
          AppToaster.success(t('toaster.feedback.success'));
          resetForm();
          afterSend?.();
        } catch (e: any) {
          AppToaster.apiError(e);
        } finally {
          setSubmitting(false);
        }
      },
    });

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <Content>
        <Description>{t('feedback.description')}</Description>
        <TextField
          name="email"
          label={t('feedback.email')}
          value={values.email}
          touched={touched.email}
          error={errors.email}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextField
          name="name"
          label={t('feedback.name')}
          value={values.name}
          touched={touched.name}
          error={errors.name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <TextAreaField
          name="message"
          label={t('feedback.message')}
          value={values.message}
          touched={touched.message}
          error={errors.message}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Content>
      <SubmitButton type="submit" intent="success" text={t('actions.send')} />
    </Form>
  );
};

export default FeedbackForm;
