import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { OpenRolesSection, LifeOfficeSection } from 'containers';

import topImg from 'assets/images/career_top_image.webp';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import { useMedia } from 'react-use';
import Tracker from 'utils/tracker';

const Container = styled.div`
  position: relative;
  z-index: 1;
`;
const FrontBlock = styled.div`
  padding-top: 180px;
  text-align: center;

  @media ${MEDIA_SM} {
    padding: 0;
  }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 648px;
  background: linear-gradient(135deg, #e3fefb 0%, #e6f7e2 100%);
  z-index: -1;
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;

const Img = styled.div`
  background-image: url(${topImg});
  width: 390px;
  height: 490px;
  background-size: cover;
  filter: drop-shadow(0px 24px 48px rgba(0, 24, 52, 0.0809));
  border-radius: 24px 100px 24px 24px;
`;
const MainContent = styled.div`
  position: relative;
  margin: auto;
  margin-top: 56px;
  display: flex;
  justify-content: center;

  > ${Img} {
    border-radius: 24px 100px 24px 24px;
    margin-top: 70px;
    margin-left: 4px;
    object-fit: cover;
  }

  @media ${MEDIA_MD} {
    flex-direction: column-reverse;
    > ${Img} {
      height: 350px;
      width: 100%;
      margin: 0;
      background-size: cover;
      background-position: 50%;
    }
  }

  @media ${MEDIA_SM} {
    margin: 0;

    > ${Img} {
      border-radius: 0;
      margin: 0;
      height: 300px;
    }
  }
`;
const MainCard = styled.div`
  display: inline-block;
  max-width: 408px;
  background: #ffffff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.0809);
  border-radius: 100px 24px 24px 24px;
  padding: 106px 44px;
  font-family: 'Open Sans';
  color: ${({ theme }) => theme.primary.label};
  text-align: left;
  height: fit-content;

  > div {
    font-weight: 700;
    font-size: 44px;
    line-height: 48px;
    text-transform: uppercase;

    @media ${MEDIA_SM} {
      font-size: 22px;
      line-height: 28px;
    }
  }

  > p {
    margin-top: 16px;
    font-size: 20px;
    line-height: 28px;

    @media ${MEDIA_SM} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media ${MEDIA_MD} {
    max-width: 100%;
    padding: 80px 44px;
  }

  @media ${MEDIA_SM} {
    background: linear-gradient(135deg, #e3fefb 0%, #e6f7e2 100%);
    box-shadow: none;
    border-radius: 0px 0px 40px 40px;
    padding: 30px 20px;
    text-align: center;
  }
`;
const StyledOpenRolesSection = styled(OpenRolesSection)`
  margin-top: 120px;
  margin-bottom: 60px;

  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;
const StyledLifeOfficeSection = styled(LifeOfficeSection)`
  margin-top: 120px;
  margin-bottom: 60px;

  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;

const CareerPage: FC<{}> = () => {
  const { t } = useTranslation();
  const isMobile = useMedia(MEDIA_SM);

  useEffect(() => {
    Tracker.track('Visited Career Page');
  }, []);

  return (
    <Container>
      <FrontBlock>
        {!isMobile && (
          <>
            <Background />
            <Header>{t('pages.career.header')}</Header>
            <Title>{t('pages.career.title')}</Title>
          </>
        )}
        <MainContent>
          <MainCard>
            {isMobile && (
              <>
                <Header>{t('pages.career.header')}</Header>
                <Title>{t('pages.career.title')}</Title>
              </>
            )}
            <div>{t('pages.career.cardTitle')}</div>
            <p>{t('pages.career.cardDescription')}</p>
          </MainCard>
          <Img />
        </MainContent>
      </FrontBlock>
      <StyledOpenRolesSection />
      <StyledLifeOfficeSection />
    </Container>
  );
};

export default CareerPage;
