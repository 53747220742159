import { FC, FormEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { Checkbox as BPCheckbox } from '@blueprintjs/core';
import classnames from 'classnames';

const StyledCheckbox = styled(BPCheckbox)`
  color: #5d737e;

  &.bp4-control {
    margin: 0;
    ${({ theme }) => theme.text.inputValue};
  }

  &.bp4-control .bp4-control-indicator {
    width: 26px;
    height: 26px;
    font-size: 26px;
    box-shadow: inset 0 0 0 1px currentColor;
  }

  &.warning.bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${({ theme }) => theme.warning.text};
  }

  &.success.bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${({ theme }) => theme.success.text};
  }
`;

type Props = {
  className?: string;
  name?: string;
  checked?: boolean;
  label?: ReactNode;
  disabled?: boolean;
  value?: any;
  intent?: 'warning' | 'success';
  onChange?: FormEventHandler<HTMLInputElement>;
};
const Checkbox: FC<Props> = ({
  className,
  name,
  checked,
  label,
  value,
  intent = 'warning',
  onChange,
  disabled,
}) => (
  <StyledCheckbox
    className={classnames(className, intent)}
    name={name}
    checked={checked}
    labelElement={label}
    value={value}
    disabled={disabled}
    onChange={onChange}
  />
);

export default Checkbox;
