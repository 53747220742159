import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { whoami } from 'api/publicUsers';
import AppToaster from 'components/AppToaster';
import { fetchMeSuccess } from 'state/app';
import history from 'utils/history';
import Tracker from 'utils/tracker';

const useRefreshMe = () => {
  const dispatch = useDispatch();
  const refreshMe = useCallback(async () => {
    try {
      const user = await whoami();
      Tracker.identify(user);
      dispatch(fetchMeSuccess(user));
      if (!user.first_name && history.location.pathname !== '/kyc') {
        history.push('/kyc', history.location.state);
      }
      return user;
    } catch (e: any) {
      AppToaster.apiError(e);
    }
    return null;
  }, []);

  return refreshMe;
};

export default useRefreshMe;
