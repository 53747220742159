import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DocumentCategory, DocumentSubCategory } from '@type';

export type State = {
  loading: boolean;
  cateLookup: Record<number, DocumentCategory>;
  subCateLookup: Record<
    number,
    DocumentSubCategory & {
      category: { id: number; name: string };
    }
  >;
};
export const initialState: State = {
  loading: false,
  cateLookup: {},
  subCateLookup: {},
};

export const getAllCategory = (state: {
  documents: State;
}): DocumentCategory[] => Object.values(state.documents.cateLookup);

export const getSubCategory = (id: number) => (state: { documents: State }) =>
  state.documents.subCateLookup[id];

export const {
  reducer: documentReducer,
  actions: { addDocumentCategories },
} = createSlice({
  name: 'DOCUMENT',
  initialState,
  reducers: {
    addDocumentCategories(
      state,
      { payload }: PayloadAction<DocumentCategory[]>,
    ) {
      return {
        ...state,
        loading: false,
        cateLookup: payload.reduce((memo, x) => {
          // eslint-disable-next-line no-param-reassign
          memo[x.id] = x;
          return memo;
        }, {} as Record<number, DocumentCategory>),
        subCateLookup: payload.reduce((memo, x) => {
          x.document_sub_categories.forEach((sc) => {
            // eslint-disable-next-line no-param-reassign
            memo[sc.id] = {
              ...sc,
              category: { id: x.id, name: x.name },
            };
          });
          return memo;
        }, {} as State['subCateLookup']),
      };
    },
  },
});
