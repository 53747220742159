import { PublicUser } from '@type';
import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type WhoamiResponse = PublicUser;
const whoami = async (): Promise<WhoamiResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withAuth().get(`/api/public-user/me`);
  const data = await res.json();
  return data;
};

export default whoami;
