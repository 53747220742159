import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Button as BPButton } from '@blueprintjs/core';
import classnames from 'classnames';

import { MEDIA_SM } from 'styles/media';

const StyledButton = styled(BPButton)`
  &.bp4-button {
    height: 40px;
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    box-shadow: none;
  }

  &.round {
    border-radius: 60px;
  }

  &.bp4-button:disabled {
    opacity: 0.3;
  }

  &.bp4-button.bp4-intent-success {
    background: ${({ theme }) => theme.success.text};
    border-color: ${({ theme }) => theme.success.text};
  }

  &.bp4-button.bp4-minimal.bp4-intent-success {
    background: transparent;
    color: ${({ theme }) => theme.success.text};
  }

  &.bp4-button.bp4-minimal.bp4-intent-warning {
    background: transparent;
    color: ${({ theme }) => theme.warning.text};
  }

  &.bp4-button.bp4-outlined.bp4-intent-success {
    background: transparent;
    color: ${({ theme }) => theme.success.text};
    border-color: ${({ theme }) => theme.success.text};
  }

  &.bp4-button.bp4-intent-success:hover {
    background: ${({ theme }) => theme.success.textHover};
  }

  .bp4-button > svg {
    margin-right: 10px;
  }

  @media ${MEDIA_SM} {
    height: auto !important;
    font-size: 11px !important;
    line-height: 15px !important;
  }
`;

type Props = {
  className?: string;
  type?: 'submit' | 'button' | 'reset';
  onClick?: (e: React.MouseEvent) => void;
  prefix?: ReactNode;
  round?: boolean;
  outlined?: boolean;
  minimal?: boolean;
  disabled?: boolean;
  intent?: 'none' | 'success' | 'warning' | 'primary';
  text?: ReactNode;
  suffix?: ReactNode | string;
  loading?: boolean;
};
const Button: FC<Props> = ({
  className,
  type = 'button',
  onClick,
  prefix,
  round,
  outlined,
  minimal,
  disabled,
  intent,
  text,
  suffix,
  loading,
}) => (
  <StyledButton
    type={type}
    className={classnames(className, {
      round,
    })}
    onClick={onClick}
    icon={prefix as any}
    intent={intent}
    outlined={outlined}
    minimal={minimal}
    text={text}
    rightIcon={suffix as any}
    loading={loading}
    disabled={disabled}
  />
);

export default Button;
