import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PublicUser } from '@type';

export type State = {
  uiTheme: 'dark' | 'light';
  authToken: string;
  refreshToken: string;
  persist: boolean;
  user: null | PublicUser;
};
const initialState: State = {
  uiTheme: 'light',
  authToken: sessionStorage.getItem('authToken') || '',
  refreshToken:
    sessionStorage.getItem('refreshToken') ||
    localStorage.getItem('refreshToken') ||
    '',
  persist: Boolean(localStorage.getItem('refreshToken')),
  user: null,
};

export const getTheme = (state: { app: State }) => state.app.uiTheme;
export const getAuthToken = (state: { app: State }) => state.app.authToken;
export const getRefreshToken = (state: { app: State }) =>
  state.app.refreshToken;
export const getUser = (state: { app: State }) => state.app.user;
export const getActiveSubs = (state: { app: State }) =>
  state.app.user?.active_public_user_subscriptions || [];

export const {
  reducer: appReducer,
  actions: {
    changeTheme,
    setAuthToken,
    setRenewToken,
    logout,
    fetchMe,
    fetchMeSuccess,
  },
} = createSlice({
  name: 'APP',
  initialState,
  reducers: {
    fetchMe() {},
    fetchMeSuccess(state, { payload }: PayloadAction<PublicUser>) {
      return {
        ...state,
        user: payload,
      };
    },
    changeTheme(state, { payload }: PayloadAction<'dark' | 'light'>) {
      return {
        ...state,
        uiTheme: payload,
      };
    },
    setAuthToken(state, { payload }: PayloadAction<string>) {
      sessionStorage.setItem('authToken', payload);
      return {
        ...state,
        authToken: payload,
      };
    },
    setRenewToken(
      state,
      { payload }: PayloadAction<{ token: string; persist?: boolean }>,
    ) {
      const persist = payload.persist ?? state.persist;
      const storage = persist ? localStorage : sessionStorage;
      storage.setItem('refreshToken', payload.token);
      return {
        ...state,
        refreshToken: payload.token,
        persist,
      };
    },
    logout(state) {
      sessionStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      sessionStorage.removeItem('refreshToken');
      return {
        ...state,
        authToken: '',
        refreshToken: '',
        user: null,
      };
    },
  },
});
