import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { format } from 'date-fns';
import { enUS, zhCN, zhHK } from 'date-fns/locale';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    debug: true,
    ns: ['translation', 'errors'],
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

i18n.on('languageChanged', (lng: string) => {
  document.documentElement.setAttribute('lang', lng);
});

export const locale2Lang = (locale: string): string =>
  ({
    en: 'en',
    'zh-HK': 'zh-Hant-HK',
    'zh-CN': 'zh-CN',
  }[locale] || 'en');

export const lang2Locale = (lang: string): string =>
  ({
    en: 'en',
    'zh-Hant-HK': 'zh-HK',
    'zh-CN': 'zh-CN',
  }[lang] || 'en');

export const formatDate = (d: Date, f: string) =>
  format(d, f, {
    locale:
      {
        'zh-HK': zhHK,
        'zh-CN': zhCN,
      }[i18n.language] || enUS,
  });

export default i18n;
