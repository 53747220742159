import FetcherFactory from 'utils/FetcherFactory';
import { OpenRoles } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchOpenRolesResponse = {
  data: OpenRoles[];
};
const fetchAllOpenRoles = async ({
  size = 100,
}: { size?: number } = {}): Promise<FetchOpenRolesResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withPublicToken().get(`/api/open-roles`, {
    'pagination[pageSize]': size,
    'populate[localizations][populate][0]': 'title',
    'populate[localizations][populate][1]': 'description',
    'populate[localizations][populate][2]': 'locale',
  });
  const data = await res.json();
  return data;
};

export default fetchAllOpenRoles;
