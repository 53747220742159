import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, Collapse, Button } from '@blueprintjs/core';
import numeral from 'numeral';
import { useMedia } from 'react-use';

import { getSubscriptionPlan } from 'state/plans';
import { locale2Lang } from 'utils/i18n';
import { Interval } from '@type';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';

const Container = styled.div`
  background: #5d737e;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.0809);
  border-radius: 24px;
  padding: 53px 42px 53px 45px;

  @media ${MEDIA_SM} {
    padding: 12px 26px 22px 22px;
  }
`;
const Title = styled.div`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #fff;

  @media ${MEDIA_SM} {
    font-size: 16px;
  }
`;
const BreakDown = styled.div`
  margin-top: 40px;

  @media ${MEDIA_MD} {
    margin-top: 8px;
  }

  @meida ${MEDIA_SM} {
    font-size: 12px;
  }
`;
const Item = styled.div`
  display flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #fff;

  @media ${MEDIA_MD} {
    color: #cbcbcb;
  }

  @meida ${MEDIA_SM} {
    font-size: 12px;
  }
`;
const Total = styled.div`
  margin-top: 25px;

  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 24px;
  color: #fff;

  @media ${MEDIA_MD} {
    color: #cbcbcb;
  }

  @media ${MEDIA_SM} {
    font-size: 12px;
    margin-top: 8px;
  }

  .price {
    margin-left: 10px;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #fccb4a;

    @media ${MEDIA_SM} {
      font-size: 22px;
    }
  }

  .interval {
    margin-left: 4px;
  }
`;
const Reminder = styled.div`
  margin-top: 12px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  padding: 16px 8px 24px 16px;
  color: #fff;

  @media ${MEDIA_SM} {
    font-size: 12px;
  }

  > * + * {
    margin-top: 10px;
  }
`;
const TabletButton = styled(Button)`
  background: transparent;
  padding: 0;
  margin-bottom: 7px;

  .bp4-button-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .bp4-icon {
    color: #fff;
  }
`;

type Props = {
  className?: string;
  planId: number;
  trial?: boolean;
  discounts?: {
    name: string;
    percent_off: number | null;
    amount_off: number | null;
  }[];
};
// eslint-disable-next-line complexity
const OrderDetailBlock: FC<Props> = ({
  className,
  planId,
  discounts = [],
  trial,
}) => {
  const { t, i18n } = useTranslation();
  const isTablet = useMedia(MEDIA_MD);
  const [open, setOpen] = useState(false);

  const plan = useSelector(getSubscriptionPlan(planId || 0));
  const langData = plan?.attributes?.localizations?.data?.find(
    (x) => x.attributes.locale === locale2Lang(i18n.language),
  );
  const interval = plan?.attributes?.charge_interval || 'never';

  const total = plan?.attributes?.price || 0;
  const discountAmount = discounts.reduce((memo, d) => {
    if (d.amount_off) return memo + d.amount_off;
    return (
      memo +
      (numeral(total)
        .multiply(d.percent_off || 0)
        .divide(100)
        .value() || 0)
    );
  }, 0);
  const subTotal = total - discountAmount;

  const lookup: Partial<Record<Interval, string>> = {
    day: 'products.interval.day',
    week: 'products.interval.week',
    month: 'products.interval.month',
    year: 'products.interval.year',
  };

  const FirstItem = (
    <Item>
      <span>{langData?.attributes?.name || plan?.attributes?.name}</span>
      <span>{`$${numeral(plan?.attributes?.price).format('0,0.00')}`}</span>
    </Item>
  );
  const BottomContent = (
    <>
      <BreakDown>
        {!isTablet && FirstItem}
        {discounts.map((d) => (
          <Item key={d.name}>
            <span>
              {d.name}
              {Boolean(d.percent_off) &&
                t('forms.paymentForm.orderDetail.percentOff', {
                  percent: ` -${d.percent_off}`,
                })}
            </span>
            <span>{`-$${numeral(
              d.amount_off ||
                numeral(total)
                  .multiply(d.percent_off || 0)
                  .divide(100)
                  .format('0.00'),
            ).format('0,0.00')}`}</span>
          </Item>
        ))}
      </BreakDown>
      <Total>
        <span>{t('forms.paymentForm.orderDetail.subTotal')}</span>
        <span className="price">{`$${numeral(subTotal).format(
          '0,0.[00]',
        )}`}</span>
        {interval !== 'never' && (
          <span className="interval">{`/${t(lookup[interval] || '')}`}</span>
        )}
      </Total>
      <Reminder>
        <Icon icon="info-sign" />
        <div>{t('forms.paymentForm.orderDetail.reminder')}</div>
      </Reminder>
    </>
  );

  const title = trial
    ? t('forms.paymentForm.orderDetail.titleTrial')
    : t('forms.paymentForm.orderDetail.title');
  if (isTablet) {
    return (
      <Container className={className}>
        <TabletButton fill minimal onClick={() => setOpen((o) => !o)}>
          <Title>{title}</Title>
          <Icon icon={open ? 'chevron-up' : 'chevron-down'} />
        </TabletButton>
        {FirstItem}
        <Collapse isOpen={open}>{BottomContent}</Collapse>
      </Container>
    );
  }

  return (
    <Container className={className}>
      <Title>{title}</Title>
      {BottomContent}
    </Container>
  );
};

export default OrderDetailBlock;
