import { FC, useState, useMemo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { isEqual, get } from 'lodash';
import { ProgressBar } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Node, RawFlow } from '@type';
import { matchCondition, findLongest } from 'utils/flow';
import { Button, TextField, AppToaster } from 'components';
import { updateSubmission } from 'api';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import BeforeNavigatePubSub from 'utils/beforeNavigatePubSub';
import { getAutoCompleteLookup } from 'state/questions';
import AutoCompleteMemo from 'utils/AutoCompleteMemo';
import { deepFlatten } from 'utils/fn';

import QuestionForm from './QuestionForm';
import NotSaveDialog, { PublicNotSaveDialog } from './NotSaveDialog';

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
  padding: 32px 42px 54px 44px;

  @media ${MEDIA_MD} {
    padding: 22px 12px 30px 20px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${MEDIA_MD} {
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
`;
const StyledProgressBar = styled(ProgressBar)`
  width: 500px;

  @media ${MEDIA_MD} {
    min-width: 0;
    width: 100%;
  }

  &.bp4-progress-bar {
    background-color: #ebebeb;
  }

  &.bp4-progress-bar.bp4-intent-warning .bp4-progress-meter {
    background-color: ${({ theme }) => theme.warning.text};
  }
`;
const Action = styled.div`
  display: flex;
  align-items: center;
`;
const SaveButton = styled(Button)`
  width: 160px;

  @media ${MEDIA_SM} {
    height: auto !important;
    font-size: 14px !important;
    line-height: 15px !important;
    margin-top: 10px;
    padding: 10px 0;
  }
`;
const LabelTextField = styled(TextField)`
  margin-top: 16px !important;
`;
const StyledQuestionForm = styled(QuestionForm)`
  margin-top: 20px;

  .dateField .bp4-form-content {
    width: 50%;
  }

  @media ${MEDIA_SM} {
    .dateField {
      width: 100%;
    }
  }
`;

type Props = {
  className?: string;
  subId: number | string;
  flow: Omit<RawFlow, 'id'>;
  label?: string;
  loading?: boolean;
  initialForm: {
    step: string[];
    stepLookup: Record<string, any>;
    data: any;
  };
  initialStep?: number;
  onPreviewClick?: (data: { label: string; formdata: any }) => void;
  onTrackSave?: () => void;
};
const QuestionSection: FC<Props> = ({
  className,
  subId,
  flow,
  label,
  loading: saving,
  initialForm,
  initialStep = 0,
  onPreviewClick,
  onTrackSave,
}) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(initialForm);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const notSaveRef = useRef<PublicNotSaveDialog>(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const autoLookup = useSelector(getAutoCompleteLookup);

  const schema = useMemo(
    () =>
      yup.object().shape({
        label: yup.string().required(t('validate.required', { ns: 'errors' })),
      }),
    [i18n.language],
  );
  const { values, touched, errors, handleBlur, handleChange } = useFormik({
    initialValues: { label },
    validationSchema: schema,
    onSubmit: () => {},
  });

  const nodeLookup = useMemo(
    () =>
      flow?.nodes.reduce((memo, n) => {
        // eslint-disable-next-line no-param-reassign
        memo[n.id] = n;
        return memo;
      }, {} as Record<string, Node>) || {},
    [flow],
  );
  const currentPage = result.step?.[currentStep] || '';
  const remain = useMemo(
    () => findLongest(nodeLookup[currentPage]?.id, flow?.edges || []),
    [nodeLookup, currentPage, flow],
  );
  const rule = useMemo(() => {
    const rules = nodeLookup[currentPage]?.data?.rules || [];
    return rules.map((r) => {
      const { left, right, op } = r.condition;
      if (!op) return r;
      const leftPath = (left?.path || '').replace(
        new RegExp(`^${left?.name}`),
        left?.overrideName,
      );
      const leftValue = get(result, leftPath);
      let rightValue;
      if (right.type === 'ref') {
        const rightPath = right.path.replace(
          new RegExp(`^${right.name}`),
          right.overrideName,
        );
        rightValue = get(result, rightPath) ?? rightPath;
      }
      return {
        ...r,
        condition: {
          ...r.condition,
          left: { ...r.condition.left, value: leftValue },
          right: {
            ...r.condition.right,
            value: (r.condition.right as any).value || rightValue,
          },
        },
      };
    });
  }, [nodeLookup[currentPage]?.data?.rules, result]);

  const saveDoc = useCallback(async (formdata: any, la: string) => {
    try {
      setLoading(true);
      await updateSubmission(subId, {
        formdata,
        label: la,
        skipReminder: false,
      });
      AppToaster.success(t('toaster.saved'));
    } catch (e: any) {
      AppToaster.apiError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSaveClick = useCallback(async () => {
    await saveDoc(result, values.label || '');
    onTrackSave?.();
    navigate('/user/documents');
  }, [result, values.label]);

  const handleNextClick = useCallback(
    (v: any) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const edges = flow!.edges
        .filter((ed) => ed.source === currentPage)
        .sort((a, b) => b.data.priority - a.data.priority);

      const newStep = [...result.step.slice(0, currentStep), currentPage];
      const newLookup = {
        ...result.stepLookup,
        [currentPage]: v,
      };
      const next = {
        step: newStep,
        stepLookup: newLookup,
        data: newStep.reduce(
          (memo, node) => ({
            ...memo,
            ...newLookup[node],
            '%locale%': {
              ...memo?.['%locale%'],
              ...newLookup[node]?.['%locale%'],
            },
          }),
          {} as Record<string, any>,
        ),
      };
      AutoCompleteMemo.bulkAdd(deepFlatten(v), autoLookup);

      // if reach end
      if (edges.length === 0) {
        setResult(next);
        onPreviewClick?.({
          label: values.label || '',
          formdata: next,
        });
        // go to preview
        return;
      }

      const matched = edges.find((e) => {
        if (e.data.priority === 0) return false;
        return matchCondition({
          conditions: e.data.conditions,
          result: next.data,
        });
      });
      const fallbackEdge = edges.find((e) => e.data.priority === 0);
      const fallback = matched?.target || fallbackEdge?.target || '';

      // if is comming from previous steps
      if (
        currentStep + 1 < result.step.length &&
        fallback === result.step[currentStep + 1]
      ) {
        setResult((r) => ({
          ...r,
          stepLookup: newLookup,
          data: newStep.reduce(
            (memo, node) => ({
              ...memo,
              ...newLookup[node],
              '%locale%': {
                ...memo?.['%locale%'],
                ...newLookup[node]?.['%locale%'],
              },
            }),
            {} as Record<string, any>,
          ),
        }));
        setCurrentStep((s) => s + 1);
        return;
      }

      setResult({
        ...next,
        step: [...next.step, fallback],
        stepLookup: {
          ...next.stepLookup,
          [fallback]: next.stepLookup[fallback] || {},
        },
      });
      setCurrentStep((s) => s + 1);
    },
    [currentPage, result, flow, nodeLookup, autoLookup],
  );

  useEffect(() => {
    let unsub: (() => void) | undefined;
    if (!isEqual(result, initialForm)) {
      unsub = BeforeNavigatePubSub.subscribe((next: () => void) => {
        notSaveRef.current?.open(next);
      });
    }
    return () => {
      unsub?.();
    };
  }, [result]);

  return (
    <Container className={className}>
      <Header>
        <StyledProgressBar
          intent="warning"
          value={currentStep / (result.step.length + remain)}
          stripes={false}
        />
        <Action>
          <SaveButton
            outlined
            round
            intent="success"
            loading={loading || saving}
            text={t('sections.question.save')}
            onClick={onSaveClick}
          />
        </Action>
      </Header>
      <LabelTextField
        solid
        name="label"
        value={values.label}
        touched={touched.label}
        error={errors.label}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <StyledQuestionForm
        questionId={nodeLookup[currentPage]?.data?.id}
        rules={rule}
        onSubmit={handleNextClick}
        onPreviousClick={(data: any) => {
          setResult((r) => ({
            ...r,
            stepLookup: { ...r.stepLookup, [currentPage]: data },
          }));
          setCurrentStep((s) => s - 1);
        }}
        overrideName={nodeLookup[currentPage]?.data?.overrideName}
        isStart={currentStep < 1}
        isEnd={remain === 0}
        loading={loading || saving}
        initValues={result.stepLookup[result.step[currentStep]]}
      />
      <NotSaveDialog ref={notSaveRef} />
    </Container>
  );
};

export default QuestionSection;
