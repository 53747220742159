import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const removeSubmission = async (id: number | string): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher
    .withAuth()
    .json()
    .delete(`/api/document-submissions/${id}`, {});
  const data = await res.json();
  return data;
};

export default removeSubmission;
