import { FC, useState, TouchEvent } from 'react';
import styled, { keyframes } from 'styled-components';
import ReactPlayer from 'react-player';
import { Icon } from '@blueprintjs/core';

import { MEDIA_SM } from 'styles/media';
import Tracker from 'utils/tracker';

const fadeInOut = keyframes`
  0% {
    width: 64px;
    height: 64px;
    opacity: 0.3;
  }

  50% {
    width: 100px;
    height: 100px;
    opacity: 0.7;
  }

  100% {
    width: 140px;
    height: 140px;
    opacity: 0;
  }
`;
const smFadeInOut = keyframes`
0% {
  width: 40px;
  height: 40px;
  opacity: 0.3;
}

50% {
  width: 64px;
  height: 64px;
  opacity: 0.7;
}

100% {
  width: 100px;
  height: 100px;
  opacity: 0;
}
`;

const Mask = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledIcon = styled(Icon)`
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  &.animate {
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-name: ${fadeInOut};
    animation-fill-mode: forwards;

    @media ${MEDIA_SM} {
      animation-name: ${smFadeInOut};
    }
  }

  > svg {
    width: 60%;
    height: 60%;
  }
`;

type Props = {
  className?: string;
  url: string;
  width?: string;
  height?: string;
};
const WrappedPlayer: FC<Props> = ({ className, url, width, height }) => {
  const [play, setPlay] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [lastQua, setLastQua] = useState(0);

  const filename = url.split('/').at(-1);

  return (
    <div className={className}>
      <ReactPlayer
        width={width}
        height={height}
        url={url}
        muted
        controls
        onEnded={() => setPlay(false)}
        onPause={() => {
          setPlay(false);
          setToggled(true);
        }}
        onPlay={() => {
          setPlay(true);
          setToggled(true);
        }}
        onProgress={(e) => {
          const qua = Math.floor(e.played * 4);
          if (qua > lastQua) {
            setLastQua(qua);
            Tracker.track('Watched Video', {
              progress: qua * 25,
              filename,
            });
          }
        }}
        config={{
          file: {
            attributes: {
              onTouchEnd: (e: TouchEvent<HTMLVideoElement>) => {
                const el = e.currentTarget;
                if (el.paused) {
                  el.play();
                } else {
                  el.pause();
                }
              },
            },
          },
        }}
      />
      <Mask>
        <StyledIcon
          className={toggled ? 'animate' : ''}
          key={String(play)}
          icon={play ? 'play' : 'pause'}
        />
      </Mask>
    </div>
  );
};

export default WrappedPlayer;
