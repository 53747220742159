import FetcherFactory from 'utils/FetcherFactory';

import StrapiPublicFetcher from '../StrapiPublicFetcher';

const resetPassword = async (
  token: string,
  rawPassword: string,
): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  // const password = await sha256(rawPassword);
  const res = await fetcher.json().post(`/api/public-user/resetPassword`, {
    token,
    password: rawPassword,
  });
  const data = await res.json();
  return data;
};

export default resetPassword;
