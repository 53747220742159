import { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  size?: string | number;
};
const SecureDoc: FC<Props> = ({
  size = 64,
  className,
  color = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 45.75 42"
    version="1.0"
  >
    <g clipPath="url(#db6eaea34a)">
      <path
        fill={color}
        d="M 15.402344 32.082031 L 30.628906 32.082031 L 30.628906 29.734375 C 30.628906 27.933594 29.894531 26.296875 28.710938 25.113281 C 27.527344 23.925781 25.894531 23.1875 24.101562 23.1875 L 21.933594 23.1875 C 20.136719 23.1875 18.507812 23.925781 17.324219 25.113281 C 16.140625 26.296875 15.402344 27.933594 15.402344 29.734375 Z M 31.542969 33.910156 L 14.492188 33.910156 C 13.988281 33.910156 13.578125 33.5 13.578125 32.996094 L 13.578125 29.734375 C 13.578125 27.429688 14.515625 25.335938 16.03125 23.816406 C 17.542969 22.300781 19.632812 21.355469 21.933594 21.355469 L 24.101562 21.355469 C 26.398438 21.355469 28.488281 22.300781 30.003906 23.816406 C 31.515625 25.335938 32.457031 27.429688 32.457031 29.734375 L 32.457031 32.996094 C 32.457031 33.5 32.046875 33.910156 31.542969 33.910156 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#afcdd9a2aa)">
      <path
        fill={color}
        d="M 32.457031 32.082031 L 42.828125 32.082031 L 42.828125 30.394531 C 42.828125 29.011719 42.261719 27.753906 41.351562 26.84375 C 40.441406 25.929688 39.1875 25.363281 37.808594 25.363281 L 36.078125 25.363281 C 35.210938 25.363281 34.394531 25.585938 33.683594 25.976562 C 33.070312 26.3125 32.53125 26.777344 32.101562 27.332031 C 32.195312 27.644531 32.273438 27.957031 32.328125 28.28125 C 32.414062 28.753906 32.457031 29.238281 32.457031 29.734375 Z M 43.738281 33.910156 L 31.542969 33.910156 C 31.039062 33.910156 30.628906 33.5 30.628906 32.996094 L 30.628906 29.734375 C 30.628906 29.34375 30.597656 28.960938 30.53125 28.59375 C 30.46875 28.234375 30.375 27.882812 30.253906 27.542969 C 30.140625 27.273438 30.15625 26.953125 30.324219 26.6875 L 30.328125 26.6875 C 30.949219 25.726562 31.804688 24.925781 32.8125 24.375 C 33.789062 23.839844 34.902344 23.53125 36.078125 23.53125 L 37.808594 23.53125 C 39.691406 23.53125 41.402344 24.304688 42.644531 25.546875 C 43.882812 26.792969 44.652344 28.507812 44.652344 30.394531 L 44.652344 32.996094 C 44.652344 33.5 44.246094 33.910156 43.738281 33.910156 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <g clipPath="url(#442978c671)">
      <path
        fill={color}
        d="M 3.03125 32.082031 L 13.578125 32.082031 L 13.578125 29.734375 C 13.578125 29.285156 13.613281 28.835938 13.6875 28.386719 C 13.738281 28.089844 13.804688 27.796875 13.882812 27.507812 C 13.453125 26.898438 12.898438 26.390625 12.25 26.019531 C 11.523438 25.601562 10.675781 25.363281 9.777344 25.363281 L 8.046875 25.363281 C 6.667969 25.363281 5.414062 25.929688 4.503906 26.84375 C 3.59375 27.753906 3.03125 29.011719 3.03125 30.394531 Z M 14.492188 33.910156 L 2.117188 33.910156 C 1.613281 33.910156 1.203125 33.5 1.203125 32.996094 L 1.203125 30.394531 C 1.203125 28.507812 1.972656 26.792969 3.214844 25.546875 C 4.453125 24.304688 6.164062 23.53125 8.046875 23.53125 L 9.777344 23.53125 C 11 23.53125 12.152344 23.859375 13.152344 24.433594 C 14.160156 25.011719 15.011719 25.839844 15.617188 26.832031 C 15.785156 27.0625 15.839844 27.371094 15.742188 27.660156 L 15.738281 27.660156 C 15.628906 27.988281 15.542969 28.328125 15.488281 28.679688 C 15.429688 29.015625 15.402344 29.363281 15.402344 29.734375 L 15.402344 32.996094 C 15.402344 33.5 14.996094 33.910156 14.492188 33.910156 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
    <path
      fill={color}
      d="M 8.914062 14.714844 C 10.074219 14.714844 11.125 15.183594 11.886719 15.949219 C 12.648438 16.710938 13.121094 17.765625 13.121094 18.929688 C 13.121094 20.09375 12.648438 21.152344 11.886719 21.914062 C 11.125 22.675781 10.074219 23.148438 8.914062 23.148438 C 7.75 23.148438 6.699219 22.675781 5.9375 21.914062 C 5.175781 21.152344 4.707031 20.09375 4.707031 18.929688 C 4.707031 17.765625 5.175781 16.710938 5.9375 15.949219 C 6.699219 15.183594 7.75 14.714844 8.914062 14.714844 Z M 10.597656 17.242188 C 10.167969 16.8125 9.570312 16.542969 8.914062 16.542969 C 8.257812 16.542969 7.660156 16.8125 7.226562 17.242188 C 6.796875 17.675781 6.53125 18.273438 6.53125 18.929688 C 6.53125 19.589844 6.796875 20.1875 7.230469 20.621094 C 7.660156 21.050781 8.257812 21.320312 8.914062 21.320312 C 9.570312 21.320312 10.167969 21.050781 10.597656 20.621094 C 11.027344 20.1875 11.292969 19.589844 11.292969 18.929688 C 11.292969 18.273438 11.027344 17.675781 10.597656 17.242188 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <path
      fill={color}
      d="M 36.945312 14.714844 C 38.105469 14.714844 39.160156 15.183594 39.917969 15.949219 C 40.679688 16.710938 41.152344 17.765625 41.152344 18.929688 C 41.152344 20.09375 40.679688 21.152344 39.917969 21.914062 C 39.160156 22.675781 38.105469 23.148438 36.945312 23.148438 C 35.78125 23.148438 34.730469 22.675781 33.96875 21.914062 C 33.207031 21.152344 32.738281 20.09375 32.738281 18.929688 C 32.738281 17.765625 33.207031 16.710938 33.96875 15.949219 C 34.730469 15.183594 35.78125 14.714844 36.945312 14.714844 Z M 38.628906 17.242188 C 38.195312 16.8125 37.601562 16.542969 36.945312 16.542969 C 36.285156 16.542969 35.691406 16.8125 35.261719 17.242188 C 34.828125 17.675781 34.5625 18.273438 34.5625 18.929688 C 34.5625 19.589844 34.828125 20.1875 35.261719 20.621094 C 35.691406 21.050781 36.285156 21.320312 36.945312 21.320312 C 37.601562 21.320312 38.195312 21.050781 38.628906 20.621094 C 39.058594 20.1875 39.324219 19.589844 39.324219 18.929688 C 39.324219 18.273438 39.058594 17.675781 38.628906 17.242188 "
      fillOpacity="1"
      fillRule="nonzero"
    />
    <g clipPath="url(#eb34295a80)">
      <path
        fill={color}
        d="M 23.09375 7.445312 C 24.886719 7.445312 26.511719 8.175781 27.6875 9.355469 C 28.863281 10.53125 29.585938 12.160156 29.585938 13.957031 C 29.585938 15.753906 28.863281 17.382812 27.6875 18.5625 C 26.511719 19.738281 24.886719 20.46875 23.09375 20.46875 C 21.300781 20.46875 19.675781 19.738281 18.503906 18.5625 C 17.328125 17.382812 16.601562 15.753906 16.601562 13.957031 C 16.601562 12.160156 17.328125 10.53125 18.503906 9.355469 C 19.675781 8.175781 21.300781 7.445312 23.09375 7.445312 Z M 26.394531 10.648438 C 25.550781 9.800781 24.382812 9.277344 23.09375 9.277344 C 21.804688 9.277344 20.640625 9.800781 19.792969 10.648438 C 18.949219 11.496094 18.425781 12.667969 18.425781 13.957031 C 18.425781 15.25 18.949219 16.421875 19.792969 17.265625 C 20.640625 18.113281 21.804688 18.636719 23.09375 18.636719 C 24.382812 18.636719 25.550781 18.113281 26.394531 17.265625 C 27.238281 16.421875 27.761719 15.25 27.761719 13.957031 C 27.761719 12.667969 27.238281 11.496094 26.394531 10.648438 "
        fillOpacity="1"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SecureDoc;
