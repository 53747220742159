import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { WrappedPlayer } from 'components';
import BulletBlock from './BulletBlock';

const StyledBulletBlock = styled(BulletBlock)`
  .media-box {
    background: transparent;

    .inner-media {
      background: #f5e0da;
      position: relative;
    }
  }
`;

type Props = {
  className?: string;
};
const CEOSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <StyledBulletBlock
      className={className}
      header={t('sections.ceo.header')}
      title={t('sections.ceo.title')}
      description={t('sections.ceo.description')}
      Media={
        <WrappedPlayer
          url={t('sections.ceo.videoUrl')}
          width="100%"
          height="auto"
        />
      }
    />
  );
};

export default CEOSection;
