import React, { PureComponent, createRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Card, Button } from '@blueprintjs/core';

import { Logo } from 'components/icons';
import { MEDIA_SM } from 'styles/media';
import Tracker from 'utils/tracker';

import FeedbackForm from './FeedbackForm';

const fadeIn = keyframes`
  from {
    transform: scale(0.3);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  min-width: 56px;
  min-height: 56px;
`;
const Widget = styled(Card)`
  border-radius: 12px;
  width: 360px;
  padding: 0;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  transform-origin: bottom right;

  @media ${MEDIA_SM} {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    z-index: 20;
  }
`;
const Title = styled.div`
  padding: 12px;
  background: #9cb166;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @media ${MEDIA_SM} {
    border-radius: 0;
  }
`;
const Content = styled.div`
  padding: 20px;
  padding-top: 12px;
  position: relative;
  flex: 1;
`;
const CloseButton = styled(Button)`
  border-radius: 100%;
  &.bp4-button {
    background-color: rgba(200, 200, 200, 0.3);
  }
`;
const FloatingButton = styled(Button)`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  box-shadow: none !important;

  &.bp4-button {
    background-color: #9cb166;
  }

  &.bp4-button .bp4-icon {
    color: white;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

type Props = {
  className?: string;
};
type State = {
  open: boolean;
};
class ChatWidget extends PureComponent<Props, State> {
  static ref =
    createRef<ChatWidget>() as React.MutableRefObject<ChatWidget | null>;

  public static instance() {
    if (ChatWidget.ref.current) return ChatWidget.ref.current;
    return undefined;
  }

  public static open() {
    ChatWidget.ref.current?.setState?.({
      open: true,
    });
  }

  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public componentDidMount(): void {
    ChatWidget.ref.current = this;
  }

  public componentWillUnmount(): void {
    ChatWidget.ref.current = null;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    Tracker.track('Clicked Contact Us Bubble');
    this.setState({ open: true });
  };

  public render() {
    const { className } = this.props;
    const { open } = this.state;

    return (
      <Container className={className}>
        {open ? (
          <Widget>
            <Title>
              <Logo />
              <CloseButton icon="cross" onClick={this.handleClose} />
            </Title>
            <Content>
              <FeedbackForm afterSend={this.handleClose} />
            </Content>
          </Widget>
        ) : (
          <FloatingButton
            aria-label="Open chat widget"
            icon="comment"
            onClick={this.handleOpen}
          />
        )}
      </Container>
    );
  }
}

export default ChatWidget;
