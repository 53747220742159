import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useInitPlansEffect, useRefreshMe } from 'hooks';
import { getRefreshToken } from 'state/app';
import AutoCompleteMemo from 'utils/AutoCompleteMemo';

const InitBlock: FC<{}> = () => {
  const isLogin = useSelector(getRefreshToken);
  const refreshMe = useRefreshMe();

  useInitPlansEffect();
  useEffect(() => {
    if (isLogin) {
      refreshMe();
      AutoCompleteMemo.load();
    }
  }, [isLogin]);
  return null;
};

export default InitBlock;
