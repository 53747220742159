import { DocumentSimple } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchQuestionsResponse = {
  data: DocumentSimple[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};
const fetchDocuments = async ({
  categories = [],
  subCategories = [],
  title,
  page = 1,
  pageSize = 25,
}: {
  categories?: number[];
  subCategories?: number[];
  title?: string;
  page?: number;
  pageSize?: number;
} = {}): Promise<FetchQuestionsResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.withAuth().get('/api/documents', {
    'fields[0]': 'title',
    'fields[1]': 'description',
    'populate[localizations][fields][0]': 'title',
    'populate[localizations][fields][1]': 'locale',
    'populate[localizations][fields][2]': 'description',
    'populate[document_sub_category][fields][0]': 'id',
    'pagination[pageSize]': pageSize,
    'pagination[page]': page,
    'sort[0]': 'createdAt:desc',
    ...categories.reduce(
      (memo, cid, idx) => ({
        ...memo,
        [`filters[document_category][${idx}]`]: cid,
      }),
      {},
    ),
    ...subCategories.reduce(
      (memo, cid, idx) => ({
        ...memo,
        [`filters[document_sub_category][${idx}]`]: cid,
      }),
      {},
    ),
    ...(title && {
      'filters[$or][0][title][$containsi]': title,
      'filters[$or][1][localizations][title][$containsi]': title,
    }),
  });
  const data = await res.json();
  return data;
};

export default fetchDocuments;
