import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Brochure } from '@type';
import { fetchBrochures } from 'api/borchure';
import AppToaster from 'components/AppToaster';
import { MEDIA_LG, MEDIA_SM, MEDIA_MD } from 'styles/media';
import { locale2Lang } from 'utils/i18n';

import BrochureCard from './BrochureCard';

const Container = styled.div`
  text-align: center;
  overflow: hidden;
  position: relative;

  @media ${MEDIA_MD} {
    padding-bottom: 70px;
  }
  @media ${MEDIA_SM} {
    padding: 0 20px 70px;
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin: auto;
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    line-height: 1.45;
  }
`;
const Description = styled.p`
  display: block;
  margin: auto;
  max-width: 470px;
  ${({ theme }) => theme.text.description};
`;
const CardBlock = styled.div`
  width: fit-content;
  margin: auto;
  margin-top: 72px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px 32px;
  min-height: 320px;
  padding-bottom: 70px;

  &.bp4-skeleton {
    width: 100%;
  }

  @media ${MEDIA_LG} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${MEDIA_MD} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
  }
  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
    margin-top: 42px;
    padding: 0 30px;
  }
`;

type Props = {
  className?: string;
};
const BrochureSection: FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sheet, setSheet] = useState<Brochure[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await fetchBrochures({ size: 100 });
        setSheet(result.data);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Container className={className}>
      <Header>{t('sections.brochure.header')}</Header>
      <Title>{t('sections.brochure.title')}</Title>
      <Description>{t('sections.brochure.description')}</Description>
      <CardBlock className={classnames({ 'bp4-skeleton': loading })}>
        {sheet.map((p) => {
          const langData = p.attributes.localizations?.data?.find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <BrochureCard
              key={p.id}
              title={langData?.attributes?.title || p.attributes.title}
              description={
                langData?.attributes?.description || p.attributes.description
              }
              fileUrl={
                langData?.attributes?.file?.data?.attributes?.url ||
                p.attributes.file?.data?.attributes?.url ||
                ''
              }
              ext={
                langData?.attributes?.file?.data?.attributes?.ext ||
                p.attributes.file?.data?.attributes?.ext ||
                ''
              }
            />
          );
        })}
      </CardBlock>
    </Container>
  );
};

export default BrochureSection;
