import { forwardRef, ReactElement, cloneElement } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { MEDIA_LG, MEDIA_MD, MEDIA_SM, MEDIA_XL } from 'styles/media';

const Container = styled.div`
  text-align: left;
  max-height: 520px;
  height: fit-content;

  @media ${MEDIA_XL} {
    max-height: 100%;
    height: fit-content;
  }

  @media ${MEDIA_LG} {
    height: auto;
  }
`;
const Main = styled.div<{ $ltr?: boolean }>`
  display: flex;
  flex-direction: ${({ $ltr }) => ($ltr ? 'row' : 'row-reverse')};
  height: 100%;
  position: relative;
  gap: 32px;

  @media ${MEDIA_LG} {
    flex-direction: ${({ $ltr }) => ($ltr ? 'column' : 'column-reverse')};
    gap: 0;
  }

  @media ${MEDIA_SM} {
    flex-direction: column-reverse;
  }
`;
const MediaBox = styled.div<{ $ltr?: boolean }>`
  flex: 4;
  background: #d5f7d4;
  border-radius: ${({ $ltr }) =>
    $ltr ? '0px 24px 100px 0px' : '24px 0px 0px 100px'};
  display: flex;
  align-items: center;
  justify-content: ${({ $ltr }) => ($ltr ? 'flex-start' : 'flex-end')};
  height: 100%;

  .inner-media {
    aspect-ratio: 16/9;
    border-radius: ${({ $ltr }) =>
      $ltr ? '0px 24px 100px 0px' : '24px 0px 0px 100px'};
    overflow: hidden;
    max-height: 520px;
    object-fit: cover;
  }

  @media ${MEDIA_XL} {
    flex: 3;
  }

  @media ${MEDIA_LG} {
    background: none;
    margin: 0;

    .inner-media {
      border-radius: 0;
      margin-top: ${({ $ltr }) => ($ltr ? '47px' : 0)};
      margin-bottom: ${({ $ltr }) => ($ltr ? '0' : '100px')};
    }
  }

  @media ${MEDIA_SM} {
    border-radius: 0;

    .inner-media {
      margin: 0;
      border-radius: 0;
      width: 100%;
    }
  }
`;
const Content = styled.div<{ $ltr?: boolean }>`
  flex: 3;
  padding: 34px 100px 34px 0;
  text-align: left;
  padding-left: ${({ $ltr }) => ($ltr ? 0 : '140px')};

  @media ${MEDIA_XL} {
    flex: 2;
  }

  @media ${MEDIA_LG} {
    background: #d5f7d4;
    padding: 20px 40px;
    border-radius: ${({ $ltr }) =>
      $ltr ? '0px 0px 40px 40px' : '40px 40px 0px 0px'};
  }

  @media ${MEDIA_MD} {
    text-align: center;
  }

  @media ${MEDIA_SM} {
    background: linear-gradient(135deg, #e3fefb 0%, #e6f7e2 100%);
    padding: 20px 30px;
    text-align: center;
    border-radius: 40px 40px 0px 0px;
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_LG} {
    margin: 0;
  }

  @media ${MEDIA_SM} {
    margin: 20px 0;
    line-height: 1.45;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 480px;
  white-space: pre-line;
  ${({ theme }) => theme.text.description};

  @media ${MEDIA_LG} {
    margin: auto;
    max-width: 100%;
    padding: 0 24px;
  }

  @media ${MEDIA_SM} {
    padding: 0;
  }
`;

type Props = {
  className?: string;
  header?: string;
  title?: string;
  description?: string;
  Media: ReactElement;
  ltr?: boolean;
};
const BulletBlock = forwardRef<HTMLDivElement, Props>(
  ({ className, header, title, description, Media, ltr = true }, ref) => (
    <Container className={className} ref={ref}>
      <Main className="content" $ltr={ltr}>
        <MediaBox className="media-box" $ltr={ltr}>
          {cloneElement(Media, {
            className: classnames(Media.props.className, 'inner-media'),
            width: '',
            height: '',
          })}
        </MediaBox>
        <Content className="text-box" $ltr={ltr}>
          <Header>{header}</Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </Main>
    </Container>
  ),
);

export default BulletBlock;
