import { FC, FocusEvent, ReactNode, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { FormGroup } from '@blueprintjs/core';
import Select from 'react-select';
import classnames from 'classnames';

import { MEDIA_SM } from 'styles/media';

const Value = styled.div`
  ${({ theme }) => theme.text.previewText};
`;

const StyleFormGroup = styled(FormGroup)`
  &.bp4-form-group {
    margin: 0;
  }

  .bp4-form-content {
    flex: 1;
    margin-top: ${({ label }) => (label ? '4px' : 0)};
  }

  &.form-field--readonly .bp4-form-content {
    margin-bottom: ${({ label }) => (label ? '12px' : 0)};
  }

  label.bp4-label {
    ${({ theme }) => theme.text.inputLabel};
    margin-bottom: 0;
  }

  .select-field__control {
    background: #f8f8f8;
    border-radius: 8px;
    border: none;
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 22px;
    height: 52px;
    box-shadow: none;
    padding-left: 16px;

    @media ${MEDIA_SM} {
      height: 38px;
    }
  }

  .select-field__value-container {
    padding: 0;
    ${({ theme }) => theme.text.inputValue};
  }

  .select-field__menu {
    background: #f8f8f8;
  }

  ${Value} + ${Value} {
    margin-top: 4px;
  }
`;

type Props<T = unknown> = {
  className?: string;
  label?: ReactNode;
  placeholder?: string;
  name?: string;
  value?: T | T[];
  disabled?: boolean;
  readonly?: boolean;
  touched?: boolean;
  error?: string;
  multi?: boolean;
  options: { label: string; value: T }[];
  langOptions?: {
    label: string;
    value: T;
  }[];
  allLangOptions?: Record<
    string,
    {
      label: string;
      value: T;
    }[]
  >;
  onChange?: (n: string, v: T | T[]) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};
const SelectField: FC<Props> = ({
  className,
  name = '',
  label,
  placeholder = '',
  value,
  disabled,
  readonly,
  touched,
  error,
  options,
  langOptions = [],
  allLangOptions,
  onChange,
  onBlur,
  multi,
}) => {
  const langLookup = useMemo(
    () =>
      langOptions.reduce((memo, o) => {
        // eslint-disable-next-line no-param-reassign
        memo[String(o.value)] = o.label;
        return memo;
      }, {} as Record<string, string>),
    [langOptions],
  );
  const selectOptions = useMemo(
    () =>
      options.map((o) => ({
        ...o,
        label: langLookup[String(o.value)] || o.label,
      })),
    [options, langLookup],
  );

  const populateLangData = useCallback(
    (vs: string[]) => {
      if (!allLangOptions) return;
      const temp: {
        value: any;
        label?: string;
        locale: string;
      }[] = [];
      vs.forEach((v) => {
        temp.push({
          label: options.find((o) => o.value === v)?.label,
          value: v,
          locale: 'en',
        });
      });
      Object.entries(allLangOptions).forEach(([locale, ops]) => {
        const lookup = ops.reduce((memo, o) => {
          // eslint-disable-next-line no-param-reassign
          memo[String(o.value)] = o.label;
          return memo;
        }, {} as Record<string, string>);
        vs.forEach((v) => {
          if (lookup[v]) {
            temp.push({ label: lookup[v], value: v, locale });
          }
        });
      });
      onChange?.(`%locale%.${name}`, temp);
    },
    [allLangOptions, onChange, name],
  );

  return (
    <StyleFormGroup
      className={classnames(className, {
        'form-field--readonly': readonly,
      })}
      label={label}
      helperText={touched && error}
      intent={touched && error ? 'danger' : 'none'}
      disabled={disabled}
      labelFor={name}
    >
      {readonly ? (
        <div>
          {(Array.isArray(value) ? value : [value]).map((v) => (
            <Value key={v}>
              {selectOptions.find((o) => String(o.value) === String(v))
                ?.label || String(v)}
            </Value>
          ))}
        </div>
      ) : (
        <Select
          inputId={name}
          name={name}
          isDisabled={disabled}
          isMulti={multi}
          classNamePrefix="select-field"
          className="select-field"
          options={selectOptions as any}
          placeholder={placeholder}
          value={
            Array.isArray(value)
              ? value.map((v) =>
                  selectOptions.find((o) => String(o.value) === String(v)),
                )
              : selectOptions.find((o) => String(o.value) === String(value)) ||
                null
          }
          onChange={(v: any) => {
            if (Array.isArray(v)) {
              const vs = v.map((x) => x.value);
              onChange?.(name, vs);
              populateLangData(vs);
              return;
            }
            onChange?.(name, v.value);
            populateLangData([v.value]);
          }}
          onBlur={onBlur}
        />
      )}
    </StyleFormGroup>
  );
};

export default SelectField;
