import { FC, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Dialog } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { useRefreshDocumentCate } from 'hooks';
import { SelectField, DateField, Checkbox, Button } from 'components';
import { locale2Lang } from 'utils/i18n';
import { MEDIA_SM, MEDIA_MD } from 'styles/media';

const Group = styled.div``;
const StyledDialog = styled(Dialog)`
  background: #ffffff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
  padding: 36px 62px 52px 66px;
  max-width: 1060px;
  width: 80%;

  @media ${MEDIA_MD} {
    padding: 22px;
  }

  ${Group} + ${Group} {
    margin-top: 44px;

    @media ${MEDIA_MD} {
      margin-top: 16px;
    }
  }
` as unknown as typeof Dialog;
const Title = styled.div`
  margin-bottom: 42px;

  @media ${MEDIA_SM} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.text.sectionTitle};
`;

const Label = styled.div`
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 24px;
  /* or 338% */
  color: #262626;

  @media ${MEDIA_SM} {
    font-size: 12px;
    line-height: 28px;
  }
`;
const GroupContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  @media ${MEDIA_SM} {
    margin-top: 8px;
    flex-direction: column;

    &.status-filter {
      flex-direction: row;
    }

    &.period-filter > div {
      display: grid;
      grid-template-columns: 1fr 7fr;
    }
  }

  > .bp4-form-group {
    flex: 1;
    width: 100%;
  }
`;
const ActionButton = styled(Button)`
  width: 140px;
`;
const Actions = styled.div`
  margin-top: 46px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;

  @media ${MEDIA_SM} {
    margin-top: 24px;
    gap: 8px;
  }
`;

export type Filter = {
  category: number;
  subCategory: number;
  from: string | null;
  to: string | null;
  status: string[];
};
export const initFilter: Filter = {
  category: 0,
  subCategory: 0,
  from: null,
  to: null,
  status: ['DONE', 'PENDING'],
};
type Props = {
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onFilterChange?: (f: Filter | null) => void;
};
const FilterModal: FC<Props> = ({
  className,
  isOpen,
  onClose,
  onFilterChange,
}) => {
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState<Filter>(initFilter);

  const {
    categories,
    loading: cateLoading,
    refreshDocumentCate,
  } = useRefreshDocumentCate();
  const subCategories = useMemo(
    () => [
      ...(categories.find((c) => c.id === filter.category)
        ?.document_sub_categories || []),
    ],
    [categories, filter.category],
  );

  useEffect(() => {
    refreshDocumentCate();
  }, []);

  return (
    <StyledDialog className={className} isOpen={isOpen} onClose={onClose}>
      <Title>{t('sections.filter.title')}</Title>
      <Group>
        <Label>{t('sections.filter.category')}</Label>
        <GroupContent>
          <SelectField
            placeholder={t('sections.documentList.search.category')}
            disabled={cateLoading}
            options={useMemo(
              () =>
                categories
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((c) => {
                    const langData = c.localizations.find(
                      (d) => d.locale === locale2Lang(i18n.language),
                    );
                    return {
                      value: String(c.id),
                      label: langData?.name || c.name,
                    };
                  }),
              [categories],
            )}
            value={filter.category || ''}
            onChange={(_, v) => {
              setFilter((f) => ({ ...f, category: Number(v), subCategory: 0 }));
            }}
          />
          <SelectField
            placeholder={t('sections.documentList.search.subCategory')}
            disabled={cateLoading || !filter.category}
            options={useMemo(
              () =>
                subCategories
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((c) => {
                    const langData = c.localizations.find(
                      (d) => d.locale === locale2Lang(i18n.language),
                    );
                    return {
                      value: String(c.id),
                      label: langData?.name || c.name,
                    };
                  }),
              [subCategories],
            )}
            value={filter.subCategory || ''}
            onChange={(_, v) =>
              setFilter((f) => ({ ...f, subCategory: Number(v) }))
            }
          />
        </GroupContent>
      </Group>
      <Group>
        <Label>{t('sections.filter.period')}</Label>
        <GroupContent className="period-filter">
          <DateField
            inline
            label={t('sections.filter.from')}
            value={filter.from}
            onChange={(_, v) => setFilter((f) => ({ ...f, from: v }))}
          />
          <DateField
            inline
            label={t('sections.filter.to')}
            value={filter.to}
            onChange={(_, v) => setFilter((f) => ({ ...f, to: v }))}
          />
        </GroupContent>
      </Group>
      <Group>
        <Label>{t('sections.filter.status')}</Label>
        <GroupContent className="status-filter">
          <Checkbox
            label={t('sections.filter.status.done')}
            checked={filter.status.includes('DONE')}
            onChange={(e) =>
              setFilter((f) => {
                const s = f.status.filter((x) => x !== 'DONE');
                return {
                  ...f,
                  status: e.currentTarget.checked ? [...s, 'DONE'] : s,
                };
              })
            }
          />
          <Checkbox
            label={t('sections.filter.status.pending')}
            checked={filter.status.includes('PENDING')}
            onChange={(e) =>
              setFilter((f) => {
                const s = f.status.filter((x) => x !== 'PENDING');
                return {
                  ...f,
                  status: e.currentTarget.checked ? [...s, 'PENDING'] : s,
                };
              })
            }
          />
        </GroupContent>
      </Group>
      <Actions>
        <ActionButton
          text={t('actions.cancel')}
          onClick={onClose}
          round
          intent="success"
          outlined
        />
        <ActionButton
          text={t('actions.reset')}
          onClick={() => setFilter(initFilter)}
          round
          intent="success"
          outlined
        />
        <ActionButton
          text={t('actions.done')}
          onClick={() => {
            onFilterChange?.(filter);
            onClose?.();
          }}
          round
          intent="success"
        />
      </Actions>
    </StyledDialog>
  );
};

export default FilterModal;
