import { ChargeEvent } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchChargeEventResponse = {
  results: ChargeEvent[];
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
};
const fetchChargeEvents = async (): Promise<FetchChargeEventResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.withAuth().get('/api/charge-events', {
    'filters[refund_id][$null]': true,
    'pagination[pageSize]': 100,
    'pagination[page]': 0,
    'sort[0]': 'charged_at:desc',
  });
  const data = await res.json();
  return data;
};

export default fetchChargeEvents;
