import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  CEOSection,
  ValuesSection,
  AboutUsSection,
  TeamSection,
} from 'containers';

import topImg from 'assets/images/about_us_top_image.webp';
import { MEDIA_SM, MEDIA_LG, MEDIA_MD } from 'styles/media';
import Tracker from 'utils/tracker';

const Container = styled.div`
  position: relative;
  padding-bottom: 180px;

  @media ${MEDIA_SM} {
    padding-bottom: 20px;
  }
`;
const FrontBlock = styled.div`
  position: relative;
  width: 100%;
  height: 700px;
  background: linear-gradient(135deg, #e3fefb 0%, #e6f7e2 100%);
  border-radius: 0px 0px 100px 100px;
  overflow: hidden;

  @media ${MEDIA_SM} {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    border-radius: 0px 0px 40px 40px;
  }
`;
const Content = styled.div`
  max-width: 100%;
  margin: auto;
  margin-top: 240px;
  position: relative;

  @media ${MEDIA_LG} {
    margin-top: 180px;
  }

  @media ${MEDIA_MD} {
    margin-top: 76px;
  }

  @media ${MEDIA_SM} {
    margin-top: 0;
    text-align: center;
  }
`;
const LeftBlock = styled.div`
  width: 55.55%;
  padding-left: 142px;
  padding-right: 40px;

  @media ${MEDIA_MD} {
    width: 50%;
    padding: 20px 40px;
  }

  @media ${MEDIA_SM} {
    width: 100%;
    padding: 20px 30px;
  }
`;
const Header = styled.label`
  white-space: pre-line;
  ${({ theme }) => theme.text.headerLabel};

  @media ${MEDIA_SM} {
    margin-top: 20px;
  }
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const Description = styled.p`
  display: block;
  white-space: pre-line;
  margin-top: 24px;
  ${({ theme }) => theme.text.description};

  @media ${MEDIA_SM} {
    margin-top: 0;
  }
`;
const RightBlock = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 44.44%;
  height: 100%;
  background: url(${topImg});
  background-position: center;
  background-size: auto 100%;
  background-size: cover;

  @media ${MEDIA_SM} {
    position: relative;
    width: 100%;
    height: 211px;
  }
`;
const StyledValuesSection = styled(ValuesSection)`
  max-width: 1440px;
  margin: auto;
  margin-top: 60px;
`;
const StyledCEOSection = styled(CEOSection)`
  margin-top: 126px;
  position: relative;
  z-index: 1;

  @media ${MEDIA_LG} {
    margin-top: 73px;

    &::before {
      display: none;
    }

    .text-box {
      background: #f5e0da;
    }
  }

  @media ${MEDIA_SM} {
    margin-top: 67px;

    .content {
      max-width: 100%;
    }
  }
`;
const StyledAboutUsSection = styled(AboutUsSection)`
  margin-top: 70px;

  .content {
    width: 100%;
    margin: auto;
  }
`;
const StyledTeamSection = styled(TeamSection)`
  max-width: 1440px;
  margin: auto;
  margin-top: 70px;

  @media ${MEDIA_SM} {
    max-width: 100%;
  }
`;

const AboutUsPage: FC<{}> = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Tracker.track('Visited About Us Page');
  }, []);

  return (
    <Container>
      <FrontBlock>
        <Content>
          <LeftBlock>
            <Header>{t('pages.service.header')}</Header>
            <Title>{t('pages.aboutus.title')}</Title>
            <Description>{t('pages.service.description')}</Description>
          </LeftBlock>
        </Content>
        <RightBlock />
      </FrontBlock>
      <StyledCEOSection />
      <StyledValuesSection />
      <StyledAboutUsSection />
      <StyledTeamSection />
    </Container>
  );
};

export default AboutUsPage;
