import { DocumentSubmission } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const createSubmission = async ({
  document,
  formdata,
  label,
}: {
  document: number;
  formdata: any;
  label?: string;
}): Promise<{ data: DocumentSubmission }> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher
    .withAuth()
    .json()
    .post('/api/document-submissions', {
      data: {
        document,
        formdata,
        label,
      },
    });
  const data = await res.json();
  return data;
};

export default createSubmission;
