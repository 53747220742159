import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { Dialog, DialogProps, Classes } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { identity } from 'lodash';

import { MEDIA_SM } from 'styles/media';
import { Button } from 'components';

const ProtalDialog = ({ className, ...rest }: DialogProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dialog {...rest} portalClassName={className} />
);
const StyledDialog = styled(ProtalDialog)`
  .bp4-dialog-container {
    align-items: flex-start;
  }

  .bp4-dialog {
    margin-top: 30vh;
  }

  .bp4-dialog-body {
    padding: 24px 8px;
    ${({ theme }) => theme.text.cardDescription};

    @media ${MEDIA_SM} {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
`;

export interface PublicConfirmDialog {
  open(onConfirm?: () => void): void;
}

type Props = {
  className?: string;
};
const ConfirmCancelDialog = forwardRef<PublicConfirmDialog, Props>(
  ({ className }, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState<() => void>(identity);

    const onClose = () => setIsOpen(false);

    useImperativeHandle(ref, () => ({
      open(fn?: () => void) {
        setIsOpen(true);
        setOnConfirm(() => (fn || identity) as any);
      },
    }));

    return (
      <StyledDialog className={className} isOpen={isOpen} onClose={onClose}>
        <div className={Classes.DIALOG_BODY}>
          {t('dialog.confirmCancel.message')}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <StyledButton
              onClick={onClose}
              intent="success"
              outlined
              text={t('actions.back')}
            />
            <StyledButton
              onClick={() => {
                onClose();
                onConfirm();
              }}
              intent="success"
              text={t('actions.yes')}
            />
          </div>
        </div>
      </StyledDialog>
    );
  },
);

export default ConfirmCancelDialog;
