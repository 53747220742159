import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  ProductSection,
  CEOSection,
  KnowledgeSection,
  ContactSection,
} from 'containers';
import frontImg from 'assets/images/frontpage.webp';
import { MEDIA_SM, MEDIA_LG, MEDIA_MD } from 'styles/media';
import Tracker from 'utils/tracker';
import { userCategories, companyCategories } from 'constants/plan';
import { getUser } from 'state/app';
import { AccountType } from '@type';

const Container = styled.div`
  position: relative;
  margin: auto;
  overflow: hidden;
`;
const FrontBlock = styled.div`
  position: relative;
  width: 100%;
  height: 900px;
  background-image: url(${frontImg});
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  margin: auto;
  background-position: center;
  background-position-y: -247px;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${MEDIA_LG} {
    height: 800px;
    background-position-y: -140px;
  }

  @media ${MEDIA_SM} {
    height: 620px;
    background-position-x: -130px;
    background-position-y: 0;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
`;
const Slogan = styled.div`
  font-family: 'Super Mario Bros. 2';
  pointer-events: none;
  color: #fff;
  position: absolute;
  top: 168px;
  right: 120px;
  font-size: 42px;
  font-weight: 900;
  line-height: 1.3;
  white-space: pre-line;

  html[lang='zh-HK'] &,
  html[lang='zh-CN'] & {
    font-size: 64px;
  }

  @media ${MEDIA_SM} {
    width: 70% !important;
    font-size: 22px;
    top: 128px;
    right: 0;
    line-height: 1.45;
    padding-right: 20px;

    > div:last-child {
      margin-top: 15px;
    }

    html[lang='zh-HK'] &,
    html[lang='zh-CN'] & {
      font-size: 32px;
    }
  }
`;
const StyledCEOSection = styled(CEOSection)`
  margin-top: 146px;
  position: relative;
  z-index: 1;

  .content {
    max-width: 100%;
    margin: auto;
  }

  @media ${MEDIA_LG} {
    margin-top: 0px;

    &::before {
      display: none;
    }
  }

  @media ${MEDIA_SM} {
    margin-top: 73px;
  }
`;
const StyledProductSection = styled(ProductSection)`
  width: 100%;
  margin: auto;
  margin-top: 126px;

  @media ${MEDIA_SM} {
    margin-top: 73px;
  }
`;
const StyledKnowledgeSection = styled(KnowledgeSection)`
  width: 100%;
  margin: auto;
  margin-top: 120px;

  @media ${MEDIA_SM} {
    margin-top: 47px;
  }
`;
const StyledContactSection = styled(ContactSection)`
  margin-top: 208px;

  @media ${MEDIA_MD} {
    margin-top: 88px;
  }
`;

const HomePage: FC<{}> = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  useEffect(() => {
    Tracker.track('Visited Home Page');
  }, []);

  return (
    <Container>
      <FrontBlock>
        <Slogan>{t('pages.home.slogan')}</Slogan>
      </FrontBlock>
      <StyledCEOSection />
      <StyledProductSection
        categories={(() => {
          if (!user?.account_type) return undefined;
          return user.account_type === AccountType.COMPANY
            ? companyCategories
            : userCategories;
        })()}
      />
      <StyledKnowledgeSection />
      <StyledContactSection />
    </Container>
  );
};

export default HomePage;
