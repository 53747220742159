import { DocumentSubmission } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchSubmissionResponse = {
  data: DocumentSubmission[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};
const fetchDocumentSubmissions = async ({
  categories = [],
  subCategories = [],
  status = [],
  label = '',
  from,
  to,
  page = 1,
  pageSize = 25,
}: {
  categories?: number[];
  subCategories?: number[];
  status?: string[];
  label?: string;
  from?: string | null;
  to?: string | null;
  page?: number;
  pageSize?: number;
} = {}): Promise<FetchSubmissionResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.withAuth().get('/api/document-submissions', {
    'fields[0]': 'label',
    'fields[1]': 'status',
    'fields[2]': 'updatedAt',
    'populate[document][fields][0]': 'id',
    'populate[document][fields][1]': 'title',
    'populate[document][populate][localizations][fields][0]': 'locale',
    'populate[document][populate][localizations][fields][1]': 'title',
    'pagination[pageSize]': pageSize,
    'pagination[page]': page,
    'sort[0]': 'updatedAt:desc',
    ...categories.reduce(
      (memo, cid, idx) => ({
        ...memo,
        [`filters[document_category][${idx}]`]: cid,
      }),
      {},
    ),
    ...subCategories.reduce(
      (memo, cid, idx) => ({
        ...memo,
        [`filters[document_sub_category][${idx}]`]: cid,
      }),
      {},
    ),
    ...(status.length
      ? status.reduce(
          (memo, cid, idx) => ({
            ...memo,
            [`filters[status][${idx}]`]: cid,
          }),
          {},
        )
      : { 'filters[status]': '' }),
    ...(label && {
      'filters[label][$containsi]': label,
    }),
    ...(from && {
      'filters[updatedAt][$gte]': from,
    }),
    ...(to && {
      'filters[updatedAt][$lte]': to,
    }),
  });
  const data = await res.json();
  return data;
};

export default fetchDocumentSubmissions;
