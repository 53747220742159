import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchDisclaimersPageResponse = {
  data: {
    id: number;
    attributes: {
      title: string;
      content: string;
      localizations: {
        data: {
          attributes: {
            title: string;
            content: string;
            locale: string;
          };
        }[];
      };
    };
  };
};
const fetchDisclaimersPage =
  async (): Promise<FetchDisclaimersPageResponse> => {
    let fetcher = FetcherFactory.make(StrapiPublicFetcher);
    fetcher = fetcher.withPublicToken();

    const res = await fetcher.get(`/api/disclaimer-page`, {
      'populate[localizations][populate]': '*',
    });
    const data = await res.json();
    return data;
  };

export default fetchDisclaimersPage;
