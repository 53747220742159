import styled from 'styled-components';

import { DocumentListSection } from 'containers';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';

const Container = styled.div`
  padding: 0 56px;
  padding-bottom: 170px;
  flex: 1;

  > * + * {
    margin-top: 60px;
  }

  @media ${MEDIA_MD} {
    margin-top: 89px;
  }

  @media ${MEDIA_SM} {
    margin-top: 69px;
    padding: 0 10px;
    padding-bottom: 80px;
  }
`;

const DocumentListPage = () => (
  <Container>
    <DocumentListSection />
  </Container>
);

export default DocumentListPage;
