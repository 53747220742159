import { createRef, PureComponent } from 'react';
import { Toaster, Position, Intent, ToasterInstance } from '@blueprintjs/core';

import APIError from 'utils/APIError';
import i18n from 'utils/i18n';

export default class AppToasterEl extends PureComponent<{}> {
  static ref =
    createRef<ToasterInstance | null>() as React.MutableRefObject<ToasterInstance | null>;

  static refHandlers = {
    toaster: (ref: Toaster) => {
      AppToasterEl.ref.current = ref;
    },
  };

  public static error(message: string) {
    AppToasterEl.clear();
    AppToasterEl.instance().show({
      message,
      intent: Intent.DANGER,
      timeout: 3000,
    });
  }

  public static async apiError(err: Error) {
    if (!(err instanceof APIError)) {
      AppToasterEl.error(err.message);
      return;
    }
    const key = await (err as APIError).i18nKey();
    AppToasterEl.error(
      i18n.t(key, { ns: 'errors', defaultValue: err.message }),
    );
  }

  public static success(message: string) {
    AppToasterEl.instance().show({
      message,
      intent: Intent.SUCCESS,
      timeout: 3000,
    });
  }

  public static clear() {
    AppToasterEl.instance().clear();
  }

  public static instance() {
    if (AppToasterEl.ref.current) return AppToasterEl.ref.current;
    AppToasterEl.ref.current = Toaster.create({
      className: 'recipe-toaster',
      position: Position.BOTTOM_LEFT,
      usePortal: true,
    });
    return AppToasterEl.ref.current;
  }

  public render() {
    return (
      <Toaster
        position={Position.TOP_RIGHT}
        ref={AppToasterEl.refHandlers.toaster}
      />
    );
  }
}
