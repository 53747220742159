import { useState, useEffect, useMemo, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RawDocumentSubmission, Document } from '@type';
import { fetchDocument, fetchDocumentSubmission, updateSubmission } from 'api';
import { AppToaster, AppModal } from 'components';
import {
  QuestionSection,
  QuestionPreviewSection,
  ReminderDialog,
} from 'containers';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import { useRefreshDocumentCate } from 'hooks';
import { getSubCategory } from 'state/documents';
import Tracker from 'utils/tracker';
import { locale2Lang } from 'utils/i18n';
import ReminderMemo from 'utils/ReminderMemo';

const Container = styled.div`
  padding: 0 56px;
  padding-bottom: 170px;
  flex: 1;
  width: 100%;

  @media ${MEDIA_MD} {
    margin-top: 89px;
    padding: 0 10px;
  }

  @media ${MEDIA_SM} {
    margin-top: 69px;
    padding-bottom: 80px;
  }
`;

const DocumentEditPage = () => {
  const { subId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [saveing, setSaving] = useState(false);
  const [doc, setDoc] = useState<Document | null>(null);
  const [docSub, setDocSub] = useState<RawDocumentSubmission | null>(null);
  const [mode, setMode] = useState({
    mode: 'EDIT',
    step: 0,
  });
  const [remindsContent, setRemindsContent] = useState<ReactNode[]>([]);
  const { refreshDocumentCate } = useRefreshDocumentCate();
  const subCate = useSelector(
    getSubCategory(doc?.attributes?.document_sub_category?.data?.id || 0),
  );

  const initialForm = useMemo(() => {
    if (!docSub) return {};
    if (!doc) return {};
    if (docSub.status === 'DONE') return docSub.formdata;
    if (!docSub.formdata?.step?.length) {
      const flow = doc.attributes.flow.data;
      const startPoint =
        flow.attributes.edges.find((e) => e.source === 'startPoint')?.target ||
        '';
      return {
        step: [startPoint],
        data: {},
        stepLookup: { [startPoint]: {} },
      };
    }
    return docSub.formdata;
  }, [docSub]);

  const saveDoc = useCallback(
    async (formdata: any, la: string, status?: string, skip?: boolean) => {
      try {
        if (!subId) return;
        setSaving(true);
        await updateSubmission(subId || '', {
          formdata,
          label: la,
          status,
          skipReminder: skip,
        });
        const res = await fetchDocumentSubmission(subId);
        setDocSub(res);
        AppToaster.success(t('toaster.saved'));
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setSaving(false);
      }
    },
    [subId],
  );
  const onPreviewClick = useCallback(
    async ({ label, formdata }: any) => {
      await saveDoc(formdata, label);
      setMode({ mode: 'PREVIEW', step: 0 });
    },
    [saveDoc],
  );

  useEffect(() => {
    if (!subId) return;
    (async () => {
      setLoading(true);
      try {
        const submission = await fetchDocumentSubmission(subId);
        const document = await fetchDocument(submission.document.id);
        setDoc(document.data);
        setDocSub(submission);

        const reminds: ReactNode[] = [];
        if (
          submission.version < document.data.attributes.updatedAt ||
          submission.version <
            document.data.attributes.flow.data.attributes.updatedAt
        ) {
          reminds.push(
            <div key="version">{t('pages.documentEdit.versionReminder')}</div>,
          );
        }
        if (
          document.data.attributes.popup_reminder &&
          submission.status !== 'DONE'
        ) {
          const langData = document.data.attributes.localizations.data.find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          reminds.push(
            <div key="reminder">
              {langData?.attributes.popup_reminder ||
                document.data.attributes.popup_reminder}
            </div>,
          );
        }
        if (submission.status === 'DONE') {
          setMode({ mode: 'PREVIEW', step: 0 });
        }
        if (
          ReminderMemo.shouldShowReminder(
            submission.document.id,
            document.data.attributes.updatedAt,
          ) &&
          reminds.length
        ) {
          setRemindsContent(reminds);
        }
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    })();
    refreshDocumentCate();
  }, []);

  if (loading) return <Spinner />;
  if (!doc || !docSub) return <div>404</div>;

  const completed = docSub.status === 'DONE';

  return (
    <Container>
      {mode.mode === 'PREVIEW' && (
        <QuestionPreviewSection
          flow={docSub.flow_snapshot || doc.attributes.flow.data.attributes}
          label={docSub.label}
          formdata={initialForm}
          loading={saveing}
          onBackClick={
            completed
              ? undefined
              : (n?: number) =>
                  setMode({
                    mode: 'EDIT',
                    step: n ?? (initialForm?.step?.length || 1) - 1,
                  })
          }
          onDoneClick={async () => {
            if (!completed) {
              AppModal.show(t('pages.documentEdit.doneReminder'), {
                onSuccess: async () => {
                  await saveDoc(docSub.formdata, docSub.label, 'DONE');
                  Tracker.track('Saved Document', {
                    documentName: doc?.attributes?.title || '',
                    category: subCate?.category?.name || '',
                    subcategory: subCate?.name || '',
                    language: i18n.language,
                  });
                  Tracker.trackSubmission(
                    doc?.attributes?.title || '',
                    doc?.id || 0,
                    docSub.formdata,
                  );
                  navigate('/user/documents');
                },
              });
            } else {
              navigate('/user/documents');
            }
          }}
        />
      )}
      {mode.mode === 'EDIT' && (
        <QuestionSection
          loading={saveing}
          subId={docSub.id}
          flow={docSub.flow_snapshot}
          label={docSub.label}
          initialForm={initialForm}
          initialStep={mode.step}
          onPreviewClick={onPreviewClick}
        />
      )}
      <ReminderDialog
        docId={docSub.document.id}
        open={remindsContent.length > 0}
        onClose={() => setRemindsContent([])}
      >
        {remindsContent}
      </ReminderDialog>
    </Container>
  );
};

export default DocumentEditPage;
