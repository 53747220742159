import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MEDIA_SM } from 'styles/media';
import { NewTab } from 'components/icons';

const Container = styled.div`
  position: relative;
`;
const TopContent = styled.div`
  background: linear-gradient(128.49deg, #fff3de 15.85%, #ffe7b8 98.46%);
  border-radius: 100px 0px 0px 0px;

  > div {
    max-width: 1440px;
    padding: 88px 120px 88px 176px;
  }

  @media ${MEDIA_SM} {
    > div {
      padding: 75px 0 44px 0;
    }
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  padding: 0 20px 0 30px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const TextContainer = styled.div`
  margin-top: 40px;
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px;

  @media ${MEDIA_SM} {
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-gap: 44px;
  }
`;
const TextBlock = styled.div`
  ${({ theme }) => theme.text.description};

  > div:first-child {
    font-weight: 700;
  }

  > div + div {
    margin-top: 12px;
  }
`;

type Props = {
  className?: string;
};
const AboutUsSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <TopContent>
        <div className="content">
          <Header>{t('sections.aboutUs.header')}</Header>
          <Title>{t('sections.aboutUs.title')}</Title>
          <TextContainer>
            <TextBlock>
              <div>{t('sections.aboutUs.group.title')}</div>
              <div>{t('sections.aboutUs.group.description')}</div>
            </TextBlock>
            <TextBlock>
              <div>{t('sections.aboutUs.tech.title')}</div>
              <div>{t('sections.aboutUs.tech.description')}</div>
            </TextBlock>
            <TextBlock>
              <div>
                <a
                  href={t('sections.aboutUs.trust.url')}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('sections.aboutUs.trust.title')}{' '}
                  <NewTab color="#215db0" size={14} />
                </a>
              </div>
              <div>{t('sections.aboutUs.trust.description')}</div>
            </TextBlock>
          </TextContainer>
        </div>
      </TopContent>
    </Container>
  );
};

export default AboutUsSection;
