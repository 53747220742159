import { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Collapse, Button as BPButton } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { getUser } from 'state/app';
import { getSubscriptionPlan } from 'state/plans';
import { locale2Lang } from 'utils/i18n';
import { UserSubscription } from '@type';
import { AppToaster, Button } from 'components';
import { unsubscribePlan } from 'api';
import useRefreshMe from 'hooks/useRefreshMe';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import Tracker from 'utils/tracker';

import ConfirmCancelDialog, {
  PublicConfirmDialog,
} from './ConfirmCancelDialog';

const Container = styled.div`
  background: #ffff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
`;
const Title = styled.div`
  padding: 42px 0 33px 44px;

  @media ${MEDIA_SM} {
    padding: 22px 0 22px 20px;
  }

  ${({ theme }) => theme.text.listSectionTitle};
`;

const TRow = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 4fr;
  padding: 27px 44px;
  align-items: baseline;

  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  color: #262626;
  gap: 4px;

  @media ${MEDIA_MD} {
    grid-template-columns: 3fr 2fr 2fr 3fr;
    padding: 12px;
    padding-left: 20px;
    font-size: 11px;
    line-height: 15px;
  }

  @media ${MEDIA_SM} {
    .time {
      font-size: 9px;
    }
  }

  .mobile-collapse {
    grid-column: 1/5;
  }

  .right {
    text-align: right;
  }
`;
const Table = styled.div`
  position: relative;

  ${TRow} + ${TRow} {
    border-top: 1px solid #d9d9d9;
  }
`;
const THeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 4fr;
  align-items: center;
  padding: 30px 44px;
  gap: 4px;

  background: #f0f2fb;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #262626;

  @media ${MEDIA_MD} {
    grid-template-columns: 3fr 2fr 2fr 3fr;
    padding: 12px;
    padding-left: 20px;
    font-size: 11px;
    line-height: 15px;
  }
`;
const CollapseButton = styled(BPButton)`
  &.bp4-button {
    padding: 0;
    justify-content: space-between;
    min-width: 40px;
    min-height: 0;
    max-width: 100px;

    svg {
      color: #5dc4b8;
    }

    @media ${MEDIA_SM} {
      max-width: 100%;
      min-width: 0;
    }
  }

  .bp4-button-text {
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 22px;
    color: #262626;

    @media ${MEDIA_SM} {
      font-size: 11px;
      line-height: 15px;
    }
  }
`;
const CollapseTitle = styled.div`
  margin-top: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #262626;

  @media ${MEDIA_MD} {
    margin-top: 4px;
    font-size: 11px;
    line-height: 15px;
  }
`;
const ContentList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;

  li + li {
    margin-top: 12px;
  }
`;
const CancelButton = styled(Button)`
  width: 230px;

  @media ${MEDIA_MD} {
    width: 100%;
  }

  @media ${MEDIA_SM} {
    max-width: 62px;
    width: 100%;

    &.bp4-button {
      height: 27px;
      font-size: 10px;
      line-height: 14px;
    }
  }
`;
const NoPlanBox = styled.div`
  text-align: center;
  padding: 40px;

  > div:first-child {
    font-family: 'Super Mario Bros. 2';
    font-style: normal;
    font-size: 22px;
    line-height: 32px;
    color: #262626;
    max-width: 360px;
    margin: auto;
  }

  > * + * {
    display: block;
    margin-top: 24px;
  }
`;

const Row: FC<{
  className?: string;
  plan: UserSubscription;
  onCancelClick: (id: number, setLoading: (l: boolean) => void) => void;
  // eslint-disable-next-line complexity
}> = ({ className, plan, onCancelClick }) => {
  const isTablet = useMedia(MEDIA_MD);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const detailPlan = useSelector(
    getSubscriptionPlan(plan.subscription_plan.id),
  );
  const documents = detailPlan?.attributes.documents?.data || [];
  const categories = detailPlan?.attributes.document_categories?.data || [];
  const subCategories =
    detailPlan?.attributes.document_sub_categories?.data || [];

  const langData = (detailPlan?.attributes?.localizations?.data || []).find(
    (x) => x.attributes.locale === locale2Lang(i18n.language),
  );

  const ContentCollaspe = (
    <Collapse className="mobile-collapse" isOpen={open}>
      <CollapseTitle>
        {t('sections.accountBalanceSection.header.includes')}
      </CollapseTitle>
      <ContentList>
        {categories.map((c) => {
          const cLang = (c?.attributes?.localizations?.data || []).find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <li key={c.id}>{`${t('name.document_category')}-${
              cLang?.attributes?.name || c.attributes.name
            }`}</li>
          );
        })}
        {subCategories.map((c) => {
          const cLang = (c?.attributes?.localizations?.data || []).find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <li key={c.id}>{`${t('name.document_sub_category')}-${
              cLang?.attributes?.name || c.attributes.name
            }`}</li>
          );
        })}
        {documents.map((d) => {
          const cLang = (d?.attributes?.localizations?.data || []).find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <li key={d.id}>{`${t('name.document')}-${
              cLang?.attributes?.title || d.attributes.title
            }`}</li>
          );
        })}
      </ContentList>
    </Collapse>
  );

  return (
    <TRow className={className}>
      <div>
        <CollapseButton
          minimal
          text={langData?.attributes?.name || plan.subscription_plan.name}
          rightIcon={open ? 'chevron-up' : 'chevron-down'}
          onClick={() => setOpen((o) => !o)}
        />
      </div>
      <div className="time">
        {format(new Date(plan.createdAt), 'dd/MM/yyyy')}
      </div>
      <div className="time">
        {format(new Date(plan.last_charged_at), 'dd/MM/yyyy')}
      </div>
      <div>
        {Boolean(plan.remain_credit) && (
          <span>
            {t('sections.accountBalanceSection.credit', {
              credit: plan.remain_credit,
            })}
          </span>
        )}
        {Boolean(plan.stripe_subscription_id) && !plan.expire_at && (
          <CancelButton
            round
            outlined
            intent="success"
            text={
              isTablet
                ? t('sections.accountBalanceSection.unsubscribeMobile')
                : t('sections.accountBalanceSection.unsubscribe')
            }
            loading={loading}
            onClick={() => {
              onCancelClick(plan.id, setLoading);
            }}
          />
        )}
        {Boolean(plan.stripe_subscription_id) && plan.expire_at && (
          <span>
            {t('sections.accountBalanceSection.expire', {
              date: format(new Date(plan.expire_at), 'dd/MM/yyyy'),
            })}
          </span>
        )}
      </div>
      {ContentCollaspe}
    </TRow>
  );
};

type Props = {
  className?: string;
};

const AccountBalanceSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const dialogRef = useRef<PublicConfirmDialog>(null);
  const refreshMe = useRefreshMe();

  return (
    <Container className={className}>
      <Title>{t('sections.accountBalanceSection.title')}</Title>
      <Table>
        <THeader>
          <div>{t('sections.accountBalanceSection.header.name')}</div>
          <div>{t('sections.accountBalanceSection.header.startDate')}</div>
          <div>{t('sections.accountBalanceSection.header.date')}</div>
          <div />
        </THeader>
        {(user?.active_public_user_subscriptions || []).length === 0 && (
          <NoPlanBox>
            <div>{t('sections.accountBalanceSection.noContent.title')}</div>
            <Link to="/user/payments/new">
              <Button
                outlined
                intent="success"
                text={t('sections.accountBalanceSection.noContent.cta')}
              />
            </Link>
          </NoPlanBox>
        )}
        {(user?.active_public_user_subscriptions || []).map((s) => (
          <Row
            key={s.id}
            plan={s}
            onCancelClick={(id: number, setLoading: (l: boolean) => void) => {
              dialogRef.current?.open(async () => {
                try {
                  setLoading(true);
                  await unsubscribePlan(id);
                  AppToaster.success('Plan unsubscribed');
                  Tracker.track('Cancelled Subscription', {
                    packageName: s.subscription_plan.name,
                  });
                  refreshMe();
                } catch (e: any) {
                  AppToaster.apiError(e);
                } finally {
                  setLoading(false);
                }
              });
            }}
          />
        ))}
      </Table>
      <ConfirmCancelDialog ref={dialogRef} />
    </Container>
  );
};

export default AccountBalanceSection;
