import { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { Button } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { MEDIA_SM } from 'styles/media';
import classNames from 'classnames';

const fadeIn = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 100px 24px 24px 24px;
  transform: scale(0.9);
  opacity: 0;

  &.animated {
    animation-duration: 1000ms;
    animation-timing-function: ease-in-out;
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;
  }

  @media ${MEDIA_SM} {
    margin-bottom: 70px;
  }
`;
const Image = styled.div`
  margin-left: -32px;
  margin-top: -32px;
  background: #ceeeed;
  border-radius: 24px 100px 24px 24px;
  width: 254px;
  height: 272px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 100%;
    height: 100%;
  }

  @media ${MEDIA_SM} {
    margin-left: -12px;
  }
`;
const Main = styled.div`
  padding: 32px;
  padding-top: 24px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
const Title = styled.div`
  ${({ theme }) => theme.text.cardTitle};
`;
const Description = styled.div`
  margin-top: 4px;
  ${({ theme }) => theme.text.cardDescription};
`;
const StyledButton = styled(Button)`
  margin-top: 18px;

  &.bp4-button {
    background: #69d795;
    height: 44px;
    border-radius: 10px;
    color: #fff;
    line-height: 21px;
    font-size: 16px;
    box-shadow: none;
  }

  &.bp4-button:hover {
    background: #69d795;
    box-shadow: none;
  }
`;

type Props = {
  className?: string;
  name: string;
  title: string;
  description: string;
  profilePic?: string;
  delay?: string;
  onMoreClick?: (description: string) => void;
};

const TeamProfileCard: FC<Props> = ({
  className,
  name,
  title,
  description,
  profilePic,
  delay = '0',
  onMoreClick,
}) => {
  const { t } = useTranslation();
  const [shouldAni, setShouldAni] = useState(false);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) setShouldAni(true);
  }, [inView]);

  return (
    <Container
      className={classNames(className, { animated: shouldAni })}
      style={{ animationDelay: delay }}
      ref={ref}
    >
      <Image>
        {Boolean(profilePic) && <img src={profilePic} alt={title} />}
      </Image>
      <Main>
        <div>
          <Title>{name}</Title>
          <Description>{title}</Description>
        </div>
        <StyledButton onClick={() => onMoreClick?.(description)}>
          {t('actions.moreInfo')}
        </StyledButton>
      </Main>
    </Container>
  );
};

export default TeamProfileCard;
