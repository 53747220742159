import React, { FC, useEffect } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import ScrollTop from './components/ScrollTop';

import PublicLayout from './pages/Public/PublicLayout';
import {
  HomePage,
  CareerPage,
  CareerDetailPage,
  ServicePage,
  KnowledgesPage,
  KnowledgeDetailPage,
  AboutUsPage,
  FAQPage,
  PrivacyPage,
  TermsPage,
  DisclaimersPage,
  LoginPage,
  RegisterPage,
  UserLayout,
  AuthLayout,
  CheckOutCompletePage,
} from './pages';

const LazyUserAccountPage = React.lazy(
  () => import('./pages/User/UserAccountPage'),
);
const LazyNewPlanPage = React.lazy(() => import('./pages/User/NewPlanPage'));
const LazyDocumentListPage = React.lazy(
  () => import('./pages/User/DocumentListPage'),
);
const LazyDocumentEditPage = React.lazy(
  () => import('./pages/User/DocumentEditPage'),
);
const LazySubmissionListPage = React.lazy(
  () => import('./pages/User/SubmissionListPage'),
);
const LazyForgetPasswordPage = React.lazy(
  () => import('./pages/Auth/ForgetPasswordPage'),
);
const LazyResetPasswordPage = React.lazy(
  () => import('./pages/Auth/ResetPasswordPage'),
);
const LazyKYCPage = React.lazy(() => import('./pages/Auth/KYCPage'));

const RedirectWithLang: FC<{ lang: string }> = ({ lang }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);
  return <HomePage />;
};

const AppRoute = () => (
  <Routes>
    <Route
      path="/"
      element={
        <AuthLayout>
          <ScrollTop />
          <React.Suspense fallback={<Spinner />}>
            <Outlet />
          </React.Suspense>
        </AuthLayout>
      }
    >
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forget" element={<LazyForgetPasswordPage />} />
      <Route path="/reset-password" element={<LazyResetPasswordPage />} />
      <Route path="/kyc" element={<LazyKYCPage />} />
    </Route>
    <Route
      path="/user"
      element={
        <UserLayout>
          <ScrollTop />
          <React.Suspense fallback={<Spinner />}>
            <Outlet />
          </React.Suspense>
        </UserLayout>
      }
    >
      <Route index element={<LazyUserAccountPage />} />
      <Route path="/user/payments/new" element={<LazyNewPlanPage />} />
      <Route
        path="/user/payments/complete"
        element={<CheckOutCompletePage />}
      />
      {/* <Route
                    path="/user/payments/:subsId"
                    element={<CheckOutPage />}
                  />
                  <Route
                    path="/user/payments/:subsId/complete"
                    element={<CheckOutResultPage />}
                  /> */}
      <Route path="/user/documents/new" element={<LazyDocumentListPage />} />
      <Route
        path="/user/documents/submissions/:subId"
        element={<LazyDocumentEditPage />}
      />
      <Route path="/user/documents" element={<LazySubmissionListPage />} />
      <Route path="*" element={<Navigate to="/user" />} />
    </Route>
    <Route
      path="/"
      element={
        <PublicLayout>
          <ScrollTop />
          <Outlet />
        </PublicLayout>
      }
    >
      <Route index element={<HomePage />} />
      <Route path="/about" element={<AboutUsPage />} />
      <Route path="/career" element={<CareerPage />} />
      <Route path="/services" element={<ServicePage />} />
      <Route path="/knowledges" element={<KnowledgesPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/disclaimers" element={<DisclaimersPage />} />
      <Route path="/knowledges/:id" element={<KnowledgeDetailPage />} />
      <Route path="/career/:id" element={<CareerDetailPage />} />
      <Route path="/zh-HK" element={<RedirectWithLang lang="zh-HK" />} />
      <Route path="/zh-CN" element={<RedirectWithLang lang="zh-CN" />} />
      <Route path="/en" element={<RedirectWithLang lang="en" />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  </Routes>
);

export default AppRoute;
