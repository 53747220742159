import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchPrivacyPageResponse = {
  data: {
    id: number;
    attributes: {
      title: string;
      content: string;
      localizations: {
        data: {
          attributes: {
            title: string;
            content: string;
            locale: string;
          };
        }[];
      };
    };
  };
};
const fetchPrivacyPage = async (): Promise<FetchPrivacyPageResponse> => {
  let fetcher = FetcherFactory.make(StrapiPublicFetcher);
  fetcher = fetcher.withPublicToken();

  const res = await fetcher.get(`/api/privacy-page`, {
    'populate[localizations][populate]': '*',
  });
  const data = await res.json();
  return data;
};

export default fetchPrivacyPage;
