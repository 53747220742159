import React, { FC } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { MEDIA_LG, MEDIA_MD, MEDIA_SM } from 'styles/media';

const Container = styled.div`
  position: relative;
`;

const Main = styled.div`
  background: linear-gradient(139.72deg, #fff8ec 5.1%, #f9e9ca 98.73%);
  border-radius: 100px 0px 0px 24px;
  padding: 100px 0 80px 70px;
  margin-left: 135px;
  position: relative;

  > div:first-child {
    display: inline-block;
  }

  @media ${MEDIA_LG} {
    margin-left: 0;
    padding: 79px 40px 60px 40px;
  }

  @media ${MEDIA_MD} {
    display: flex;
    padding: 79px 0 60px 0;
    margin: 0;
    width: 100%;
    flex-direction: column-reverse;
  }

  @media ${MEDIA_SM} {
    padding: 60px 0;
  }
`;
const Content = styled.div`
  padding: 0 20px;
  text-align: left;

  @media ${MEDIA_LG} {
    margin: 0;
    padding: 0;
  }

  @media ${MEDIA_MD} {
    padding: 80px 0 60px 0;
    flex-direction: column;
    margin: 0;
    padding: 0;
    text-align: center;

    > div:first-child {
      display: block;
      text-align: center;
    }
  }

  @media ${MEDIA_SM} {
    text-align: center;
  }
`;
const Header = styled.label`
  white-space: pre-line;
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  white-space: pre-line;
  ${({ theme }) => theme.text.description};

  @media ${MEDIA_MD} {
    max-width: 100%;
    padding: 0 37px;
  }

  @media ${MEDIA_SM} {
    width: 100%;
    max-width: 100%;
  }
`;
const ImageBlock = styled.div`
  max-height: 600px;
  height: fit-content;
  border-radius: 100px 24px 24px 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  clear: none;
  width: 70%;
  margin-top: -200px;
  margin-left: 28px;

  > video,
  > img,
  > div {
    min-height: 400px;
    width: 100%;

    @media ${MEDIA_LG} {
      min-height: 0;
    }
  }

  @media ${MEDIA_LG} {
    max-height: 100%;
    margin-top: -160px;
  }

  @media ${MEDIA_MD} {
    margin-top: 20px;
    margin-left: 0;
    border-radius: 0;
    float: initial;
    width: 100%;
  }

  .inner-image {
    object-fit: cover;

    @media ${MEDIA_MD} {
      margin: 0;
      height: 420px;
      width: 100%;
      border-radius: 0;
    }

    @media ${MEDIA_SM} {
      height: 211px;
    }
  }
`;

type Props = {
  className?: string;
  header?: string;
  title?: string;
  description?: string;
  Media: JSX.Element;
};
const ThumbsUpBlock: FC<Props> = ({
  className,
  header,
  title,
  description,
  Media,
}) => (
  <Container className={className}>
    <div className="content">
      <Main className="main">
        <ImageBlock>
          {React.cloneElement(Media, {
            className: classnames(Media.props.className, 'inner-image'),
            width: '',
            height: '',
          })}
        </ImageBlock>
        <Content className="content">
          <Header>{header}</Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </Main>
    </div>
  </Container>
);

export default ThumbsUpBlock;
