import { FC } from 'react';

type Props = {
  size?: number | string;
  color?: string;
};
const Download: FC<Props> = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.467773 12.1738V13.7369H13.532V12.1738H0.467773ZM12.3316 6.79055L7.01714 11.2703L1.82451 6.78434L2.84672 5.60266L6.30114 8.58813V0.263184H7.86419V8.51308L11.3248 5.59634L12.3316 6.79055Z"
      fill={color}
    />
  </svg>
);

export default Download;
