import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchKnowledge } from 'api/knowledge';
import { Knowledge } from '@type';
import { AppToaster } from 'components';
import { MEDIA_SM, MEDIA_XL } from 'styles/media';
import { locale2Lang, formatDate } from 'utils/i18n';
import Tracker from 'utils/tracker';

const Container = styled.div`
  position: relative;
  padding-top: 148px;
  min-height: calc(100vh - 470px);
  padding-bottom: 120px;

  @media ${MEDIA_SM} {
    padding-top: 100px;
    padding-bottom: 60px;
  }
`;

const Main = styled.div`
  max-width: 1440px;
  margin: auto;
  width: 100%;
  min-height: 50vh;

  @media ${MEDIA_XL} {
    padding: 0 28px;
  }
`;
const Remarks = styled.div<{ type?: string }>`
  margin-top: 12px;
  font-family: 'Open Sans';
  font-size: 20px;
  line-height: 30px;
  color: ${({ type }) => (type === 'orange' ? '#ffae10' : '#7ED6D3')};

  > span:first-child {
    font-weight: 800;
  }
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};
`;
const Description = styled.p`
  display: block;
  max-width: 470px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;
const Content = styled.div`
  line-height: 28px;
`;

const KnowledgeDetailPage: FC<{}> = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<Knowledge | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchKnowledge(Number(id));
        setNews(result.data);

        if (result.data.attributes) {
          const { title } = result.data.attributes;
          Tracker.track('Visited Knowledge Detail Page', { title });
        }
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading)
    return (
      <Container>
        <Main className="bp4-skeleton" />
      </Container>
    );
  if (!news)
    return (
      <Container>
        <Main style={{ textAlign: 'center' }}>
          <Title>{t('errors.notFound.title')}</Title>
          <Description>{t('errors.notFound.description')}</Description>
        </Main>
      </Container>
    );

  const { category, createdAt, title, content } = news.attributes;
  const langData = news.attributes.localizations?.data?.find(
    (x) => x.attributes.locale === locale2Lang(i18n.language),
  );
  return (
    <Container>
      <Main>
        <Remarks type={{ NEWS: 'orange' }[category] || 'lime'}>
          <span>
            {category ? t(`knowledge.category.${category.toLowerCase()}`) : ''}
          </span>
          {category && <span>{` | `}</span>}
          <span>
            {formatDate(
              new Date(createdAt),
              t('sections.knowledge.dateFormat'),
            )}
          </span>
        </Remarks>
        <Title>{langData?.attributes?.title || title}</Title>
        <Content
          className="ck-content"
          dangerouslySetInnerHTML={{
            __html: langData?.attributes?.content || content,
          }}
        />
      </Main>
    </Container>
  );
};

export default KnowledgeDetailPage;
