import { get } from 'lodash';

import { Edge } from '@type';

export const resolvePath = (p: {
  path: string;
  name: string;
  overrideName: string;
}) => {
  const path = p.path.replace(new RegExp(`^${p.name}`), p.overrideName);
  return path;
};

export const matchCondition = ({
  conditions,
  result,
}: {
  conditions: Edge['data']['conditions'];
  result: any;
}): boolean => {
  if (conditions.length === 0) return false;
  let out = false;
  conditions.forEach((c) => {
    const { joinType = 'or', op, left, right } = c;
    if (out && joinType === 'or') return;
    const leftPath = resolvePath(left);
    const leftValue = get(result, leftPath);
    let rightValue = (right as any).value || '';
    if (right.type === 'ref') {
      const rightPath = resolvePath(right);
      rightValue = get(result, rightPath);
    }
    const lookup: Record<string, boolean> = {
      eq: String(leftValue).trim() === String(rightValue).trim(),
      neq: String(leftValue).trim() !== String(rightValue).trim(),
      gt: Number(leftValue) > Number(rightValue),
      gte: Number(leftValue) >= Number(rightValue),
      lt: Number(leftValue) < Number(rightValue),
      lte: Number(leftValue) <= Number(rightValue),
      includes: leftValue?.includes?.(rightValue),
      nincludes: !leftValue?.includes?.(rightValue),
    };
    out = joinType === 'or' ? lookup[op] : out && lookup[op];
  });
  return out;
};

// const cache = new WeakMap<Edge[], Record<string, number>>();
export const findLongest = (
  current: string | number,
  edges: Edge[],
): number => {
  const reverseNode: Record<string, string[]> = {};
  const lookup: Record<string, string[]> = {};
  edges.forEach((ed) => {
    lookup[ed.source] ||= [];
    lookup[ed.target] ||= [];
    lookup[ed.source].push(ed.target);
    reverseNode[ed.target] ||= [];
    reverseNode[ed.target].push(ed.source);
  });
  const stopMax = Object.keys(lookup).length;
  const temp = Object.keys(lookup).filter((id) => lookup[id].length === 0);
  const maxMap: Record<string, number> = {};
  while (temp.length > 0) {
    const parent = temp.shift() as string;
    const dest = reverseNode[parent];
    // eslint-disable-next-line no-continue
    if (!dest?.length) continue;
    const cur = Math.min(stopMax, (maxMap[parent] || 0) + 1);
    dest.forEach((id) => {
      if (cur > (maxMap[id] || 0)) {
        maxMap[id] = cur;
        if (reverseNode[id]) {
          temp.push(id);
        }
      }
    });
  }
  return maxMap[String(current)] || 0;
};
