import FetcherFactory from 'utils/FetcherFactory';
import { AccountType } from '@type';

import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
};
const register = async ({
  username,
  rawPassword,
  accountType,
}: {
  username: string;
  rawPassword: string;
  accountType: AccountType;
}): Promise<LoginResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  // const password = await sha256(rawPassword);
  const res = await fetcher.json().post(`/api/public-user/register`, {
    username,
    password: rawPassword,
    accountType,
  });
  const data = await res.json();
  return data;
};

export default register;
