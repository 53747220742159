import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/i18n';
import { MEDIA_SM } from 'styles/media';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 370px;
  display: inline-flex;
  flex-direction: column;
  background: white;
  box-shadow: ${({ theme }) => theme.shadows.base};
  border-radius: 24px;
  overflow: hidden;

  @media ${MEDIA_SM} {
    width: 100%;
    margin-bottom: 40px;
  }
`;
const Image = styled.div`
  background: #c5c8ca;
  width: 100%;
  height: 250px;

  > img {
    width: 100%;
    height: 100%;
  }
`;
const Main = styled.div`
  padding: 24px 28px;
  min-height: 226px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: space-between;
`;
const Title = styled.div`
  ${({ theme }) => theme.text.cardTitle};
`;
const Description = styled.div`
  margin-top: 12px;
  ${({ theme }) => theme.text.cardDescription};
`;
const Remarks = styled.div<{ type?: string }>`
  margin-top: 12px;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 32px;
  color: ${({ type }) => (type === 'orange' ? '#ffae10' : '#7ED6D3')};

  > span:first-child {
    font-weight: 800;
  }
`;

type Props = {
  className?: string;
  id: number;
  title: string;
  content: string;
  imgSrc?: string;
  category: string;
  createAt: string;
};

const KnowledgeCard: FC<Props> = ({
  className,
  id,
  title,
  content,
  imgSrc,
  category,
  createAt,
}) => {
  const { t } = useTranslation();
  return (
    <Link to={`/knowledges/${id}`}>
      <Container className={className}>
        <Image>{Boolean(imgSrc) && <img src={imgSrc} alt={title} />}</Image>
        <Main>
          <div>
            <Title>{title}</Title>
            <Description>{`${content.substring(0, 47)}...`}</Description>
          </div>
          <Remarks type={{ NEWS: 'orange' }[category] || 'lime'}>
            <span>
              {formatDate(
                new Date(createAt),
                t('sections.knowledge.dateFormat'),
              )}
            </span>
          </Remarks>
        </Main>
      </Container>
    </Link>
  );
};

export default KnowledgeCard;
