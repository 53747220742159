import { RawDocumentSubmission } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

type FetchSubmissionResponse = RawDocumentSubmission;
const fetchSubmission = async (
  id: number | string,
): Promise<FetchSubmissionResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.withAuth().get(`/api/document-submissions/${id}`);
  const data = await res.json();
  return data;
};

export default fetchSubmission;
