import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import classnames from 'classnames';
import { useMedia } from 'react-use';
import { Button } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { Knowledge } from '@type';
import { fetchKnowledges } from 'api/knowledge';
import AppToaster from 'components/AppToaster';
import { extractHtmlText } from 'utils/fn';
import { MEDIA_LG, MEDIA_SM } from 'styles/media';
import { locale2Lang } from 'utils/i18n';

import KnowledgeCard from './KnowledgeCard';

const Container = styled.div`
  text-align: center;
  overflow: hidden;
  position: relative;

  @media ${MEDIA_SM} {
    overflow: visible;
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 480px;
  padding: 0 40px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;
const SliderContainer = styled.div`
  margin: auto;
  margin-top: 68px;
  position: relative;
  min-height: 226px;

  @media ${MEDIA_SM} {
    margin-top: 47px;
  }
`;
const StyledSlider = styled(Slider)`
  max-width: 1206px;
  margin: auto;

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }

  .slick-list {
    padding-bottom: 60px;
    overflow: visible;

    .slick-slide.slick-cloned {
      visibility: hidden;
    }

    .slick-slide {
      visibility: visible;
      height: auto;

      > div {
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: 0px;
  }

  .slick-dots li button:before {
    font-size: 16px;
  }

  @media ${MEDIA_LG} {
    .slick-list {
      .slick-slide {
        > div {
          height: auto;
        }

        > div + div {
          margin-top: 40px;
        }
      }
    }
  }
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  padding: 0 24px;

  @media ${MEDIA_SM} {
    gap: 0;
  }
`;
const ExploreButton = styled(Button)`
  ${({ theme }) => theme.text.description};
  min-width: 100px;
  padding: 5px 20px;
  color: #69d795 !important;
  border-color: #69d795 !important;
  font-weight: 700;
  border-radius: 20px;
`;

type Props = {
  className?: string;
};
const KnowledgeSection: FC<Props> = ({ className }) => {
  const isLG = useMedia(MEDIA_LG);
  const isMobile = useMedia(MEDIA_SM);
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState<Knowledge[]>([]);
  const slides = Math.min(news.length, isLG ? 2 : 3);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await fetchKnowledges({ size: 9 });
        setNews(result.data);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
      setLoading(false);
    })();
  }, []);

  let Content = (
    <StyledSlider
      arrows={false}
      infinite={false}
      dots
      rows={isLG ? 2 : 1}
      slidesToShow={slides}
      slidesToScroll={slides}
      centerPadding="22px"
    >
      {news.map((p) => {
        const langData = p.attributes.localizations?.data?.find(
          (x) => x.attributes.locale === locale2Lang(i18n.language),
        );
        return (
          <KnowledgeCard
            key={p.id}
            id={p.id}
            title={langData?.attributes?.title || p.attributes.title}
            content={extractHtmlText(
              langData?.attributes?.content || p.attributes.content,
            )}
            imgSrc={p.attributes.cover?.data.attributes.url}
            category={p.attributes.category}
            createAt={p.attributes.createdAt}
          />
        );
      })}
    </StyledSlider>
  );
  if (isMobile) {
    Content = (
      <List>
        {news.slice(0, 3).map((p) => {
          const langData = p.attributes.localizations?.data?.find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <KnowledgeCard
              key={p.id}
              id={p.id}
              title={langData?.attributes?.title || p.attributes.title}
              content={extractHtmlText(
                langData?.attributes?.content || p.attributes.content,
              )}
              imgSrc={p.attributes.cover?.data.attributes.url}
              category={p.attributes.category}
              createAt={p.attributes.createdAt}
            />
          );
        })}
        <Link to="/knowledges">
          <ExploreButton outlined text={t('actions.explore')} />
        </Link>
      </List>
    );
  }

  return (
    <Container className={className}>
      <Header>{t('sections.knowledge.header')}</Header>
      <Title>{t('sections.knowledge.title')}</Title>
      <Description>{t('sections.knowledge.description')}</Description>
      <SliderContainer className={classnames({ 'bp4-skeleton': loading })}>
        {Content}
      </SliderContainer>
    </Container>
  );
};

export default KnowledgeSection;
