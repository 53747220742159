import FetcherFactory from 'utils/FetcherFactory';
import { UserSubscription } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchCheckoutResponse = {
  userSubscription: UserSubscription;
  discounts: {
    name: string;
    percent_off: number;
    amount_off: number;
  }[];
  invoiceURL: string;
};
const fetchCheckout = async (
  checkoutId: string,
): Promise<FetchCheckoutResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher
    .withAuth()
    .get(`/api/public-user-subscriptions/checkout/${checkoutId}`);
  const data = await res.json();
  return data;
};

export default fetchCheckout;
