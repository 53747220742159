import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import visionImage from 'assets/images/vision_image.webp';

import ThumbsUpBlock from '../ThumbsUpBlock';

const Media = styled.div`
  background-image: url(${visionImage}) !important;
  background-size: cover !important;
  background-position: center !important;
  height: 560px;
`;

type Props = {
  className?: string;
};
const VisionSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <ThumbsUpBlock
      className={className}
      header={t('sections.vision.header')}
      title={t('sections.vision.title')}
      description={t('sections.vision.description')}
      Media={<Media />}
    />
  );
};

export default VisionSection;
