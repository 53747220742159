import { Document } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchDocumentResponse = {
  data: Document;
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};
const fetchDocument = async (
  id: number | string,
): Promise<FetchDocumentResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.withAuth().get(`/api/documents/${id}`, {
    'populate[localizations][fields][0]': 'id',
    'populate[localizations][fields][1]': 'title',
    'populate[localizations][fields][2]': 'locale',
    'populate[localizations][fields][3]': 'description',
    'populate[localizations][fields][4]': 'popup_reminder',
    'populate[flow][populate]': '*',
    'populate[document_sub_category][fields][0]': 'id',
    'populate[document_sub_category][fields][1]': 'name',
  });
  const data = await res.json();
  return data;
};

export default fetchDocument;
