import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SubscriptionPlan } from '@type';

export type State = {
  loading: boolean;
  lookup: Record<number, SubscriptionPlan>;
};
export const initialState: State = {
  loading: false,
  lookup: {},
};

export const getAllActiveSubscriptionPlan = (state: { plans: State }) =>
  Object.values(state.plans.lookup).filter((x) =>
    Boolean(x.attributes.publishedAt),
  );

export const getSubscriptionPlan =
  (id: number) =>
  (state: { plans: State }): SubscriptionPlan | undefined =>
    state.plans.lookup[id];

export const {
  reducer: planReducer,
  actions: { fetchPlans, fetchPlansSuccess, fetchPlansFail },
} = createSlice({
  name: 'SUBSCRIPTION_PLAN',
  initialState,
  reducers: {
    fetchPlans: (state) => ({
      ...state,
      loading: true,
    }),
    fetchPlansFail: (state) => ({
      ...state,
      loading: false,
    }),
    fetchPlansSuccess(state, { payload }: PayloadAction<SubscriptionPlan[]>) {
      return {
        ...state,
        loading: false,
        lookup: payload.reduce(
          (memo, x) => ({
            ...memo,
            [x.id]: {
              ...memo[x.id],
              ...x,
            },
          }),
          state.lookup,
        ),
      };
    },
  },
});
