import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAllSubscriptions } from 'api/subscriptions';
import { fetchPlans, fetchPlansSuccess } from 'state/plans';
import { retryFn } from 'utils/fn';
import { AppToaster } from 'components';

const retryFetchPlans = retryFn(fetchAllSubscriptions);
const useInitPlansEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(fetchPlans());
        const response = await retryFetchPlans();
        dispatch(fetchPlansSuccess(response.data));
      } catch (e: any) {
        AppToaster.apiError(e);
      }
    })();
  }, []);
};

export default useInitPlansEffect;
