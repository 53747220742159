import { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  size?: string | number;
};
const Facebook: FC<Props> = ({
  size = 20,
  className,
  color = 'currentColor',
}) => (
  <svg
    width={size}
    height={size}
    viewBox="-3 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.66667 5.33333H0V8H2.66667V16H6.66667V8H9.09333L9.33333 5.33333H6.66667V4.22267C6.66667 3.58533 6.79467 3.33333 7.41067 3.33333H9.33333V0H6.128C3.73067 0 2.66667 1.056 2.66667 3.07733V5.33333Z"
      fill={color}
    />
  </svg>
);

export default Facebook;
