import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { FormGroup } from '@blueprintjs/core';
import PhoneInput from 'react-phone-number-input';
import { MEDIA_SM } from 'styles/media';

const StyleFormGroup = styled(FormGroup)`
  margin: 0;

  .bp4-label {
    ${({ theme }) => theme.text.inputLabel};
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  height: 52px;
  padding: 14px 24px;
  box-shadow: none;
  ${({ theme }) => theme.text.inputValue};

  @media ${MEDIA_SM} {
    padding: 0;
    padding-left: 16px;
    height: 38px;
  }

  input {
    background: transparent;
    outline: none;
    border: none;

    &:disabled {
      color: rgba(95, 107, 124, 0.6);
    }
  }
`;

type Props = {
  className?: string;
  label?: ReactNode;
  name?: string;
  value?: string;
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  onChange?: (name: string, value: string) => void;
  onBlur?: (name: string) => void;
};
const PhoneField: FC<Props> = ({
  className,
  name = '',
  label,
  value,
  disabled,
  touched,
  error,
  onChange,
  onBlur,
}) => (
  <StyleFormGroup
    className={className}
    label={label}
    helperText={touched && error}
    intent={touched && error ? 'danger' : 'none'}
    disabled={disabled}
  >
    <StyledPhoneInput
      defaultCountry="HK"
      value={value}
      onChange={(v: string) => onChange && onChange(name, v as string)}
      onBlur={() => onBlur?.(name)}
      disabled={disabled}
    />
  </StyleFormGroup>
);

export default PhoneField;
