import FetcherFactory from 'utils/FetcherFactory';
import { PublicUser } from '@type';

import StrapiPublicFetcher from '../StrapiPublicFetcher';

type UpdateMeRequest = {
  firstName: string;
  lastName: string;
  email?: string;
  meta: {
    contactPhone?: string;
    mobilePhone?: string;
    companySize?: string;
    industry?: string;
    jobTitle?: string;
    companyAddress?: string;
    companyName?: string;
  };
  documentSettings?: {
    showProfile?: boolean;
  };
  profilePic?: File | number | string | null;
};
const updateMe = async (param: UpdateMeRequest): Promise<PublicUser> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.form().withAuth().put(`/api/public-user/me`, param);
  const data = await res.json();
  return data;
};

export default updateMe;
