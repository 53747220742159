import { useState, useImperativeHandle, forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import { Dialog, Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { fetchPlanDocuments, FetchPlanDocumentsResponse } from 'api';
import { AppToaster } from 'components';
import { locale2Lang } from 'utils/i18n';
import { MEDIA_SM } from 'styles/media';

const ItemBox = styled.div`
  display: flex;
  gap: 8px;
  ${({ theme }) => theme.text.description};
  font-size: 12px;
  line-height: 1.5;

  &.header {
    font-weight: bold;
  }

  .category {
    flex: 1;
  }
  .documentList {
    flex: 2;
  }
`;
const StyledDialog = styled(Dialog)`
  .content {
    padding: 24px;

    @media ${MEDIA_SM} {
      padding: 12px;
    }
  }

  ${ItemBox} + ${ItemBox} {
    margin-top: 8px;
  }
`;

export interface PublicProductDetailDialog {
  open(id: number | string): void;
}
type Props = {
  className?: string;
};
const ProductDetailDialog = forwardRef<PublicProductDetailDialog, Props>(
  ({ className }, ref) => {
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState<FetchPlanDocumentsResponse | null>(null);
    const { t, i18n } = useTranslation();
    const raw = docs?.raw || null;

    const handleClose = useCallback(() => setDocs(null), []);

    useImperativeHandle(ref, () => ({
      open(id: string | number) {
        (async () => {
          try {
            setLoading(true);
            const res = await fetchPlanDocuments(id);
            setDocs(res);
          } catch (e: any) {
            AppToaster.apiError(e);
          } finally {
            setLoading(false);
          }
        })();
      },
    }));

    return (
      <StyledDialog
        isOpen={Boolean(docs) || loading}
        onClose={handleClose}
        className={className}
      >
        <div className="content">
          {loading && <Spinner />}
          {!loading && (
            <ItemBox className="header">
              <div className="category">{t('products.details.category')}</div>
              <div className="documentList">
                {t('products.details.documents')}
              </div>
            </ItemBox>
          )}
          {(docs?.categories || []).map((c) => {
            const langData = c.localizations.find(
              (x) => x.locale === locale2Lang(i18n.language),
            );
            const flatDocs = c.document_sub_categories.flatMap(
              (s) => s.documents,
            );
            return (
              <ItemBox key={c.id}>
                <div className="category">{langData?.name || c.name}</div>
                <div className="documentList">
                  {flatDocs.map((d) => {
                    const docLangData = d.localizations.find(
                      (x) => x.locale === locale2Lang(i18n.language),
                    );
                    return <div>{docLangData?.title || d.title}</div>;
                  })}
                </div>
              </ItemBox>
            );
          })}
          {Boolean(raw) && (
            <ItemBox>
              <div className="category" />
              <div className="documentList">
                {(raw || []).map((d) => {
                  const docLangData = d.localizations.find(
                    (x) => x.locale === locale2Lang(i18n.language),
                  );
                  return <div>{docLangData?.title || d.title}</div>;
                })}
              </div>
            </ItemBox>
          )}
        </div>
      </StyledDialog>
    );
  },
);

export default ProductDetailDialog;
