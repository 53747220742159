import { fetchMeMisc, updateMeMisc } from 'api/publicUsers';
import { sub } from 'date-fns';
import { last } from 'lodash';

class AutoCompleteMemo {
  // Record<string, [occurrence, last_date]>
  cache: Record<string, Record<string, [number, string]>> = {};

  scoreCache: Record<string, [string, number][]> = {};

  async load() {
    try {
      const res = await fetchMeMisc();
      this.cache = res.autocomplete || {};
    } catch (e) {
      console.error(e);
    }
  }

  async upload() {
    try {
      await updateMeMisc({
        autocomplete: this.cache,
      });
    } catch (e) {
      console.error(e);
    }
  }

  add(label: string, data: string): void {
    this.cache[label] ||= {};
    const now = new Date().toISOString().substring(0, 10);
    this.cache[label][data] = [(this.cache[label]?.[data]?.[0] || 0) + 1, now];
    delete this.scoreCache[label];
  }

  bulkAdd(list: [string, any][], lookup: Record<string, string>): void {
    list.forEach((el) => {
      if (typeof el[1] !== 'string') return;
      if (!el[1]) return;
      const end = last(el[0].split('.'));
      if (!end || !lookup[end]) return;
      this.add(lookup[end], el[1]);
    });
    this.upload();
  }

  getScore(label: string): [string, number][] {
    if (this.scoreCache[label]) return this.scoreCache[label];

    const lookup = this.cache[label] || {};
    const now = new Date();
    const weight = {
      week: sub(now, { weeks: 1 }).toISOString().substring(0, 10),
      month: sub(now, { months: 1 }).toISOString().substring(0, 10),
      year: sub(now, { years: 1 }).toISOString().substring(0, 10),
    };
    this.scoreCache[label] = Object.keys(lookup).map((k) => {
      const v = lookup[k];
      let w = 1;
      if (v[1] < weight.week) {
        w = 0.8;
      }
      if (v[1] < weight.month) {
        w = 0.5;
      }
      if (v[1] < weight.year) {
        w = 0.2;
      }
      return [k, v[0] * w];
    });
    return this.scoreCache[label];
  }

  search(label: string, query: string): string[] {
    const q = query.toLowerCase();
    const scoreArr = this.getScore(label);
    const filtered = scoreArr.filter((v) => v[0].toLowerCase().includes(q));
    return filtered.sort((a, b) => b[1] - a[1]).map((v) => v[0]);
  }
}

export default new AutoCompleteMemo();
