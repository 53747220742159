import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon, Spinner, Button } from '@blueprintjs/core';

import { TextField } from 'components';

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.0809);
  border-radius: 24px;
  padding: 14px 18px 14px 24px;

  .bp4-label {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.primary.text};
  }

  .bp4-form-group {
    flex: 1;
  }
`;

const IconContainer = styled.div`
  margin-left: 8px;
  width: 24px;
  height: 24px;

  .bp4-button {
    padding: 0;
    min-width: 0;
    min-height: 0;
    width: 100%;
  }

  svg {
    width: 24px !important;
    height: 24px !important;
  }
`;
const SuccessIcon = styled(Icon)`
  color: #fccb4a;
`;

type Props = {
  className?: string;
  loading?: boolean;
  success?: boolean;
  onAddClick?: (v: string) => void;
};

const PromoBlock: FC<Props> = ({ className, loading, success, onAddClick }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  return (
    <Container className={className}>
      <TextField
        name="promoCode"
        label={t('forms.paymentForm.promo')}
        inline
        value={value}
        disabled={loading || success}
        onChange={(e) => setValue(e.target.value)}
      />
      <IconContainer>
        {Boolean(value) && !loading && !success && (
          <Button minimal icon="add" onClick={() => onAddClick?.(value)} />
        )}
        {loading && <Spinner />}
        {success && <SuccessIcon icon="tick-circle" />}
      </IconContainer>
    </Container>
  );
};

export default PromoBlock;
