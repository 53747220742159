import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Facebook, Logo, Instagram, LinkedIn } from 'components/icons';
import i18n from 'utils/i18n';
import { MEDIA_LG, MEDIA_MD, MEDIA_SM } from 'styles/media';

const Container = styled.div`
  background: ${({ theme }) => theme.secondary.background};
`;
const MainBlock = styled.div`
  margin: auto;
  max-width: 1440px;
  color: ${({ theme }) => theme.secondary.text};
  padding: 92px 136px;

  @media ${MEDIA_LG} {
    padding: 60px 80px;
  }

  @media ${MEDIA_MD} {
    padding: 36px;
    padding-bottom: 100px;
  }
`;

const TopBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 8px;
  margin-bottom: 54px;

  @media ${MEDIA_MD} {
    grid-template-columns: 1fr 2fr;
  }

  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    margin-bottom: 0;
  }
`;
const MiddleBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;
const Footer = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 37px;
  display: flex;
  justify-content: space-between;

  > span {
    align-self: center;
  }

  > div:last-child {
    display: flex;

    > * + * {
      margin-left: 80px;
    }
  }

  @media ${MEDIA_SM} {
    flex-direction: column;
    border-top: none;

    > div:last-child {
      margin-top: 20px;
      justify-content: space-between;

      > * + * {
        margin-left: 0;
      }
    }
  }
`;
const Section = styled.div`
  .title {
    font-size: ${({ theme }) => theme.font.xl2};
    font-weight: ${({ theme }) => theme.font.bold};
    margin-bottom: 16px;
  }
`;
const FooterLinkButton = styled(Button)`
  display: block;
  padding: 0;
  ${({ theme }) => theme.text.footerLinkButton};

  &.bp4-button.bp4-minimal {
    color: ${({ theme }) => theme.secondary.text};
  }
`;
const StyledButton = styled(Button)`
  display: block;
  padding: 0;

  &.bp4-button.bp4-minimal {
    color: ${({ theme }) => theme.secondary.text};
  }
`;

const SocialButton = styled.a`
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5d737e;
`;
const SocialBlock = styled.div`
  display: flex;
  margin-top: 19px;
  margin-bottom: 12px;
  ${SocialButton} + ${SocialButton} {
    margin-left: 8px;
  }
`;

type Props = {
  className?: string;
};
const PublicFooter: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <MainBlock>
        <TopBlock>
          <Section>
            <Logo />
            <SocialBlock>
              <SocialButton
                href="//www.facebook.com/ibrilligroup"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit us in Facebook"
              >
                <Facebook />
              </SocialButton>
              <SocialButton
                href="//www.instagram.com/ibrilli_group"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit us in Instagram"
              >
                <Instagram />
              </SocialButton>
              <SocialButton
                href="//www.linkedin.com/company/i-brilli-legaltech"
                target="_blank"
                rel="noreferrer"
                aria-label="Visit us in Linkedin"
              >
                <LinkedIn />
              </SocialButton>
            </SocialBlock>
          </Section>
          <MiddleBlock>
            <Section>
              <div className="title">{t('public.footer.menu')}</div>
              <Link to="/about">
                <FooterLinkButton minimal text={t('public.footer.about')} />
              </Link>
              <Link to="/services">
                <FooterLinkButton minimal text={t('public.footer.services')} />
              </Link>
              <Link to="/knowledges">
                <FooterLinkButton minimal text={t('public.footer.knowledge')} />
              </Link>
              <Link to="/career">
                <FooterLinkButton minimal text={t('public.footer.career')} />
              </Link>
              <Link to="/faq">
                <FooterLinkButton minimal text={t('public.footer.faq')} />
              </Link>
            </Section>
            <Section>
              <div className="title">{t('public.footer.languages')}</div>
              <FooterLinkButton
                onClick={() => i18n.changeLanguage('en')}
                minimal
                text="English"
              />
              <FooterLinkButton
                onClick={() => i18n.changeLanguage('zh-HK')}
                minimal
                text="繁體中文"
              />
              <FooterLinkButton
                onClick={() => i18n.changeLanguage('zh-CN')}
                minimal
                text="简体中文"
              />
            </Section>
          </MiddleBlock>
        </TopBlock>
        <Footer>
          <span>{t('public.footer.copyright')}</span>
          <div>
            <Link to="/disclaimers">
              <StyledButton minimal>
                {t('public.footer.disclaimers')}
              </StyledButton>
            </Link>
            <Link to="/terms">
              <StyledButton minimal>{t('public.footer.terms')}</StyledButton>
            </Link>
            <Link to="/privacy">
              <StyledButton minimal>{t('public.footer.privacy')}</StyledButton>
            </Link>
          </div>
        </Footer>
      </MainBlock>
    </Container>
  );
};

export default PublicFooter;
