import { FC } from 'react';

type Props = {
  className?: string;
  color?: string;
  size?: string | number;
};
const NewTab: FC<Props> = ({
  size = 16,
  className,
  color = 'currentColor',
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    className={className}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M455.11,455.11H56.89V56.89H256V0H56.89C25.31,0,0,25.61,0,56.89v398.22C0,486.39,25.31,512,56.89,512h398.22
		c31.28,0,56.89-25.61,56.89-56.89V256h-56.89V455.11z M312.89,0v56.89H415L135.39,336.5l40.11,40.11L455.11,97v102.11H512V0H312.89
		z"
      />
    </g>
  </svg>
);

export default NewTab;
