// @deprecated
import { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@blueprintjs/core';

import { UserSubscription } from '@type';
import stripePromise from 'utils/stripe';
import { AppToaster } from 'components';
import { StripeBandge } from 'components/icons';
import { fetchUserSubscription } from 'api';

import PaymentForm from './PaymentForm';

const Container = styled.div`
  position: relative;
  padding: 0 56px;
`;
const Title = styled.h1`
  ${({ theme }) => theme.text.header};
`;
const StyledPaymentForm = styled(PaymentForm)`
  margin-top: 64px;
`;
const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const CheckOutPage: FC = () => {
  const { subsId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [subs, setSubs] = useState<UserSubscription | null>(null);
  const [isSetup, setIsSetup] = useState(true);

  const options = useMemo(
    () => ({
      appearance: {
        theme: 'stripe' as const,
        labels: 'floating' as const,
        variables: {
          colorBackground: '#f8f8f8',
        },
        rules: {
          '.Input': {
            border: 'none',
          },
        },
      },
      clientSecret,
    }),
    [clientSecret],
  );

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchUserSubscription(subsId || '');
        if (
          !['incomplete', 'trialing'].includes(result.userSubscription.status)
        ) {
          AppToaster.success('Payment already complete');
          navigate('/user/payments');
          return;
        }

        setClientSecret(result.clientSecret);
        setSubs(result.userSubscription);
        setIsSetup(result.isSetup || false);
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <Spinner />;
  if (!clientSecret) return <div>Resource not found</div>;

  return (
    <Container>
      <Title>{t('pages.checkOutPage.title')}</Title>
      <Elements stripe={stripePromise} options={options}>
        <StyledPaymentForm
          subsId={subsId || ''}
          isSetup={isSetup}
          planId={subs?.subscription_plan?.id || 0}
        />
      </Elements>
      <Footer>
        <StripeBandge />
      </Footer>
    </Container>
  );
};

export default CheckOutPage;
