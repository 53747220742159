import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const duplicateSubmission = async (id: number | string): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher
    .withAuth()
    .json()
    .post(`/api/document-submissions/${id}/duplicate`, {});
  const data = await res.json();
  return data;
};

export default duplicateSubmission;
