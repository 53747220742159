import { FC, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Button as BPButton,
  Menu,
  MenuItem,
  Icon,
  Collapse,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Link, NavLink } from 'react-router-dom';
import { useMedia, useClickAway } from 'react-use';

import { getRefreshToken } from 'state/app';
import { Button } from 'components';
import { Logo, Profile } from 'components/icons';
import { MEDIA_LG, MEDIA_MD } from 'styles/media';

const Container = styled.div`
  background: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(2px);
  height: 108px;
`;
const MainBlock = styled.div`
  margin: auto;
  padding: 36px 84px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${MEDIA_LG} {
    padding: 36px;
  }
`;

const NavBar = styled.div`
  color: ${({ theme }) => theme.secondary.text};

  * + * {
    margin-left: 40px;
  }

  > a span {
    font-weight: 300;
  }

  > a.active span {
    font-weight: 700;
  }

  @media ${MEDIA_MD} {
    display: flex;
    flex-direction: column;
    align-item: center;
    text-align: center;

    * + * {
      margin-left: 0;
    }

    > a {
      padding: 8px;
    }
  }
`;
const StyledNavLink = styled(NavLink)`
  > button span {
    color: ${({ theme }) => theme.secondary.text};
  }
`;
const IconButton = styled(BPButton)`
  &.bp4-button.bp4-minimal {
    color: ${({ theme }) => theme.secondary.text};
  }
  &.bp4-button.bp4-minimal .bp4-icon {
    color: ${({ theme }) => theme.secondary.text};
  }
`;
const LinkButton = styled(BPButton)`
  ${({ theme }) => theme.text.headerLinkButton};
`;
const RightBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const LoginButton = styled(Button)`
  min-width: 100px;
  padding: 0 20px;
`;

const TabletContainer = styled.div`
  background: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(2px);
`;
const Header = styled.div`
  height: 76px;
  padding: 12px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TabletLangBar = styled.div`
  display: flex;
  flex-direction: column;
  align-item: center;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.35);

  > a {
    padding: 8px;
  }

  & .bp4-button {
    font-size: 12px;
    color: ${({ theme }) => theme.secondary.text};

    @media ${MEDIA_MD} {
      font-size: 14px;
      font-weight: 400;
      padding: 13px;
    }
  }
`;

type Props = {
  className?: string;
};
const PublicHeader: FC<Props> = ({ className }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isAuth = Boolean(useSelector(getRefreshToken));
  const { t, i18n } = useTranslation();
  const isTablet = useMedia(MEDIA_MD);

  const onLinkClick = useCallback(() => setOpen(false), []);

  useClickAway(ref, () => {
    setOpen(false);
  });

  const List = (
    <NavBar>
      <StyledNavLink to="/about" onClick={onLinkClick}>
        <LinkButton minimal>{t('public.headers.about')}</LinkButton>
      </StyledNavLink>
      <StyledNavLink to="/services" onClick={onLinkClick}>
        <LinkButton minimal>{t('public.headers.services')}</LinkButton>
      </StyledNavLink>
      <StyledNavLink to="/knowledges" onClick={onLinkClick}>
        <LinkButton minimal>{t('public.headers.knowledge')}</LinkButton>
      </StyledNavLink>
      <StyledNavLink to="/career" onClick={onLinkClick}>
        <LinkButton minimal>{t('public.headers.career')}</LinkButton>
      </StyledNavLink>
      <StyledNavLink to="/faq" onClick={onLinkClick}>
        <LinkButton minimal>{t('public.headers.faq')}</LinkButton>
      </StyledNavLink>
    </NavBar>
  );

  if (isTablet) {
    return (
      <TabletContainer className={className} ref={ref}>
        <Header>
          <IconButton
            minimal
            icon={<Icon icon="menu" size={24} />}
            onClick={() => setOpen((o) => !o)}
            aria-label="Open menu"
          />
          <Link aria-label="HomePage" to="/" onClick={onLinkClick}>
            <Logo />
          </Link>
          <Link to={isAuth ? '/user' : '/login'}>
            <IconButton aria-label="Login" minimal icon={<Profile />} />
          </Link>
        </Header>
        <Collapse isOpen={open}>
          {List}
          <TabletLangBar>
            <LinkButton
              minimal
              text="English"
              onClick={() => {
                setOpen(false);
                i18n.changeLanguage('en');
              }}
            />
            <LinkButton
              minimal
              text="繁體中文"
              onClick={() => {
                setOpen(false);
                i18n.changeLanguage('zh-HK');
              }}
            />
            <LinkButton
              minimal
              text="简体中文"
              onClick={() => {
                setOpen(false);
                i18n.changeLanguage('zh-CN');
              }}
            />
          </TabletLangBar>
        </Collapse>
      </TabletContainer>
    );
  }

  return (
    <Container className={className}>
      <MainBlock>
        <Link aria-label="Home page" to="/">
          <Logo />
        </Link>
        {List}
        <RightBox>
          <Popover2
            position="bottom-left"
            content={
              <Menu>
                <MenuItem
                  text="English"
                  onClick={() => i18n.changeLanguage('en')}
                />
                <MenuItem
                  text="繁體中文"
                  onClick={() => i18n.changeLanguage('zh-HK')}
                />
                <MenuItem
                  text="简体中文"
                  onClick={() => i18n.changeLanguage('zh-CN')}
                />
              </Menu>
            }
          >
            <IconButton minimal icon="globe-network" />
          </Popover2>
          <Link to={isAuth ? '/user' : '/login'}>
            <LoginButton
              round
              outlined
              intent="success"
              text={
                isAuth ? t('public.profile.user') : t('public.profile.login')
              }
            />
          </Link>
        </RightBox>
      </MainBlock>
    </Container>
  );
};

export default PublicHeader;
