import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as img from 'assets/images';
import { useSelector } from 'react-redux';

import {
  SMESection,
  IndividualSection,
  FinancialSection,
  ProductSection,
} from 'containers';
import { MEDIA_LG, MEDIA_MD, MEDIA_SM } from 'styles/media';
import Tracker from 'utils/tracker';
import { userCategories, companyCategories } from 'constants/plan';
import { getUser } from 'state/app';
import { AccountType } from '@type';

const Container = styled.div`
  position: relative;
  padding-bottom: 180px;

  @media ${MEDIA_SM} {
    padding-bottom: 0;
  }
`;
const FrontBlock = styled.div`
  max-width: 1172px;
  margin: auto;
  margin-top: 108px;
  padding-top: 64px;
  display: flex;
  align-items: center;
  gap: 80px;

  @media ${MEDIA_LG} {
    gap: 20px;
  }

  @media ${MEDIA_MD} {
    flex-direction: column;
    padding-top: 0;
  }
`;
const LeftBlock = styled.div`
  text-align: left;
  flex: 1;
  max-width: 470px;

  @media ${MEDIA_MD} {
    max-width: 100%;
    padding: 0 40px;
  }

  @media ${MEDIA_SM} {
    padding: 0 24px;
  }
`;
const RightBlock = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-areas:
    'a a a b b b b b b b'
    '. c c c c c c c d d';
  grid-gap: 12px;
  grid-template-rows: repeat(2, 256px);

  @media ${MEDIA_MD} {
    padding: 0 40px;
  }

  @media ${MEDIA_MD} {
    padding: 0 24px;
  }

  .block1 {
    grid-area: a;

    > div {
      background-color: #fee5de;
      background-image: url(${img.serviceTopLeft});
      background-size: cover;
      border-radius: 100px 24px 24px 24px;
      width: 100%;
      height: 100%;
    }
  }
  .block2 {
    grid-area: b;

    > div {
      background: #d1fefa;
      background-image: url(${img.serviceTopRight});
      background-size: cover;
      border-radius: 24px 100px 24px 24px;
      width: 100%;
      height: 100%;
    }
  }
  .block3 {
    grid-area: c;

    > div {
      background: #fde6b9;
      background-image: url(${img.serviceBottomLeft});
      background-size: cover;
      border-radius: 24px 24px 24px 100px;
      width: 100%;
      height: 100%;
    }
  }
  .block4 {
    grid-area: d;
    justify-content: flex-start;

    > div {
      background: #ceedef;
      background-image: url(${img.serviceBottomRight});
      background-size: cover;
      border-radius: 16px 16px 32px 16px;
      width: 75%;
      height: 75%;
    }
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_MD} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  white-space: pre-line;
  ${({ theme }) => theme.text.description};
`;

const StyledSMESection = styled(SMESection)`
  margin-top: 200px;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    height: 100%;
    background: #e6f7e2;
    z-index: -1;
  }

  .content {
    max-width: 1440px;
    margin: auto;
  }

  @media ${MEDIA_MD} {
    margin-top: 73px;

    &::before {
      display: none;
    }
  }

  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;
const StyledIndividualSection = styled(IndividualSection)`
  margin-top: 128px;
  position: relative;
  z-index: 1;

  @media ${MEDIA_LG} {
    margin-top: 73px;

    &::before {
      display: none;
    }
  }
`;
const StyledFinancialSection = styled(FinancialSection)`
  margin-top: 128px;
  position: relative;
  z-index: 1;

  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;
const StyledProductSection = styled(ProductSection)`
  margin-top: 126px;

  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;

const ServicePage: FC<{}> = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  useEffect(() => {
    Tracker.track('Visited Service Page');
  }, []);
  return (
    <Container>
      <FrontBlock>
        <LeftBlock>
          <Header>{t('pages.service.header')}</Header>
          <Title>{t('pages.service.title')}</Title>
          <Description>{t('pages.service.description')}</Description>
        </LeftBlock>
        <RightBlock>
          <div className="block1">
            <div />
          </div>
          <div className="block2">
            <div />
          </div>
          <div className="block3">
            <div />
          </div>
          <div className="block4">
            <div />
          </div>
        </RightBlock>
      </FrontBlock>
      <StyledSMESection />
      <StyledIndividualSection />
      <StyledFinancialSection />
      <StyledProductSection
        categories={(() => {
          if (!user?.account_type) return undefined;
          return user.account_type === AccountType.COMPANY
            ? companyCategories
            : userCategories;
        })()}
      />
    </Container>
  );
};

export default ServicePage;
