import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { fetchChargeEvents } from 'api';
import { ChargeEvent } from '@type';
import { AppToaster, Button } from 'components';
import { locale2Lang } from 'utils/i18n';
import { MEDIA_SM } from 'styles/media';

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
`;
const Title = styled.div`
  padding: 42px 0 33px 44px;
  ${({ theme }) => theme.text.listSectionTitle};

  @media ${MEDIA_SM} {
    padding: 22px 0 22px 20px;
  }
`;

const TRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 27px 44px;
  align-items: baseline;

  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  color: #262626;

  border-bottom: 1px solid #d9d9d9;

  @media ${MEDIA_SM} {
    grid-template-columns: 2fr 3fr 2fr;
    font-size: 11px;
    line-height: 15px;
    padding: 22px 0 22px 20px;
    padding: 12px 20px;
  }
`;
const Table = styled.div`
  position: relative;
  padding-bottom: 40px;
`;
const THeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  padding: 30px 44px;

  background: #f0f2fb;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #262626;

  @media ${MEDIA_SM} {
    grid-template-columns: 2fr 3fr 2fr;
    font-size: 11px;
    line-height: 15px;
    padding: 22px 0 22px 20px;
    padding: 20px 12px;
  }
`;
const SaveButton = styled(Button)`
  width: 112px;

  @media ${MEDIA_SM} {
    min-height: 0;
    height: 27px !important;
    width: 76px;
    font-size: 10px !important;
    line-height: 14px !important;
  }
`;

type Props = {
  className?: string;
};
const PaymentHistorySection: FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState<ChargeEvent[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { results } = await fetchChargeEvents();
        setEvents(results);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
    })();
  }, []);

  return (
    <Container className={className}>
      <Title>{t('sections.paymentHistory.title')}</Title>
      <Table>
        <THeader>
          <div>{t('sections.paymentHistory.date')}</div>
          <div>{t('sections.paymentHistory.name')}</div>
          <div>{t('sections.paymentHistory.action')}</div>
        </THeader>
        {events.map((e) => {
          const plan = e.public_user_subscription.subscription_plan;
          const langData = plan?.localizations?.find?.(
            (x) => x.locale === locale2Lang(i18n.language),
          );
          return (
            <TRow key={e.id}>
              <div>{format(new Date(e.charged_at), 'dd/MM/yyyy')}</div>
              <div>
                {langData?.name || plan?.name || e.statement_descriptor}
              </div>
              <div>
                {e.receipt_url ? (
                  <a href={e.receipt_url} target="_blank" rel="noreferrer">
                    <SaveButton
                      text={t('actions.download')}
                      outlined
                      intent="success"
                      round
                    />
                  </a>
                ) : (
                  <span>{e.failure_message}</span>
                )}
              </div>
            </TRow>
          );
        })}
      </Table>
    </Container>
  );
};

export default PaymentHistorySection;
