import { FC } from 'react';

type Props = {
  size?: number | string;
  color?: string;
};
const Pencil: FC<Props> = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2774 4.3147L17.685 1.72237C17.4297 1.46696 17.1265 1.26435 16.7928 1.12612C16.4591 0.987894 16.1015 0.916748 15.7403 0.916748C15.3792 0.916748 15.0215 0.987894 14.6878 1.12612C14.3542 1.26435 14.051 1.46696 13.7956 1.72237L4.05328 11.4647L2.43262 19.5671L10.535 17.9465L20.2774 8.20412C20.5328 7.94875 20.7354 7.64557 20.8736 7.3119C21.0118 6.97822 21.083 6.62058 21.083 6.25941C21.083 5.89824 21.0118 5.5406 20.8736 5.20692C20.7354 4.87325 20.5328 4.57007 20.2774 4.3147ZM6.19462 11.9166L13.7956 4.3147L15.0927 5.61087L7.49078 13.2128L6.19462 11.9166ZM5.46128 13.7756L8.22412 16.5385L4.76645 17.2333L5.46128 13.7756ZM10.0831 15.8051L8.78695 14.509L16.3889 6.90704L17.685 8.20412L10.0831 15.8051ZM15.0918 3.01854C15.1769 2.93331 15.278 2.8657 15.3893 2.81956C15.5006 2.77343 15.6199 2.74969 15.7403 2.74969C15.8608 2.74969 15.9801 2.77343 16.0914 2.81956C16.2026 2.8657 16.3037 2.93331 16.3889 3.01854L18.9812 5.61087C19.0664 5.696 19.134 5.7971 19.1802 5.90838C19.2263 6.01966 19.25 6.13895 19.25 6.25941C19.25 6.37987 19.2263 6.49916 19.1802 6.61044C19.134 6.72172 19.0664 6.82282 18.9812 6.90795L15.0918 3.01854Z"
      fill={color}
    />
  </svg>
);

export default Pencil;
