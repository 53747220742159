import React, { createRef, PureComponent, ReactNode } from 'react';
import { Dialog, Classes, DialogProps } from '@blueprintjs/core';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import { MEDIA_SM } from 'styles/media';

import Button from './Button';

const ProtalDialog = ({ className, ...rest }: DialogProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Dialog {...rest} portalClassName={className} />
);

const StyledDialog = styled(ProtalDialog)`
  .bp4-dialog-body {
    ${({ theme }) => theme.text.cardDescription};

    @media ${MEDIA_SM} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .bp4-dialog-container {
    align-items: flex-start;
  }

  .bp4-dialog {
    margin-top: 30vh;
  }
`;

const StyledButton = styled(Button)`
  width: 120px;
`;

type State = {
  message: ReactNode | null;
  onClose?: () => any;
  onSuccess?: () => any;
};
export default class AppModalEl extends PureComponent<{}, State> {
  static ref =
    createRef<AppModalEl>() as React.MutableRefObject<AppModalEl | null>;

  public static instance() {
    if (AppModalEl.ref.current) return AppModalEl.ref.current;
    return undefined;
  }

  public static show(
    message: ReactNode,
    options?: { onClose?: () => any; onSuccess?: () => any },
  ) {
    AppModalEl.instance()?.setState?.({
      message,
      onClose: options?.onClose,
      onSuccess: options?.onSuccess,
    });
  }

  constructor(props: any) {
    super(props);
    this.state = {
      message: null,
      onClose: () => {},
      onSuccess: undefined,
    };
  }

  public componentDidMount(): void {
    AppModalEl.ref.current = this;
  }

  public componentWillUnmount(): void {
    AppModalEl.ref.current = null;
  }

  handleClose = (): void => {
    const { onClose } = this.state;
    this.setState({ message: null, onClose: () => {}, onSuccess: undefined });
    onClose?.();
  };

  handleSuccess = (): void => {
    const { onClose, onSuccess } = this.state;
    this.setState({ message: null, onClose: () => {}, onSuccess: undefined });
    if (onSuccess) {
      onSuccess();
    } else {
      onClose?.();
    }
  };

  public render() {
    const { message, onSuccess } = this.state;
    return (
      <StyledDialog isOpen={Boolean(message)} onClose={this.handleClose}>
        <div className={Classes.DIALOG_BODY}>{message}</div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {Boolean(onSuccess) && (
              <StyledButton
                onClick={this.handleClose}
                intent="none"
                text={
                  <Translation>
                    {(t) => <span>{t('actions.cancel')}</span>}
                  </Translation>
                }
              />
            )}
            <StyledButton
              onClick={this.handleSuccess}
              intent="success"
              text={
                <Translation>
                  {(t) => (
                    <span>
                      {onSuccess ? t('actions.confirm') : t('actions.ok')}
                    </span>
                  )}
                </Translation>
              }
            />
          </div>
        </div>
      </StyledDialog>
    );
  }
}
