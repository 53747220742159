import { UserSubscription } from '@type';
import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type CreateCheckoutResponse = {
  userSubscription: UserSubscription;
  url: string;
  checkoutId: string;
};
const createCheckout = async (
  planId: number,
): Promise<CreateCheckoutResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher
    .withAuth()
    .json()
    .post('/api/public-user-subscriptions/checkout', {
      planId,
    });
  const data = await res.json();
  return data;
};

export default createCheckout;
