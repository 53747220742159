import { FC } from 'react';
import styled from 'styled-components';
import { MEDIA_LG, MEDIA_SM } from 'styles/media';
import * as img from 'assets/images';

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 1156px;
  margin: auto;
  grid-gap: 28px;
  margin-top: 80px;

  @media screen and (max-width: 1156px) {
    padding: 0 24px;
    width: 100%;
  }

  > div {
    height: 360px;
    border-radius: 16px;
  }

  .block1 {
    background: #d5f7d4;
    grid-column: 1/4;
    background-size: cover !important;
  }
  .block2 {
    background: #fef6e7;
    grid-column: 4/6;
    background-size: cover !important;
  }
  .block3 {
    background: #e3fefb;
    grid-column: 1/3;
    background-size: cover !important;
  }
  .block4 {
    background: #f5e0da;
    grid-column: 3/6;
    background-size: cover !important;
  }
  .block1-faq {
    background: url(${img.faqTopLeft});
    background-position: center center;
  }
  .block2-faq {
    background: url(${img.faqTopRight});
    background-position: center center;
  }
  .block3-faq {
    background: url(${img.faqBottomLeft});
    background-position: center center;
  }
  .block4-faq {
    background: url(${img.faqBottomRight});
    background-position: center center;
  }
  .block1-career {
    background: url(${img.careerTopLeft});
    background-position: center center;
  }
  .block2-career {
    background: url(${img.careerTopRight});
    background-position: center center;
  }
  .block3-career {
    background: url(${img.careerBottomLeft});
    background-position: center center;
  }
  .block4-career {
    background: url(${img.careerBottomRight});
    background-position: center center;
  }

  @media ${MEDIA_LG} {
    grid-template-columns: 1fr 1fr;

    .block1,
    .block2,
    .block3,
    .block4 {
      grid-column: auto;
    }
  }

  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
    margin-top: 40px;
  }
`;

type Props = {
  className?: string;
  pageType?: 'faq' | 'career';
};
const ColorfulSection: FC<Props> = ({ className, pageType }) => (
  <Gallery className={className}>
    <div className={`block1 block1-${pageType}`} />
    <div className={`block2 block2-${pageType}`} />
    <div className={`block3 block3-${pageType}`} />
    <div className={`block4 block4-${pageType}`} />
  </Gallery>
);

export default ColorfulSection;
