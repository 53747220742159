import { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { OpenRoles } from '@type';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import { locale2Lang } from 'utils/i18n';
import { firstNWords } from 'utils/fn';
import { Button } from 'components';

const Container = styled.div`
  background: #e5f9ea;
  border-radius: 14px;
  text-align: left;
`;

const RoleButton = styled.div`
  padding: 16px 32px;
  padding-right: 24px;
  padding-bottom: 0;
  width: 100%;
  font-family: 'Open Sans';
  text-align: left;

  .first-row {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #54bcbd;
  }

  .level {
    font-size: 10px;
    line-height: 12px;
  }

  .job-type {
    background: #ffffff;
    border-radius: 8px;
    height: 28px;
    width: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0 12px;
    margin-top: 4px;

    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 12px;
    color: #5dc4b8;
  }

  @media ${MEDIA_SM} {
    .title {
      margin-bottom: 10px;
    }

    .level {
      font-size: 12px;
      margin-top: 10px;
    }
  }
`;
const Desc = styled.div.attrs({
  role: 'button',
  tabIndex: 1,
})`
  text-align: left;
  font-size: 14px;
  line-height: 32px;
  color: ${({ theme }) => theme.primary.text};
  transition: height 2s cubic-bezier(0.4, 1, 0.75, 0.9);
  cursor: pointer;

  @media ${MEDIA_MD} {
    margin-top: 12px;
    line-height: 1.5;
  }

  @media ${MEDIA_SM} {
    line-height: 20px;
  }
`;
const Footer = styled.div`
  padding: 16px 32px;
`;
const ApplyButton = styled(Button)`
  max-width: 180px;
  width: 100%;
`;

type Props = {
  className?: string;
  role: OpenRoles;
};
const RoleBlock: FC<Props> = ({ className, role }) => {
  const { t, i18n } = useTranslation();
  const langData = role.attributes.localizations?.data?.find(
    (x) => x.attributes.locale === locale2Lang(i18n.language),
  );
  const [open, setOpen] = useState(false);

  const desc =
    langData?.attributes.short_description ||
    role.attributes.short_description ||
    '';

  return (
    <Container className={className}>
      <RoleButton>
        <div className="first-row">
          <div>
            <div className="title">
              {langData?.attributes.title || role.attributes.title}
            </div>
            <div className="level">
              {t('sections.openRoles.level', {
                level: t(
                  `openRoles.level.${role.attributes.level
                    .replace(/\s/g, '')
                    .toLowerCase()}`,
                ),
              })}
            </div>
          </div>
          <div className="job-type">
            {t(
              `openRoles.jobType.${role.attributes.job_type
                .replace(/\s/g, '')
                .toLowerCase()}`,
            )}
          </div>
        </div>
        <Desc onClick={() => setOpen((o) => !o)}>
          {open ? desc : firstNWords(desc, 30)}
        </Desc>
      </RoleButton>
      <Footer>
        <Link to={`/career/${role.id}`}>
          <ApplyButton
            intent="success"
            round
            text={t('sections.openRoles.apply')}
          />
        </Link>
      </Footer>
    </Container>
  );
};

export default RoleBlock;
