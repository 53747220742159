import { Question } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchQuestionsResponse = {
  data: Question[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
};
const fetchQuestions = async (
  ids: number[],
  locale = 'en',
): Promise<FetchQuestionsResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.withPublicToken().get('/api/questions', {
    'populate[textMeta][populate]': '*',
    'populate[radioMeta][populate][options][populate]': '*',
    'populate[selectMeta][populate]': '*',
    'populate[dateMeta][populate]': '*',
    'populate[groupMeta][populate]': '*',
    'populate[constraints][populate]': '*',
    'populate[auto_complete_group][fields]': 'label',
    locale,
    ...(locale === 'en' && {
      'populate[localizations][populate]': '*',
    }),
    ...ids.reduce(
      (memo, id, idx) => ({
        ...memo,
        [`filters[id][$in][${idx}]`]: id,
      }),
      {},
    ),
  });
  const data = await res.json();
  return data;
};

export default fetchQuestions;
