import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MEDIA_SM } from 'styles/media';

import ColorfulSection from './ColorfulSection';

const Container = styled.div`
  position: relative;
  text-align: center;
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 780px;
  padding: 0 24px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;

type Props = {
  className?: string;
};
const LifeOfficeSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <Header>{t('sections.lifeOffice.header')}</Header>
      <Title>{t('sections.lifeOffice.title')}</Title>
      <Description>{t('sections.lifeOffice.description')}</Description>
      <ColorfulSection pageType="career" />
    </Container>
  );
};

export default LifeOfficeSection;
