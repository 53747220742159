export default class APIError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }

  async i18nKey(): Promise<string> {
    try {
      const res = await this.response.clone().json();
      return res?.error?.details?.i18nKey || '';
    } catch {
      return '';
    }
  }
}
