import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { format } from 'date-fns';
import { Popover2 } from '@blueprintjs/popover2';
import { Menu, MenuItem } from '@blueprintjs/core';
import { useMedia } from 'react-use';

import {
  fetchDocumentSubmissions,
  removeSubmission,
  duplicateSubmission,
  generateDocument,
} from 'api';
import { AppToaster, Button, Checkbox, TextField } from 'components';
import { Download } from 'components/icons';
import { DocumentSubmission } from '@type';
import { download } from 'utils/file';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import { locale2Lang } from 'utils/i18n';

import FilterModal, { initFilter, Filter } from './FilterModal';

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
  padding-bottom: 40px;
`;
const Header = styled.div`
  padding: 42px 44px 0 44px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${MEDIA_MD} {
    padding: 22px 12px 0 20px;
    justify-content: space-between;
  }
`;
const Title = styled.div`
  flex: 1;
  ${({ theme }) => theme.text.listSectionTitle};
`;
const SubHeader = styled.div`
  padding: 0 44px 0 44px;
  margin-top: 26px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  @media ${MEDIA_MD} {
    margin-top: 14px;
    padding: 0 12px 0 20px;
    margin-bottom: 0;
  }
`;
const ActionButton = styled(Button)<{ $hide?: boolean }>`
  visibility: ${({ $hide }) => ($hide ? 'hidden' : 'visible')};
  min-height: 0;
  padding: 0 !important;
  font-family: 'Open Sans';

  @media ${MEDIA_MD} {
    height: auto !important;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 11px !important;
    line-height: 15px !important;
  }
`;
const CircleButton = styled(Button)`
  border-radius: 100%;
  width: 44px;
  height: 44px;
`;
const SearchField = styled(TextField)`
  flex: 1;
  min-width: 232px;

  .bp4-input-group {
    padding-right: 0;
  }
`;
const SearchGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

const TRow = styled.div`
  display: grid;
  grid-template-columns: 7fr 2fr 2fr 1fr;
  padding: 27px 44px;
  align-items: baseline;

  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  color: #262626;
  white-space: pre-line;
  gap: 4px;

  @media ${MEDIA_MD} {
    grid-template-columns: 5fr 2fr 2fr 1fr;
    padding: 12px;
    padding-left: 22px;
    font-size: 11px;
    line-height: 15px;
  }

  > div:last-child {
    text-align: left;
  }

  a:hover {
    text-decoration: none;
  }

  .status-cell {
    display: flex;
    align-items: center;
    gap: 8px;

    .done {
      color: ${({ theme }) => theme.success.text};
    }
  }
`;
const Table = styled.div`
  position: relative;

  ${TRow} + ${TRow} {
    border-top: 1px solid #d9d9d9;
  }
`;
const THeader = styled.div`
  display: grid;
  grid-template-columns: 7fr 2fr 2fr 1fr;
  align-items: center;
  padding: 30px 44px;

  background: #f0f2fb;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #262626;
  gap: 4px;

  .bp4-control {
    font-size: 14px;
    line-height: 19px;
    color: #262626;
  }

  @media ${MEDIA_MD} {
    grid-template-columns: 5fr 2fr 2fr 1fr;
    padding: 12px;
    padding-left: 22px;
    font-size: 11px;
    line-height: 15px;

    .bp4-control {
      font-size: 11px;
      line-height: 15px;
    }
  }

  > div:last-child {
    text-align: left;
  }
`;
const NameButton = styled(Button)`
  &.bp4-button {
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;

    .sub {
      opacity: 0.5;
      font-size: 14px;
    }

    @media ${MEDIA_MD} {
      font-size: 11px;
      line-height: 15px;

      .sub {
        font-size: 10px;
      }
    }
  }
`;
const PaginationButton = styled(Button)``;
const Pagination = styled.div`
  padding: 0 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DownloadButton = styled(Button)`
  border-radius: 100%;
  width: 32px;
  height: 32px !important;

  @media ${MEDIA_SM} {
    min-height: 0;
    min-width: 0;
    width: 24px !important;
    height: 24px !important;

    svg {
      width: 10px !important;
      height: 10px !important;
    }
  }
`;

type Props = {
  className?: string;
};
const SubmissionListSection: FC<Props> = ({ className }) => {
  const isMobile = useMedia(MEDIA_SM);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<Filter | null>(null);
  const [search, setSearch] = useState('');
  const [docSubs, setDocSubs] = useState<DocumentSubmission[]>([]);
  const [pagination, setPagination] = useState({ page: 1, pageCount: 0 });
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const { t, i18n } = useTranslation();

  const searchSubmission = useCallback(
    async (
      f: {
        category?: number;
        subCategory?: number;
        status?: string[];
        label?: string;
        from?: string | null;
        to?: string | null;
      } = initFilter,
      page = 1,
    ) => {
      try {
        setSelected([]);
        setLoading(true);
        const result = await fetchDocumentSubmissions({
          categories: f.category ? [f.category] : [],
          subCategories: f.subCategory ? [f.subCategory] : [],
          status: f.status,
          label: f.label,
          from: f.from,
          to: f.to,
          page,
          pageSize: 10,
        });
        setDocSubs(result.data);
        setPagination(result.meta.pagination);
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    },
    [],
  );
  const refresh = useCallback(
    (page = pagination.page) => {
      searchSubmission(
        {
          ...(filter || initFilter),
          label: search,
        },
        page,
      );
    },
    [filter, search, pagination.page],
  );
  const debouncedSearch = useMemo(() => debounce(searchSubmission, 300), []);

  const downloadDoc = useCallback(
    async (id: number, locale?: string, filename = 'preview') => {
      try {
        setDownloading(true);
        const [, blob] = await generateDocument(id, locale);
        download(blob, `${filename}.pdf`);
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setDownloading(false);
      }
    },
    [],
  );
  useEffect(() => {
    searchSubmission(initFilter, 1);
  }, []);

  const Search = (
    <SearchField
      prefix="search"
      type="search"
      placeholder={t('sections.documentSubmissionList.search')}
      value={search}
      onChange={(e) => {
        const label = e.currentTarget.value;
        setSearch(label);
        debouncedSearch({ ...(filter || initFilter), label }, 1);
      }}
    />
  );

  return (
    <Container className={className}>
      <Header>
        <Title>{t('sections.documentSubmissionList.title')}</Title>
        <SearchGroup>
          {!isMobile && Search}
          <CircleButton prefix="filter-list" onClick={() => setOpen(true)} />
          <Link to="/user/documents/new">
            <CircleButton prefix="plus" />
          </Link>
        </SearchGroup>
      </Header>
      {isMobile && <SubHeader>{Search}</SubHeader>}
      <SubHeader>
        <ActionButton
          intent="warning"
          minimal
          text={t('sections.documentSubmissionList.duplicate')}
          onClick={async () => {
            const sub = docSubs.find(
              (d) => d.id === selected[0],
            ) as DocumentSubmission;
            try {
              await duplicateSubmission(sub.id);
              refresh();
              AppToaster.success('Duplicated');
              refresh();
            } catch (e: any) {
              AppToaster.apiError(e);
            }
          }}
          $hide={selected.length === 0}
          disabled={selected.length !== 1}
        />
        <ActionButton
          intent="warning"
          minimal
          text={t('sections.documentSubmissionList.delete')}
          onClick={async () => {
            try {
              await Promise.all(selected.map((id) => removeSubmission(id)));
              AppToaster.success('Submission Removed');
              refresh();
            } catch (e: any) {
              AppToaster.apiError(e);
            }
          }}
          $hide={selected.length === 0}
        />
      </SubHeader>
      <Table>
        <THeader>
          <div>{t('sections.documentSubmissionList.name')}</div>
          <div>{t('sections.documentSubmissionList.updatedAt')}</div>
          <div>{t('sections.documentSubmissionList.status')}</div>
          <div>
            <Checkbox
              label={t('sections.documentSubmissionList.action')}
              checked={
                selected.length !== 0 && selected.length === docSubs.length
              }
              onChange={(e) =>
                e.currentTarget.checked
                  ? setSelected(docSubs.map((d) => d.id))
                  : setSelected([])
              }
            />
          </div>
        </THeader>
        {docSubs.map((d) => (
          <TRow key={d.id}>
            <div>
              <Link to={`/user/documents/submissions/${d.id}`}>
                <NameButton
                  minimal
                  text={
                    <>
                      <div className="main">
                        {d.attributes.label ||
                          d.attributes.document?.data?.attributes?.title}
                      </div>
                      <div className="sub">
                        {d.attributes.document?.data?.attributes?.localizations?.data?.find?.(
                          (l) =>
                            l.attributes.locale === locale2Lang(i18n.language),
                        )?.attributes?.title ||
                          d.attributes.document?.data?.attributes?.title}
                      </div>
                    </>
                  }
                />
              </Link>
            </div>
            <div>
              {format(new Date(d.attributes.updatedAt), 'dd/MM/yyyy HH:mm')}
            </div>
            <div className="status-cell">
              {d.attributes.status === 'DONE' ? (
                <>
                  <span className="done">
                    {t('sections.documentSubmissionList.status.done')}
                  </span>
                  <Popover2
                    position="bottom-left"
                    content={
                      <Menu>
                        <MenuItem
                          text="English"
                          disabled={downloading}
                          onClick={() =>
                            downloadDoc(d.id, 'en', d.attributes.label)
                          }
                        />
                        <MenuItem
                          text="繁體中文"
                          disabled={downloading}
                          onClick={() =>
                            downloadDoc(d.id, 'zh-Hant-HK', d.attributes.label)
                          }
                        />
                        <MenuItem
                          text="简体中文"
                          disabled={downloading}
                          onClick={() =>
                            downloadDoc(d.id, 'zh-CN', d.attributes.label)
                          }
                        />
                      </Menu>
                    }
                  >
                    <DownloadButton
                      loading={downloading}
                      intent="success"
                      prefix={<Download size="14" />}
                    />
                  </Popover2>
                </>
              ) : (
                <span>
                  {t('sections.documentSubmissionList.status.pending')}
                </span>
              )}
            </div>
            <div>
              <Checkbox
                checked={selected.includes(d.id)}
                onChange={(e) => {
                  const temp = selected.filter((x) => x !== d.id);
                  const next = e.currentTarget.checked ? [...temp, d.id] : temp;
                  setSelected(next);
                }}
              />
            </div>
          </TRow>
        ))}
      </Table>
      <Pagination>
        <PaginationButton
          text={t('actions.previousPage')}
          prefix="arrow-left"
          disabled={pagination.page <= 1}
          loading={loading}
          minimal
          intent="success"
          onClick={() => refresh(pagination.page - 1)}
        />
        <PaginationButton
          text={t('actions.nextPage')}
          suffix="arrow-right"
          disabled={pagination.pageCount <= pagination.page}
          loading={loading}
          minimal
          intent="success"
          onClick={() => refresh(pagination.page + 1)}
        />
      </Pagination>
      <FilterModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onFilterChange={(f) => {
          setFilter(f);
          searchSubmission({ ...f, label: search }, 1);
        }}
      />
    </Container>
  );
};

export default SubmissionListSection;
