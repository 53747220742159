import {
  track,
  init,
  Identify,
  identify,
  reset,
} from '@amplitude/analytics-browser';
import { PublicUser } from '@type';

const IGNORE_LIST = new Set(['%locale%']);

export const deepFlattenTracker = (x: any): [string, any][] => {
  if (!x) return [['', x]];
  if (Array.isArray(x)) {
    if (x.length === 0) return [];
    const arr = x.map(deepFlattenTracker);
    const temp = arr[0].map(
      ([attr], idx) => [attr, arr.map((ar) => ar[idx][1])] as [string, any],
    );
    return temp;
  }
  if (typeof x === 'object') {
    const out = Object.entries(x).flatMap(([name, value]) => {
      if (IGNORE_LIST.has(name)) return [];
      const temp = deepFlattenTracker(value);
      return temp.map(
        ([n, v]) => [n ? `${name}.${n}` : name, v] as [string, any],
      );
    });
    return out;
  }
  if (String(x).startsWith('data:image/png')) {
    // skip singature
    return [];
  }
  return [['', x]];
};
export default class Tracker {
  static init() {
    init(process.env.AMPLITUDE_API_TOKEN || '');
  }

  static track(event: string, properties?: Record<string, any>) {
    try {
      track(event, properties);
    } catch {
      // do nothing
    }
  }

  static identify(user: PublicUser) {
    const event = new Identify();
    event.set('firstName', user.first_name);
    event.set('lastName', user.last_name);
    event.set('contactNumber', user.meta?.contactPhone || '');
    event.set('mobile', user.meta?.mobilePhone || '');
    event.set('email', user.email);
    event.set('industry', user.meta?.industry || '');
    event.set('companySize', user.meta?.companySize || '');
    event.set('jobTitle', user.meta?.jobTitle || '');
    event.set('address', user.meta?.companyAddress || '');
    identify(event);
  }

  static reset() {
    reset();
  }

  static trackSubmission(docName: string, docId: number, sub: any) {
    const toTrack = deepFlattenTracker(sub.data);
    toTrack.forEach((t) => {
      Tracker.track('Answered Document Question', {
        document: docName,
        documentId: docId,
        question: t[0],
        answer: t[1],
      });
    });
  }
}
