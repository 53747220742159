import { FC } from 'react';
import styled from 'styled-components';
import { Icon, Button } from '@blueprintjs/core';

const Container = styled.div`
  position: relative;
`;
const StyledLabel = styled.label`
  background: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
  }
`;
const Mask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  opacity: 0;
  border-radius: 100%;

  &:hover {
    opacity: 0.5;
    backdrop-filter: blur(2px);
  }
`;

// sq root(sq root 2 - 1) / 2
const ClearButton = styled(Button)`
  position: absolute;
  top: 10%;
  left: 90%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
`;

type Props = {
  className?: string;
  name?: string;
  value?: File | string | null;
  disabled?: boolean;
  onChange?: (name: string, value: File | null) => void;
};
const ImageInput: FC<Props> = ({
  className,
  name = '',
  value,
  disabled,
  onChange,
}) => {
  let Preview = <Icon icon="media" size={32} />;
  if (value) {
    const src = value instanceof File ? URL.createObjectURL(value) : value;
    Preview = <img src={src} alt="profile" />;
  }
  return (
    <Container className={className}>
      <StyledLabel>
        <input
          type="file"
          name={name}
          hidden
          disabled={disabled}
          onChange={(e) => {
            const { files } = e.target;
            if (!files?.length) return;
            if (onChange) onChange(e.target.name, files[0]);
            // reset form value to reselect again
            e.target.value = '';
          }}
        />
        {Preview}
        {!disabled && (
          <Mask>
            <Icon icon="plus" size={64} />
          </Mask>
        )}
      </StyledLabel>
      {!disabled && value !== null && (
        <ClearButton onClick={() => onChange?.(name, null)}>
          <Icon icon="cross" />
        </ClearButton>
      )}
    </Container>
  );
};

export default ImageInput;
