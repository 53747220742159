import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@blueprintjs/core';

import Tracker from 'utils/tracker';
import { MEDIA_MD } from 'styles/media';
import { fetchTermsPage } from 'api/staticPage';
import { AppToaster } from 'components';
import { locale2Lang } from 'utils/i18n';

const Container = styled.div`
  position: relative;
  padding: 148px 30px 120px;
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_MD} {
    margin: 0;
  }
`;
const Content = styled.div`
  display: block;
  white-space: pre-line;
  ${({ theme }) => theme.text.description};
`;

const TermsPage: FC<{}> = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] =
    useState<Awaited<ReturnType<typeof fetchTermsPage>>['data']>();

  useEffect(() => {
    Tracker.track('Visited Terms of Use Page');
    (async () => {
      try {
        const res = await fetchTermsPage();
        setPage(res.data);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
      setLoading(false);
    })();
  }, []);

  const langData = page?.attributes?.localizations?.data?.find(
    (x) => x.attributes.locale === locale2Lang(i18n.language),
  );

  return (
    <Container>
      {loading && <Spinner />}
      <Title>{langData?.attributes?.title || page?.attributes?.title}</Title>
      <Content
        className="ck-content"
        dangerouslySetInnerHTML={{
          __html:
            langData?.attributes?.content || page?.attributes?.content || '',
        }}
      />
    </Container>
  );
};

export default TermsPage;
