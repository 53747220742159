export const downloadLink = (url: string, name: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.target = '_blank';
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

export const download = (data: Blob, name: string) => {
  const url = window.URL.createObjectURL(data);
  downloadLink(url, name);
  window.URL.revokeObjectURL(url);
};
