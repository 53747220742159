import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { OpenRoles } from '@type';
import { fetchOpenRoles } from 'api/openRoles';
import AppToaster from 'components/AppToaster';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';

import RoleBlock from './RoleBlock';

const Container = styled.div`
  position: relative;
  text-align: center;

  @media ${MEDIA_MD} {
    padding: 0 20px;
  }
`;

const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 1000px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;

const StyledRoleBlock = styled(RoleBlock)``;

const RoleList = styled.ul`
  list-decoration: none;
  margin: auto;
  width: 780px;
  margin-top: 40px;

  @media screen and (max-width: 780px) {
    width: 100%;
    padding: 0 24px;
  }

  ${StyledRoleBlock} + ${StyledRoleBlock} {
    margin-top: 16px;
  }

  @media ${MEDIA_MD} {
    padding: 0;

    ${StyledRoleBlock} + ${StyledRoleBlock} {
      margin-top: 30px;
    }
  }
`;

const NoOpenRoleText = styled.div`
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.primary.text};
  margin-top: 30px;
`;

type Props = {
  className?: string;
};

const OpenRolesSection: FC<Props> = ({ className }) => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState<OpenRoles[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchOpenRoles();
        setRoles(data);
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Container className={className}>
      <Header>{t('sections.openRoles.header')}</Header>
      <Title>{t('sections.openRoles.title')}</Title>
      <Description>{t('sections.openRoles.description')}</Description>
      {roles.length > 0 ? (
        <RoleList className={classNames({ 'bp4-skeleton': loading })}>
          {roles.map((r) => (
            <StyledRoleBlock key={r.id} role={r} />
          ))}
        </RoleList>
      ) : (
        <NoOpenRoleText>{t('pages.career.noOpen')}</NoOpenRoleText>
      )}
    </Container>
  );
};

export default OpenRolesSection;
