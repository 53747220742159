export type Interval = 'day' | 'week' | 'month' | 'year' | 'never';

export enum PlanTargetType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
  ALL = 'all',
}

export type SubscriptionPlan = {
  id: number;
  attributes: {
    name: string;
    description: string;
    features: string | null;
    target_type: PlanTargetType;
    active: boolean;
    currency: string;
    price: number;
    credit: null | number;
    statement_descriptor: string;
    charge_interval: Interval;
    trial_duration: number | null;
    expire_duration: number | null;
    expire_duration_interval: number | null;
    stripe_product_id: string;
    latest_stripe_price_id: string;
    publishedAt: string;
    document_categories: {
      data: {
        id: number;
        attributes: {
          name: string;
          localizations: {
            data: {
              attributes: {
                name: string;
                locale: string;
              };
            }[];
          };
        };
      }[];
    };
    document_sub_categories: {
      data: {
        id: number;
        attributes: {
          name: string;
          localizations: {
            data: {
              attributes: {
                name: string;
                locale: string;
              };
            }[];
          };
        };
      }[];
    };
    documents: {
      data: {
        id: number;
        attributes: {
          title: string;
          localizations: {
            data: {
              attributes: {
                title: string;
                locale: string;
              };
            }[];
          };
        };
      }[];
    };
    localizations: {
      data: {
        id: number;
        attributes: {
          name: string;
          description: string;
          features: string | null;
          locale: string;
        };
      }[];
    };
  };
};

export type FlatSubscriptionPlan = {
  id: number;
  name: string;
  description: string;
  active: boolean;
  currency: string;
  price: number;
  statement_descriptor: string;
  charge_interval: Interval;
  trial_duration: number | null;
  stripe_product_id: string;
  latest_stripe_price_id: string;
};

export type UserSubscription = {
  id: number;
  createdAt: string;
  updatedAt: string;
  expire_at: string;
  start_at: string;
  stripe_subscription_id: null | string;
  status:
    | 'active'
    | 'incomplete'
    | 'trialing'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'incomplete_expired';
  remain_credit: number;
  last_fail_event: null | any;
  last_charged_at: string;
  subscription_plan: {
    id: number;
    name: string;
  };
};
