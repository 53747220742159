import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner, Icon } from '@blueprintjs/core';

import { AppToaster, Button } from 'components';
import { fetchCheckout, fetchUserSubscription } from 'api/subscriptions';
import { UserSubscription } from '@type';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import Tracker from 'utils/tracker';

import OrderDetailBlock from './OrderDetailBlock';
// import PromoBlock from './PromoBlock';

const Container = styled.div`
  position: relative;
  padding: 0 56px;
  flex: 1;

  @media ${MEDIA_MD} {
    margin-top: 76px;
  }

  @media ${MEDIA_SM} {
    margin-top: 56px;
    padding: 0 38px;
    padding-bottom: 80px;
  }
`;
const Title = styled.h1`
  ${({ theme }) => theme.text.header};
`;
const Content = styled.div`
  margin-top: 64px;
  display: flex;
  gap: 30px;

  @media ${MEDIA_SM} {
    margin-top: 24px;
    flex-direction: column-reverse;
  }
`;
const LeftBlock = styled.div`
  flex: 4;
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.0809);
  border-radius: 100px 24px 24px 24px;
  padding: 70px 54px 54px 75px;
  text-align: center;

  @media ${MEDIA_MD} {
    border-radius: 16px;
    padding: 28px 0 32px 0;
  }

  svg {
    width: 96px;
    height: 96px;

    @media ${MEDIA_SM} {
      width: 64px;
      height: 64px;
    }
  }

  .result-message {
    margin-top: 40px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.primary.text};
    padding: 0 28px;

    @media ${MEDIA_MD} {
      margin-top: 24px;
    }
  }

  .result-actions {
    margin-top: 20px;

    svg {
      width: 16px;
      height: 16px;
    }

    * + * {
      margin-left: 8px;
    }
  }

  @media ${MEDIA_MD} {
    flex: 1;
  }
`;
const RightBlock = styled.div`
  flex: 3;

  @media ${MEDIA_MD} {
    flex: 1;
  }
`;
const NotFound = styled.div`
  margin-top: 40px;
  text-align: center;
  font-family: 'Super Mario Bros. 2';
  font-style: normal;
  font-size: 32px;
  line-height: 44px;
  color: #262626;
  max-width: 360px;
  margin: auto;
`;

const CheckOutResultPage = () => {
  const [params] = useSearchParams();
  const checkoutId = params.get('session_id') || '';
  const subsId = params.get('user_subscription_id') || '';
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [subs, setSubs] = useState<UserSubscription | null>(null);
  const [status, setStatus] = useState<'success' | 'error' | 'processing'>(
    'error',
  );
  const [message, setMessage] = useState('');
  const [invoice, setInvoice] = useState('');
  const [discounts, setDiscounts] = useState<
    { name: string; percent_off: number | null; amount_off: number | null }[]
  >([]);

  const handleInvoiceClick = () => window.open(invoice, '_blank');

  useEffect(() => {
    // eslint-disable-next-line complexity
    (async () => {
      if (!checkoutId && !subsId) return;
      if (checkoutId) {
        try {
          const result = await fetchCheckout(checkoutId);
          setSubs(result.userSubscription);

          // let intentStatus;/
          if (!result.invoiceURL) {
            setMessage('pages.checkOutResultPage.cancel');
            setStatus('error');
            return;
          }
          setStatus('success');
          setMessage('pages.checkOutResultPage.success');
          setDiscounts(result.discounts);
          setInvoice(result.invoiceURL || '');
          Tracker.track('Purchased Package', {
            packageName: result.userSubscription.subscription_plan.name,
          });
        } catch (e: any) {
          setMessage('pages.checkOutResultPage.error');
          setStatus('error');
          AppToaster.apiError(e);
        } finally {
          setLoading(false);
        }
      }
      if (subsId) {
        try {
          const result = await fetchUserSubscription(subsId);
          setSubs(result.userSubscription);
          setStatus('success');
          setMessage('pages.checkOutResultPage.success');
          setDiscounts([]);
          setInvoice('');
          Tracker.track('Purchased Package', {
            packageName: result.userSubscription.subscription_plan.name,
          });
        } catch (e: any) {
          setMessage('pages.checkOutResultPage.error');
          setStatus('error');
          AppToaster.apiError(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  if (!checkoutId && !subsId) {
    return <NotFound>{t('pages.checkOutResultPage.notFound')}</NotFound>;
  }
  if (loading) return <Spinner />;

  const IconLookup = {
    success: <Icon icon="tick-circle" color="#69d795" />,
    error: <Icon icon="error" color="red" />,
    processing: <Icon icon="updated" color="#fccb4a" />,
  };

  return (
    <Container>
      <Title>{t('pages.checkOutResultPage.title')}</Title>
      <Content>
        <LeftBlock>
          {IconLookup[status]}
          <div className="result-message">{t(message)}</div>
          <div className="result-actions">
            <Link to="/user/documents/new">
              <Button
                intent="success"
                text={t('pages.checkOutResultPage.primaryCTA')}
              />
            </Link>
            {invoice && (
              <Button
                outlined
                onClick={handleInvoiceClick}
                text={t('pages.checkOutResultPage.invoice')}
                suffix="share"
                intent="success"
              />
            )}
          </div>
        </LeftBlock>
        <RightBlock>
          <OrderDetailBlock
            planId={subs?.subscription_plan?.id || 0}
            discounts={discounts}
            trial={subs?.status === 'trialing'}
          />
        </RightBlock>
      </Content>
    </Container>
  );
};

export default CheckOutResultPage;
