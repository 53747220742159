// @deprecated
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner, Icon } from '@blueprintjs/core';

import stripePromise from 'utils/stripe';
import { AppToaster, Button } from 'components';
import { fetchUserSubscription } from 'api/subscriptions';
import { UserSubscription } from '@type';

import OrderDetailBlock from './OrderDetailBlock';
// import PromoBlock from './PromoBlock';

const Container = styled.div`
  position: relative;
  padding: 0 56px;
`;
const Title = styled.h1`
  ${({ theme }) => theme.text.header};
`;
const Content = styled.div`
  margin-top: 64px;
  display: flex;
  gap: 30px;
`;
const LeftBlock = styled.div`
  flex: 4;
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.0809);
  border-radius: 100px 24px 24px 24px;
  padding: 70px 54px 54px 75px;
  text-align: center;

  svg {
    width: 96px;
    height: 96px;
  }

  .result-message {
    margin-top: 40px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: ${({ theme }) => theme.primary.text};
  }

  .result-actions {
    margin-top: 20px;

    svg {
      width: 16px;
      height: 16px;
    }

    * + * {
      margin-left: 8px;
    }
  }
`;
const RightBlock = styled.div`
  flex: 3;
`;

const CheckOutResultPage = () => {
  const [params] = useSearchParams();
  const { subsId } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [subs, setSubs] = useState<UserSubscription | null>(null);
  const [status, setStatus] = useState<'success' | 'error' | 'processing'>(
    'error',
  );
  const [message, setMessage] = useState('');
  const [invoice, setInvoice] = useState('');

  const handleInvoiceClick = () => window.open(invoice, '_blank');

  useEffect(() => {
    // eslint-disable-next-line complexity
    (async () => {
      try {
        const result = await fetchUserSubscription(subsId || '');
        setSubs(result.userSubscription);
        const stripe = await stripePromise;
        const clientSecret =
          params.get('setup_intent_client_secret') ||
          params.get('payment_intent_client_secret') ||
          result.clientSecret;

        let stripeResult;
        let intentStatus;
        const isSetup =
          Boolean(params.get('setup_intent_client_secret')) || result.isSetup;
        if (isSetup) {
          stripeResult = await stripe?.retrieveSetupIntent(clientSecret);
          intentStatus = stripeResult?.setupIntent?.status;
        } else {
          stripeResult = await stripe?.retrievePaymentIntent(clientSecret);
          intentStatus = stripeResult?.paymentIntent?.status;
        }
        if (!stripeResult) {
          setMessage('pages.checkOutResultPage.success');
          setStatus('error');
          return;
        }
        const statusLookup: Record<string, any> = {
          succeeded: 'success',
          processing: 'processing',
          requires_payment_method: 'error',
        };
        const messageLookup: Record<string, string> = {
          succeeded: 'pages.checkOutResultPage.success',
          processing: 'pages.checkOutResultPage.processing',
          requires_payment_method:
            'pages.checkOutResultPage.requires_payment_method',
        };
        setStatus(statusLookup[intentStatus || ''] || 'error');
        setMessage(
          messageLookup[intentStatus || ''] || 'pages.checkOutResultPage.error',
        );
        setInvoice(result.invoiceURL || '');
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <Spinner />;

  const IconLookup = {
    success: <Icon icon="tick-circle" color="#69d795" />,
    error: <Icon icon="error" color="red" />,
    processing: <Icon icon="updated" color="#fccb4a" />,
  };

  return (
    <Container>
      <Title>{t('pages.checkOutResultPage.title')}</Title>
      <Content>
        <LeftBlock>
          {IconLookup[status]}
          <div className="result-message">{t(message)}</div>
          <div className="result-actions">
            <Link to="/user/documents/new">
              <Button
                intent="success"
                text={t('pages.checkOutResultPage.primaryCTA')}
              />
            </Link>
            {invoice && (
              <Button
                outlined
                onClick={handleInvoiceClick}
                text={t('pages.checkOutResultPage.invoice')}
                suffix="share"
                intent="success"
              />
            )}
          </div>
        </LeftBlock>
        <RightBlock>
          <OrderDetailBlock planId={subs?.subscription_plan?.id || 0} />
        </RightBlock>
      </Content>
    </Container>
  );
};

export default CheckOutResultPage;
