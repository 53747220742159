import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getRefreshToken } from 'state/app';

type Props = {
  onLogin?: () => void;
};
const useRedirectUserEffect = ({ onLogin }: Props = {}) => {
  const isLogin = Boolean(useSelector(getRefreshToken));
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      if (onLogin) {
        onLogin();
      } else {
        navigate('/user');
      }
    }
  }, [isLogin]);
};

export default useRedirectUserEffect;
