import { FC, useMemo, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Button } from '@blueprintjs/core';
import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';

import { getActiveSubs } from 'state/app';
import { getAllActiveSubscriptionPlan } from 'state/plans';
import { MEDIA_SM, MEDIA_LG } from 'styles/media';
import { locale2Lang } from 'utils/i18n';
import { PlanTargetType } from '@type';
import { MuiSelectField } from 'components';

import Price from './Price';
import ProductDetailDialog, {
  PublicProductDetailDialog,
} from './ProductDetailDialog';

const Container = styled.div`
  text-align: center;
  overflow: hidden;
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 480px;
  margin: auto;
  padding: 0 20px;
  ${({ theme }) => theme.text.description};
`;
const FilterSelect = styled(MuiSelectField)`
  margin-top: 30px !important;
  max-width: 360px;
  margin: auto;

  .MuiInputBase-root {
    border-radius: 10px;
  }

  @media ${MEDIA_SM} {
    padding: 0 28px !important;
  }
`;
const SliderContainer = styled.div`
  margin-top: 40px;
  position: relative;

  @media ${MEDIA_SM} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 28px;
  }
`;
const StyledSlider = styled(Slider)`
  max-width: 100%;
  margin: auto;

  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }

  .slick-list {
    padding-bottom: 60px;
    overflow: visible;

    .slick-slide.slick-cloned {
      visibility: hidden;
    }

    .slick-slide {
      visibility: visible;
      height: auto;

      > div {
        height: 100%;
      }
    }
  }

  .slick-dots {
    bottom: 0px;
  }

  .slick-dots li button:before {
    font-size: 16px;
  }

  @media ${MEDIA_LG} {
    .slick-list {
      .slick-slide {
        > div {
          height: auto;
        }

        > div + div {
          margin-top: 40px;
        }
      }
    }
  }
`;
const Card = styled.div`
  height: 100%;
  width: 510px !important;
  padding: 52px 44px;
  text-align: left;
  box-shadow: ${({ theme }) => theme.shadows.base};
  border-radius: 16px;
  background: white;
  display: inline-flex !important;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    @media ${MEDIA_SM} {
      margin-bottom: 65px;
    }
  }

  .card-title {
    margin-top: 24px;
    font-family: 'Open Sans';
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  .card-description {
    margin-top: 16px;
    ${({ theme }) => theme.text.description};
    white-space: pre-line;
  }

  .card-features {
    margin-top: 16px;
    ${({ theme }) => theme.text.description};
  }

  .card-feature {
    display: flex;
    align-items: center;
    margin-top: 18px;
  }

  .feature-dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-right: 10px;
  }

  .card-button {
    padding: 12px 18px;
    border-radius: 24px;
    // opacity: 0.3;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    width: fit-content;
  }

  .card-button:disabled {
    opacity: 0.3;
  }

  &.success {
    .card-title {
      color: #69d795;
    }

    .card-button {
      color: #69d795;
      border-color: #69d795;
    }

    .feature-dot {
      background-color: #69d795;
    }
  }

  &.highlight {
    .card-title {
      color: #7ed6d3;
      border-color: #7ed6d3;
    }

    .card-button {
      color: #7ed6d3;
      border-color: #7ed6d3;
    }

    .feature-dot {
      background-color: #7ed6d3;
    }
  }

  &.warn {
    .card-title {
      color: #ffae10;
    }

    .card-button {
      color: #ffae10;
      border-color: #ffae10;
    }

    .feature-dot {
      background-color: #ffae10;
    }
  }

  @media ${MEDIA_LG} {
    min-height: 484px;
  }

  @media ${MEDIA_SM} {
    min-height: 0;
    width: 100% !important;
    margin-bottom: 40px;
    padding: 40px;

    .card-title {
      margin-top: 24px;
      font-size: 20px;
    }
  }
`;
const StyledPrice = styled(Price)``;
const CardFooter = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
`;
const ListButton = styled(Button)`
  min-height: 0;
  height: auto;
  &.bp4-button {
    padding: 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }
`;

const filterOptions = [
  { value: PlanTargetType.ALL, label: 'products.filter.all' },
  { value: PlanTargetType.COMPANY, label: 'products.filter.company' },
  { value: PlanTargetType.INDIVIDUAL, label: 'products.filter.individual' },
];

type Props = {
  className?: string;
  categories?: PlanTargetType[];
  onPlanClick?: (planId: number, type: string) => void;
};
const ProductSection: FC<Props> = ({ className, categories, onPlanClick }) => {
  const { t, i18n } = useTranslation();
  const detailDialog = useRef<PublicProductDetailDialog>(null);
  const plans = useSelector(getAllActiveSubscriptionPlan);
  const activeSubs = useSelector(getActiveSubs);
  const activePlanIds = activeSubs
    .filter((x) => x.remain_credit === null)
    .map((x) => x.subscription_plan.id);
  const isLG = useMedia(MEDIA_LG);
  const isMobile = useMedia(MEDIA_SM);
  const options = useMemo(() => {
    let ops = [...filterOptions];
    if (categories) {
      ops = ops.filter((o) => categories.includes(o.value as any));
    }
    return ops.map((o) => ({
      ...o,
      label: t(o.label),
    }));
  }, [categories]);
  const [filter, setFilter] = useState(options[0].value);
  const filteredPlans = useMemo(() => {
    let ps = plans;
    if (categories) {
      ps = ps.filter((p) => categories.includes(p.attributes.target_type));
    }
    if (filter !== 'all') {
      ps = ps.filter((p) =>
        [PlanTargetType.ALL, filter].includes(p.attributes.target_type),
      );
    }
    return ps;
  }, [plans, categories, filter]);
  const navigate = useNavigate();

  const slides = Math.min(Object.keys(filteredPlans).length, isLG ? 2 : 3);

  const CardList = Object.values(filteredPlans).map((p, idx) => {
    const langData = p.attributes.localizations.data.find(
      (x) => x.attributes.locale === locale2Lang(i18n.language),
    );
    return (
      <Card key={p.id} className={['success', 'highlight', 'warn'][idx % 3]}>
        <div>
          <StyledPrice
            price={p.attributes.price}
            interval={p.attributes.charge_interval}
          />
          <div className="card-title">
            {langData?.attributes?.name || p.attributes.name}
          </div>
          <div className="card-description">
            {langData?.attributes?.description || p.attributes.description}
          </div>
          <div className="card-features">
            {(langData?.attributes?.features || p.attributes.features)
              ?.split('\n')
              .map((f, id) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="card-feature" key={id}>
                  <div className="feature-dot" />
                  <span>{f}</span>
                </div>
              ))}
          </div>
        </div>
        <CardFooter>
          <Button
            className="card-button"
            minimal
            outlined
            large
            disabled={activePlanIds.includes(p.id)}
            onClick={
              onPlanClick
                ? () => onPlanClick(p.id, p.attributes.target_type)
                : () => {
                    navigate('/register', {
                      state: {
                        planId: p.id,
                        accountType:
                          filter === PlanTargetType.ALL
                            ? p.attributes.target_type
                            : filter,
                      },
                    });
                  }
            }
          >
            {activePlanIds.includes(p.id)
              ? t('product.subscribed')
              : t('product.subscribe')}
          </Button>
          <ListButton
            minimal
            intent="primary"
            text={t('products.listAll')}
            type="button"
            onClick={() => {
              detailDialog.current?.open(p.id);
            }}
          />
        </CardFooter>
      </Card>
    );
  });

  return (
    <Container className={className}>
      <Header>{t('sections.product.header')}</Header>
      <Title>{t('sections.product.title')}</Title>
      <Description>{t('sections.product.description')}</Description>
      <FilterSelect
        intent="success"
        name="productFilter"
        value={filter}
        options={options}
        onChange={(e) => setFilter(e.target.value as PlanTargetType)}
      />
      <SliderContainer>
        {!isMobile ? (
          <StyledSlider
            arrows={false}
            infinite={false}
            dots
            rows={isLG ? 2 : 1}
            slidesToShow={slides}
            slidesToScroll={slides}
            centerPadding="22px"
          >
            {CardList}
          </StyledSlider>
        ) : (
          CardList
        )}
      </SliderContainer>
      <ProductDetailDialog ref={detailDialog} />
    </Container>
  );
};

export default ProductSection;
