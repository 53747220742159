import { FC, PropsWithChildren, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, css } from 'styled-components';

import { getTheme } from 'state/app/appReducer';
import { MEDIA_SM } from './media';

const commonTheme = {
  font: {
    xl2: '20px',
    xl: '18px',
    lg: '16px',
    md: '14px',
    sm: '12px',
    light: '300',
    regular: '400',
    semiBold: '600',
    bold: '700',
    extraBold: '800',
  },
  shadows: {
    base: '0px 24px 48px rgb(0 24 52 / 8%)',
  },
  text: {
    headerLabel: css`
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #5dc4b8;

      @media ${MEDIA_SM} {
        font-size: 16px;
      }
    `,
    header: css`
      font-family: 'Super Mario Bros. 2';
      font-weight: 900;
      font-size: 42px;
      line-height: 60px;
      color: ${({ theme }) => theme.primary.label};

      @media ${MEDIA_SM} {
        font-size: 20px;
        line-height: 36px;
      }
    `,
    description: css`
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 32px;
      color: ${({ theme }) => theme.primary.text};
    `,
    cardTitle: css`
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: ${({ theme }) => theme.primary.text};
    `,
    cardDescription: css`
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 26px;
      color: ${({ theme }) => theme.primary.text};
    `,
    footerLinkButton: css`
      font-family: 'Open Sans';
      font-size: ${({ theme }) => theme.font.lg};
      font-weight: ${({ theme }) => theme.font.regular};
      line-height: 32px;
    `,
    headerLinkButton: css`
      font-family: 'Open Sans';
      font-size: ${({ theme }) => theme.font.lg};
      font-weight: ${({ theme }) => theme.font.regular};
      line-height: 20px;
    `,
    sectionTitle: css`
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      color: ${({ theme }) => theme.primary.text};

      @media ${MEDIA_SM} {
        font-size: 22px;
        line-height: 30px;
      }
    `,
    listSectionTitle: css`
      font-family: 'Open Sans';
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: #262626;

      @media ${MEDIA_SM} {
        font-size: 18px;
        line-height: 25px;
      }
    `,
    previewText: css`
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */
      color: #5dc4b8;
    `,
    inputLabel: css`
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      opacity: 0.4;
    `,
    inputValue: css`
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 24px;
    `,
    questionLabel: css`
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 24px;
      color: #5d737e;
    `,
  },
};

export const theme = {
  dark: {
    name: 'dark',
    primary: {
      text: '#6F7E8C',
      textHover: '',
      label: '#FFF',
      background: '#0A1929',
      backgroundHover: 'rgba(255, 255, 255, 0.08)',
      card: 'rgb(18, 18, 18)',
    },
    secondary: {
      text: '',
      textHover: '',
      label: '',
      background: '',
      backgroundHover: '',
    },
  },
  light: {
    name: 'light',
    primary: {
      text: '#333333',
      textHover: '#444',
      label: '#5D737E',
      background: '#FFF',
      backgroundHover: '',
      card: '',
    },
    secondary: {
      text: '#FFF',
      textHover: '',
      label: '',
      background: '#2F3E3C',
      backgroundHover: '',
    },
    error: {
      text: 'red',
      textHover: '',
      background: '',
      backgroundHover: '',
    },
    success: {
      text: '#69d795',
      textHover: '#D5F7D4',
      background: '#ECF7EB',
      backgroundHover: '',
    },
    warning: {
      text: '#FFAE10',
    },
    highlight: {
      text: '#7ED6D3',
    },
  },
};

export const Provider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const themeName = useSelector(getTheme);
  const t = useMemo(
    () => ({
      ...theme[themeName],
      ...commonTheme,
    }),
    [themeName],
  );
  useEffect(() => {
    const lookup = {
      dark: 'bp4-dark',
      light: 'bp4-light',
    };
    document.body.classList.add(lookup[themeName]);
    return () => {
      document.body.classList.remove(lookup[themeName]);
    };
  }, [themeName]);
  return <ThemeProvider theme={t}>{children}</ThemeProvider>;
};
