import { PropsWithChildren, FC } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import PublicHeader from 'containers/PublicHeader';
import PublicFooter from 'containers/PublicFooter';
import ToTopButton from 'components/ToTopButton';

const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.primary.background};
`;

const StyledHeader = styled(PublicHeader)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;
const StyledFooter = styled(PublicFooter)``;
type Props = PropsWithChildren<{
  className?: string;
}>;
const PublicLayout: FC<Props> = ({ className, children }) => {
  return (
    <Container className={className}>
      <StyledHeader />
      {children}
      {ReactDOM.createPortal(
        <ToTopButton />,
        document.getElementById('bubble-box') || document.body,
      )}
      <StyledFooter />
    </Container>
  );
};

export default PublicLayout;
