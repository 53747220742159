import { ChangeEvent, FC, FocusEvent, ReactNode } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { InputGroup, FormGroup } from '@blueprintjs/core';
import { MEDIA_SM } from 'styles/media';

const StyleFormGroup = styled(FormGroup)`
  margin: 0;

  &.bp4-form-group.bp4-inline {
    align-items: center;
  }

  .bp4-form-content {
    flex: 1;
    margin-top: ${({ label }) => (label ? '4px' : 0)};
  }

  &.form-field--readonly .bp4-form-content {
    margin-bottom: ${({ label }) => (label ? '12px' : 0)};
  }

  label.bp4-label {
    ${({ theme }) => theme.text.inputLabel};
    margin-bottom: 0;
  }

  .bp4-input-group {
    background: #f8f8f8;
    border-radius: 8px;
    height: 52px;
    box-shadow: none;
    padding: 14px;
    display: flex;
    align-items: center;

    @media ${MEDIA_SM} {
      height: 38px;
      padding: 8px;
      padding-left: 16px;
    }
  }

  &.solid .bp4-input-group {
    background: rgba(106, 112, 126, 0.0001);
    border: 1px solid #dddfe1;
  }

  &.solid.bp4-intent-danger .bp4-input-group {
    border-color: #ac2f33;
  }

  .bp4-input-group > .bp4-icon {
    position: relative;
    margin: 0;
    z-index: 0;
  }

  input.bp4-input {
    ${({ theme }) => theme.text.inputValue};

    background: transparent;
    box-shadow: none;
    padding: 0;

    &:not(:first-child) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  input.bp4-input::placeholder {
    color: #000000;
    opacity: 0.4;
  }
`;

const Value = styled.div`
  ${({ theme }) => theme.text.previewText};
`;

type Props = {
  className?: string;
  type?: 'text' | 'password' | 'search' | 'number';
  label?: ReactNode;
  name?: string;
  value?: string;
  disabled?: boolean;
  touched?: boolean;
  readonly?: boolean;
  error?: string;
  placeholder?: string;
  inline?: boolean;
  solid?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  prefix?: string | ReactNode;
  autoComplete?: string;
};
const TextField: FC<Props> = ({
  className,
  type = 'text',
  name = '',
  label,
  value,
  disabled,
  touched,
  readonly,
  error,
  placeholder,
  inline,
  solid,
  onChange,
  onBlur,
  prefix,
  autoComplete,
}) => (
  <StyleFormGroup
    className={classnames(className, {
      solid,
      'form-field--readonly': readonly,
    })}
    label={label}
    helperText={disabled ? '' : touched && error}
    intent={touched && error && !disabled ? 'danger' : 'none'}
    inline={inline}
    disabled={disabled}
    labelFor={name}
  >
    {readonly ? (
      <Value>{value}</Value>
    ) : (
      <InputGroup
        id={name}
        fill
        type={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onWheel={(e) => {
          if (type !== 'number') return;
          e.currentTarget.blur();
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (type !== 'number') return;
          if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
            e.preventDefault();
          }
        }}
        leftIcon={prefix as any}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    )}
  </StyleFormGroup>
);

export default TextField;
