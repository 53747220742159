import React, { FC, PropsWithChildren, forwardRef, ReactElement } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div<{ hasError: boolean }>`
  font-size: ${({ theme }) => theme.font.lg};
  margin: 0;

  .form-field-input {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.error.text : 'inherit'};
    font-size: ${({ theme }) => theme.font.lg};
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Label = styled.label<{ hasLabel: boolean }>`
  display: block;
  line-height: 1.1;
  min-height: ${({ hasLabel }) => (hasLabel ? '16px' : 0)};

  ${({ theme }) => theme.text.questionLabel};
`;
const Datum = styled.div`
  margin-top: 4px;
  ${({ theme }) => theme.text.previewText};
`;
const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.error.text};
  font-size: ${({ theme }) => theme.font.sm};
  line-height: 1.1;
  word-break: keep-all;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderReadOnly: FC<any> = (p) => (
  // eslint-disable-next-line react/destructuring-assignment
  <div>{p.value ? String(p.value) : ''}</div>
);
type Props = {
  label?: React.ReactNode;
  labelSuffix?: React.ReactNode;
  name: string;
  touched?: boolean;
  error?: string;
  readonly?: boolean;
  disabled?: boolean;
  empty?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRead?: (p: any) => React.ReactNode;
};
const FormField = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      className,
      label,
      labelSuffix,
      name,
      touched = false,
      error = '',
      readonly = false,
      disabled = false,
      empty = false,
      children,
      renderRead = RenderReadOnly,
    },
    ref,
  ) => {
    const hasError = Boolean(error) && Boolean(touched);
    return (
      <Container
        hasError={hasError}
        className={classnames('bp4-form-group', className, {
          'form-field--readonly': readonly,
        })}
        ref={ref}
      >
        <div className="form-field-content">
          <Header>
            <Label htmlFor={name} hasLabel={Boolean(label)}>
              {label}
            </Label>
            {labelSuffix}
          </Header>
          {React.Children.map(children, (Child) => {
            if (!React.isValidElement(Child)) {
              return null;
            }
            if (empty) {
              return <Datum />;
            }
            if (readonly) {
              return <Datum>{renderRead(Child.props)}</Datum>;
            }
            return React.cloneElement(
              Child as ReactElement<
                {
                  id: string;
                  name: string;
                  disabled?: boolean;
                  className?: string;
                },
                'input'
              >,
              {
                id: name,
                name,
                disabled: Child.props.disabled ?? disabled,
                className: classnames(
                  Child.props.className,
                  'form-field-input',
                ),
              },
            );
          })}
        </div>
        {hasError && <ErrorMessage>{String(error)}</ErrorMessage>}
      </Container>
    );
  },
);

export default FormField;
