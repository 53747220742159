import {
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  FormEvent,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { RootState } from 'state/rootReducer';
import { fetchDocuments, createSubmission } from 'api';
import { AppToaster, Button, SelectField, TextField } from 'components';
import { locale2Lang } from 'utils/i18n';
import { useRefreshDocumentCate } from 'hooks';
import { DocumentSimple } from '@type';
import { MEDIA_SM, MEDIA_MD } from 'styles/media';
import Tracker from 'utils/tracker';

const Container = styled.form`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
  padding-bottom: 40px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px 44px 33px 44px;
  gap: 8px;

  @media ${MEDIA_SM} {
    padding: 22px 12px 12px 20px;
  }
`;
const Title = styled.div`
  ${({ theme }) => theme.text.listSectionTitle};
`;
const SearchField = styled(TextField)`
  flex: 1;
  min-width: 232px;
  max-width: 50%;

  .bp4-input-group {
    padding-right: 0;
  }

  @media ${MEDIA_SM} {
    min-width: 200px;
  }
`;

const TRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 27px 44px;
  align-items: baseline;

  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  color: #262626;

  @media ${MEDIA_SM} {
    font-size: 11px;
    line-height: 15px;
    padding: 12px 12px 12px 20px;
  }

  .bp4-button {
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    @media ${MEDIA_SM} {
      font-size: 11px;
      line-height: 15px;
    }
  }
`;
const Table = styled.div`
  position: relative;

  ${TRow} + ${TRow} {
    border-top: 1px solid #d9d9d9;
  }
`;
const THeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 30px 44px;

  background: #f0f2fb;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #262626;

  @media ${MEDIA_SM} {
    font-size: 11px;
    line-height: 15px;
    padding: 12px 12px 12px 20px;
  }
`;
const StyledSelectField = styled(SelectField)`
  .select-field__indicator-separator {
    background-color: ${({ theme }) => theme.success.text};
  }

  .select-field__indicator {
    color: ${({ theme }) => theme.success.text};
  }
`;
const SearchRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 44px;
  padding-bottom: 24px;
  gap: 14px;

  ${StyledSelectField} {
    flex: 1;
  }

  @media ${MEDIA_MD} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
    padding-left: 20px;
    padding-right: 12px;
  }

  .search-button-container {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    @media ${MEDIA_MD} {
      grid-column: 1/3;
    }
    @media ${MEDIA_SM} {
      grid-column: 1;
    }
  }
`;
const SearchButton = styled(Button)`
  width: 112px;

  @media ${MEDIA_MD} {
    margin-left: auto;
  }

  @media ${MEDIA_SM} {
    height: auto !important;
    font-size: 11px !important;
    line-height: 15px !important;
  }
`;
const PaginationButton = styled(Button)``;
const Pagination = styled.div`
  padding: 0 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  className?: string;
};
const DocumentListPage: FC<Props> = ({ className }) => {
  const isMobile = useMedia(MEDIA_SM);
  const subCateLookup = useSelector(
    (s: RootState) => s.documents.subCateLookup,
  );
  const [cate, setCate] = useState(0);
  const [subCate, setSubCate] = useState(0);
  const [search, setSearch] = useState('');
  const [docs, setDocs] = useState<DocumentSimple[]>([]);
  const [pagination, setPagination] = useState({ page: 1, pageCount: 0 });
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    categories,
    loading: cateLoading,
    refreshDocumentCate,
  } = useRefreshDocumentCate();
  const subCategories = useMemo(
    () => [
      ...(categories.find((c) => c.id === cate)?.document_sub_categories || []),
    ],
    [categories, cate],
  );

  const searchDocument = useCallback(
    async ({
      category,
      subCategory,
      page,
      title,
    }: {
      category?: number;
      subCategory?: number;
      title?: string;
      page: number;
    }) => {
      try {
        setLoading(true);
        const d = await fetchDocuments({
          categories: category ? [category] : [],
          subCategories: subCategory ? [subCategory] : [],
          title,
          page,
          pageSize: 10,
        });
        setDocs(d.data);
        setPagination(d.meta.pagination);
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    },
    [],
  );
  const onSearch = (e: FormEvent) => {
    e.preventDefault();
    searchDocument({
      category: cate,
      subCategory: subCate,
      title: search,
      page: 1,
    });

    const c = categories.find((x) => x.id === cate);
    const sc = (c?.document_sub_categories || []).find((x) => x.id === subCate);
    Tracker.track('Search Document', {
      category: c?.name || '',
      subcategory: sc?.name || '',
    });
  };

  useEffect(() => {
    refreshDocumentCate();
    searchDocument({
      page: 1,
    });
  }, []);

  const Search = (
    <SearchField
      prefix="search"
      type="search"
      placeholder={t('sections.documentSubmissionList.search')}
      value={search}
      onChange={(e) => {
        const label = e.currentTarget.value;
        setSearch(label);
      }}
    />
  );

  return (
    <Container className={className} onSubmit={onSearch}>
      <Header>
        <Title>{t('sections.documentList.title')}</Title>
        {!isMobile && Search}
      </Header>
      <SearchRow>
        <StyledSelectField
          placeholder={t('sections.documentList.search.category')}
          disabled={cateLoading}
          options={useMemo(
            () =>
              categories
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => {
                  const langData = c.localizations.find(
                    (d) => d.locale === locale2Lang(i18n.language),
                  );
                  return {
                    value: String(c.id),
                    label: langData?.name || c.name,
                  };
                }),
            [categories],
          )}
          value={cate || null}
          onChange={(_, v) => {
            setCate(Number(v));
            setSubCate(0);
          }}
        />
        <StyledSelectField
          placeholder={t('sections.documentList.search.subCategory')}
          disabled={cateLoading || !cate}
          options={useMemo(
            () =>
              subCategories
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => {
                  const langData = c.localizations.find(
                    (d) => d.locale === locale2Lang(i18n.language),
                  );
                  return {
                    value: String(c.id),
                    label: langData?.name || c.name,
                  };
                }),
            [subCategories],
          )}
          value={subCate || null}
          onChange={(_, v) => setSubCate(Number(v))}
        />
        <div className="search-button-container">
          {isMobile && Search}
          <SearchButton
            type="submit"
            text={t('sections.documentList.search.submit')}
            intent="success"
            round
            loading={loading}
          />
        </div>
      </SearchRow>
      <Table>
        <THeader>
          <div>{t('sections.documentList.name')}</div>
          <div>{t('sections.documentList.description')}</div>
        </THeader>
        {docs.map((d) => {
          const langData = d.attributes.localizations.data.find(
            (l) => l.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <TRow key={d.id}>
              <div>
                <Button
                  minimal
                  text={langData?.attributes?.title || d.attributes.title}
                  onClick={async () => {
                    try {
                      const res = await createSubmission({
                        label:
                          langData?.attributes?.title || d.attributes.title,
                        formdata: { step: [], data: {}, stepLookup: {} },
                        document: d.id,
                      });
                      navigate(`/user/documents/submissions/${res.data.id}`);
                      const sc =
                        subCateLookup[
                          d.attributes.document_sub_category.data.id
                        ];
                      Tracker.track('Created New Document', {
                        documentName: d.attributes.title,
                        category: sc?.category?.name || '',
                        subcategory: sc?.name || '',
                      });
                    } catch (e: any) {
                      AppToaster.apiError(e);
                    }
                  }}
                />
              </div>
              <div>
                {langData?.attributes?.description || d.attributes.description}
              </div>
            </TRow>
          );
        })}
      </Table>
      <Pagination>
        <PaginationButton
          text={t('actions.previousPage')}
          prefix="arrow-left"
          disabled={pagination.page <= 1}
          loading={loading}
          minimal
          intent="success"
          onClick={() =>
            searchDocument({
              category: cate,
              subCategory: subCate,
              title: search,
              page: pagination.page - 1,
            })
          }
        />
        <PaginationButton
          text={t('actions.nextPage')}
          suffix="arrow-right"
          disabled={pagination.pageCount <= pagination.page}
          loading={loading}
          minimal
          intent="success"
          onClick={() =>
            searchDocument({
              category: cate,
              subCategory: subCate,
              title: search,
              page: pagination.page + 1,
            })
          }
        />
      </Pagination>
    </Container>
  );
};

export default DocumentListPage;
