import { useLayoutEffect, useState, FC } from 'react';
import { Router, BrowserRouterProps } from 'react-router-dom';
import { createBrowserHistory } from '@remix-run/router';

const history = createBrowserHistory({ window, v5Compat: true });

// unstable_HistoryRouter
// copy from https://github.com/remix-run/react-router/blob/main/packages/react-router-dom/index.tsx
export const WrappedBrowserRouter: FC<BrowserRouterProps> = ({
  basename,
  children,
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  // const wrappedHistory = useMemo(
  //   () => ({
  //     ...history,
  //     push(to: To, s?: any) {
  //       if (BeforeNavigatePubSub.listeners.length) {
  //         BeforeNavigatePubSub.listeners.forEach((cb) => {
  //           try {
  //             cb(to, s);
  //           } catch (e) {
  //             console.error(e);
  //           }
  //         });
  //         return undefined;
  //       }
  //       return history.push(to, s);
  //     },
  //     replace(to: To, s?: any) {
  //       BeforeNavigatePubSub.listeners.forEach((cb) => {
  //         try {
  //           cb(to, s);
  //         } catch (e) {
  //           console.error(e);
  //         }
  //       });
  //       return history.replace(to, s);
  //     },
  //   }),
  //   [history],
  // );

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};

export default history;
