import { FC, FocusEvent } from 'react';
import styled from 'styled-components';
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import classnames from 'classnames';

const StyledFormControl = styled(FormControl)`
  &.success {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.success.text};
    }
    .MuiInputBase-root {
      color: ${({ theme }) => theme.success.text};
    }
  }

  &.success label {
    color: ${({ theme }) => theme.success.text};
  }

  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  &.success fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;

type Props = {
  className?: string;
  name?: string;
  value?: string;
  error?: string;
  touched?: boolean;
  label?: React.ReactNode;
  options?: { label: string; value: string }[];
  onChange?: (e: SelectChangeEvent<string>) => void;
  onBlur?: (e: FocusEvent) => void;
  intent?: 'none' | 'success';
};
const MuiSelectField: FC<Props> = ({
  className,
  name,
  value,
  error,
  touched,
  label,
  options = [],
  onChange,
  onBlur,
  intent = 'none',
}) => (
  <StyledFormControl
    fullWidth
    className={classnames(className, intent)}
    error={touched && Boolean(error)}
  >
    <InputLabel>{label}</InputLabel>
    <Select
      name={name}
      value={value}
      label={label}
      fullWidth
      onChange={onChange}
      onBlur={onBlur}
    >
      {options.map((o) => (
        <MenuItem value={o.value} key={o.value}>
          <span>{o.label}</span>
        </MenuItem>
      ))}
    </Select>
    {touched && <FormHelperText>{error}</FormHelperText>}
  </StyledFormControl>
);

export default MuiSelectField;
