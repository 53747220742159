import { FC } from 'react';
import styled from 'styled-components';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import classnames from 'classnames';
import { useMedia } from 'react-use';

import { MEDIA_MD } from 'styles/media';

const Container = styled.div`
  width: 100%;
`;

const center = {
  lat: 22.2628831,
  lng: 114.125,
};
const position = {
  lat: 22.262705579122876,
  lng: 114.13019132993384,
};
const mapStyle = {
  width: '100%',
  height: '100%',
};
const options = {
  mapTypeControl: false,
  streetViewControl: false,
};
type Props = {
  className?: string;
};
const Map: FC<Props> = ({ className }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.MAP_API_TOKEN || '',
  });
  const isTablet = useMedia(MEDIA_MD);

  if (!isLoaded)
    return <Container className={classnames('bp4-skeleton', className)} />;

  return (
    <Container className={className}>
      <GoogleMap
        center={isTablet ? position : center}
        zoom={17}
        mapContainerStyle={mapStyle}
        options={options}
      >
        <Marker position={position} />
      </GoogleMap>
    </Container>
  );
};

export default Map;
