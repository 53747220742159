type Callback = (fn: () => void) => void;
class BeforeNavigatePubSub {
  listeners: Callback[] = [];

  subscribe = (cb: Callback) => {
    this.listeners.push(cb);
    return () => {
      const idx = this.listeners.indexOf(cb);
      if (idx > -1) {
        this.listeners.splice(idx, 1);
      }
    };
  };

  warp(fn: () => void) {
    if (this.listeners.length) {
      this.listeners.forEach((cb) => cb(fn));
      return;
    }
    fn();
  }
}

export default new BeforeNavigatePubSub();
