import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import SEOBlock from './containers/SEOBlock';
import 'normalize.css/normalize.css';
import './index.css';
import './ckeditor.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-phone-number-input/style.css';

import Tracker from './utils/tracker';

Tracker.init();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <SEOBlock />
    <App />
  </React.StrictMode>,
);
