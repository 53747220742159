import { FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { updateMe } from 'api';
import { getUser } from 'state/app';
import {
  ImageInput,
  TextField,
  PhoneField,
  SelectField,
  AppToaster,
  Button,
  Checkbox,
} from 'components';
import { AccountType } from '@type';
import { industries, companySizes } from 'constants/kyc';
import { useRefreshMe } from 'hooks';
import { Pencil } from 'components/icons';
import { MEDIA_SM, MEDIA_MD, MEDIA_LG } from 'styles/media';

const Form = styled.form`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 100px 24px 24px 24px;
  padding: 48px 64px 48px 120px;

  @media ${MEDIA_LG} {
    padding: 28px 24px 40px 34px;
    border-radius: 60px 24px 24px 24px;
  }

  .bp4-form-group.bp4-disabled label.bp4-label {
    color: rgb(0, 0, 0) !important;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  ${({ theme }) => theme.text.sectionTitle};
`;
const Content = styled.div`
  margin-top: 24px;

  @media ${MEDIA_SM} {
    margin-top: 12px;
  }
`;
const StyledImageInput = styled(ImageInput)`
  width: 100px;
  height: 100px;
  border-radius: 100%;

  @media ${MEDIA_SM} {
    width: 80px;
    height: 80px;
  }
`;
const InputBlock = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 16px;

  @media ${MEDIA_MD} {
    margin-top: 12px;
    row-gap: 12px;
    grid-template-columns: 1fr;
  }
`;
const AddressField = styled(TextField)`
  grid-column: 1 / 3;

  @media ${MEDIA_MD} {
    grid-column: 1;
  }
`;
const ShowCheckbox = styled(Checkbox)`
  padding-top: 8px;
`;
const ActionBar = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 28px;

  @media ${MEDIA_SM} {
    margin-top: 26px;
    justify-content: center;
  }
`;
const BottomButton = styled(Button)`
  width: 200px;

  @media ${MEDIA_MD} {
    width: 100%;
    max-width: 200px;
    flex: 1;
  }

  @media ${MEDIA_SM} {
    font-size: 14px !important;
    line-height: 19px !important;
  }
`;
const EditButton = styled(Button)`
  max-width: 186px;
  flex: 1;

  &.bp4-button {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;

    @media ${MEDIA_SM} {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
`;

type Props = {
  className?: string;
};
// eslint-disable-next-line complexity
const AccountSection: FC<Props> = ({ className }) => {
  const isMobile = useMedia(MEDIA_SM);
  const user = useSelector(getUser);
  const isCompany = user?.account_type === AccountType.COMPANY;
  const { t, i18n } = useTranslation();
  const initialValues = useMemo(
    // eslint-disable-next-line complexity
    () => ({
      profilePic: user?.profile_pic?.url || null,
      email: user?.email || '',
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      contactPhone: user?.meta?.contactPhone || '',
      mobilePhone: user?.meta?.mobilePhone || '',
      jobTitle: user?.meta?.jobTitle || '',
      companyAddress: user?.meta?.companyAddress || '',
      industry: user?.meta?.industry || '',
      companySize: user?.meta?.companySize || '',
      companyName: user?.meta?.companyName || '',
      showProfile: user?.document_settings?.showProfile || false,
    }),
    [user],
  );
  const schema = useMemo(
    () =>
      yup.object().shape({
        profilePic: yup.mixed(),
        email: yup.string().required(t('validate.required', { ns: 'errors' })),
        firstName: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        lastName: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        contactPhone: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        mobilePhone: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
      }),
    [i18n.language],
  );
  const companySchema = useMemo(
    () =>
      schema.shape({
        jobTitle: yup.string(),
        companyAddress: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        industry: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        companySize: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        companyName: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        showProfile: yup.boolean(),
      }),
    [schema],
  );
  const refreshMe = useRefreshMe();

  const [edit, setEdit] = useState(false);
  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: isCompany ? companySchema : schema,
    onSubmit: async (data) => {
      try {
        await updateMe({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          profilePic: data.profilePic,
          meta: {
            contactPhone: data.contactPhone,
            mobilePhone: data.mobilePhone,
            ...(isCompany && {
              companyAddress: data.companyAddress,
              industry: data.industry,
              companySize: data.companySize,
              jobTitle: data.jobTitle,
              companyName: data.companyName,
            }),
          },
          documentSettings: {
            showProfile: data.showProfile,
          },
        });
        refreshMe();
        setEdit(false);
      } catch (e: any) {
        AppToaster.apiError(e);
      }
    },
  });

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <Header>
        <Title>{t('forms.accountSection.title')}</Title>
        {!edit && !isMobile && (
          <EditButton
            type="button"
            text={t('forms.accountSection.edit')}
            prefix={<Pencil />}
            round
            onClick={() => setEdit(true)}
          />
        )}
      </Header>
      <Content>
        <StyledImageInput
          name="profilePic"
          onChange={setFieldValue}
          value={values.profilePic}
          disabled={!edit}
        />
        <InputBlock>
          <TextField
            label={t('forms.accountSection.username')}
            name="username"
            value={
              user?.auth_profiles.find((p) => p.provider === 'local')
                ?.identifier
            }
            disabled
          />
          <TextField
            label={t('forms.accountSection.email')}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.email}
            error={errors.email}
            disabled={!edit}
            autoComplete="email"
          />
          <TextField
            label={t('forms.accountSection.firstName')}
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.firstName}
            error={errors.firstName}
            disabled={!edit}
            autoComplete="given-name"
          />
          <TextField
            label={t('forms.accountSection.lastName')}
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.lastName}
            error={errors.lastName}
            disabled={!edit}
            autoComplete="family-name"
          />
          <PhoneField
            label={t('forms.accountSection.contactPhone')}
            name="contactPhone"
            value={values.contactPhone}
            onChange={setFieldValue}
            onBlur={handleBlur}
            touched={touched.contactPhone}
            error={errors.contactPhone}
            disabled={!edit}
          />
          <PhoneField
            label={t('forms.accountSection.mobilePhone')}
            name="mobilePhone"
            value={values.mobilePhone}
            onChange={setFieldValue}
            onBlur={handleBlur}
            touched={touched.mobilePhone}
            error={errors.mobilePhone}
            disabled={!edit}
          />
          {isCompany && (
            <TextField
              label={t('forms.accountSection.companyName')}
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.companyName}
              error={errors.companyName}
              disabled={!edit}
              autoComplete="organization"
            />
          )}
          {isCompany && (
            <SelectField
              name="industry"
              label={t('forms.accountSection.industry')}
              value={values.industry}
              touched={touched.industry}
              error={errors.industry}
              disabled={!edit}
              onChange={setFieldValue}
              onBlur={handleBlur}
              options={industries.map((ind) => ({
                value: ind,
                label: t(
                  `constants.industry.${ind.replace(/\s/g, '').toLowerCase()}`,
                ),
              }))}
            />
          )}
          {isCompany && (
            <SelectField
              name="companySize"
              label={t('forms.accountSection.companySize')}
              value={values.companySize}
              touched={touched.companySize}
              error={errors.companySize}
              disabled={!edit}
              onChange={setFieldValue}
              onBlur={handleBlur}
              options={companySizes.map((c) => ({
                value: c,
                label: c,
              }))}
            />
          )}
          {isCompany && (
            <TextField
              label={t('forms.accountSection.jobTitle')}
              name="jobTitle"
              value={values.jobTitle}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.jobTitle}
              error={errors.jobTitle}
              disabled={!edit}
              autoComplete="organization-title"
            />
          )}
          {isCompany && (
            <AddressField
              label={t('forms.accountSection.companyAddress')}
              name="companyAddress"
              value={values.companyAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.companyAddress}
              error={errors.companyAddress}
              disabled={!edit}
              autoComplete="street-address"
            />
          )}
          {isCompany && (
            <ShowCheckbox
              label={t('forms.accountSection.showProfile')}
              name="showProfile"
              checked={values.showProfile}
              onChange={handleChange}
              disabled={!edit}
            />
          )}
        </InputBlock>
        {isMobile && !edit && (
          <ActionBar>
            <EditButton
              type="button"
              text={t('forms.accountSection.edit')}
              prefix={<Pencil />}
              round
              onClick={() => setEdit(true)}
            />
          </ActionBar>
        )}
        {edit && (
          <ActionBar>
            <BottomButton
              type="submit"
              text={t('forms.accountSection.submit')}
              round
              intent="success"
            />
            <BottomButton
              type="button"
              text={t('forms.accountSection.cancel')}
              round
              intent="success"
              outlined
              onClick={() => setEdit(false)}
            />
          </ActionBar>
        )}
      </Content>
    </Form>
  );
};

export default AccountSection;
