import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Button } from 'components';
import { QuestionRender } from 'components/questions';
import { deepFetchQuestion } from 'state/questions';
import { RootState } from 'state/rootReducer';
import { guessInitialValue, guessSchema } from 'utils/question';
import { MEDIA_SM } from 'styles/media';
import { ConditionRef, ConditionConst } from '@type';
import { resolvePath } from 'utils/flow';

const Container = styled.form`
  position: relative;
`;
const ActionBar = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
const ActionButton = styled(Button)`
  width: 130px;

  @media ${MEDIA_SM} {
    width: 120px;
    height: auto !important;
    font-size: 14px !important;
    line-height: 15px !important;
    padding: 10px 0;
  }
`;

let previous = '';
type Props = {
  className?: string;
  questionId: number | string;
  rules?: {
    type: string; // sum,
    condition: {
      op: string;
      left: ConditionRef & { value?: any };
      right: (ConditionRef & { value?: any }) | ConditionConst;
    };
    fields: string[];
  }[];
  initValues?: any;
  overrideName?: string;
  isStart?: boolean;
  isEnd?: boolean;
  loading?: boolean;
  onPreviousClick?: (formdata?: any) => void;
  onSubmit: (v: any) => void;
};
const QuestionForm: FC<Props> = ({
  className,
  questionId,
  rules = [],
  initValues,
  overrideName = '',
  onPreviousClick,
  onSubmit,
  isStart,
  isEnd,
  loading,
}) => {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(20); // hardCode
  const { t } = useTranslation();
  const lookup = useSelector((state: RootState) => state.questions.lookup);
  const currentQues = lookup[questionId as number];

  const guessed = useMemo(
    () => guessInitialValue(Number(questionId), lookup),
    [questionId, lookup],
  );
  const initialValues = useMemo(
    () => ({
      ...guessed,
      ...Object.entries(initValues).reduce((memo, [k, v]) => {
        const ln = {
          [overrideName]: currentQues?.attributes?.name || '',
          [`${overrideName}$Nested`]: `${
            currentQues?.attributes?.name || ''
          }$Nested`,
        };
        // eslint-disable-next-line no-param-reassign
        memo[ln[k] || k] = v;
        if (k === '%locale%') {
          // eslint-disable-next-line no-param-reassign
          memo['%locale%'] = Object.entries(v as any).reduce((m, [sk, sv]) => {
            // eslint-disable-next-line no-param-reassign
            m[ln[sk] || sk] = sv as any;
            return m;
          }, {} as Record<string, any>);
        }
        return memo;
      }, {} as Record<string, any>),
    }),
    [guessed, initValues],
  );
  const validationSchema = useMemo(() => {
    const guessedShema = guessSchema(Number(questionId), lookup);
    let schema = yup.object().shape(guessedShema);
    rules.forEach((r) => {
      if (r.type === 'sum') {
        const getSum = (value: any, fields: string[]) => {
          const values: number[] = fields.flatMap((f) => {
            const tokens = f.split('[0].');
            const v = get(value, tokens[0]);
            if (tokens.length === 1) return [v];
            const last = tokens.pop() as string;
            return (v || []).map((iv: any) => get(iv, last));
          });
          const sum = values.reduce((m, v) => m + (v || 0), 0);
          return sum;
        };

        const matchCond = (value: any, scope = ''): boolean => {
          const { left, right, op } = r.condition;
          if (op) {
            const leftPath = resolvePath(left).substring(scope.length);
            const leftValue = left.value || get(value, leftPath);
            let rightValue = right.value;
            if (!rightValue && right.type === 'ref') {
              const rightPath = resolvePath(right).substring(scope.length);
              rightValue = get(value, rightPath);
            }
            const opLook: Record<string, boolean> = {
              eq: String(leftValue).trim() === String(rightValue).trim(),
              neq: String(leftValue).trim() !== String(rightValue).trim(),
              includes: leftValue?.includes?.(rightValue),
              nincludes: !leftValue?.includes?.(rightValue),
            };
            return opLook[op];
          }
          return true;
        };

        r.fields.forEach((f) => {
          // const path = f.replace('[0]', '[-1]');
          const tokens = f.split('[0].');
          if (tokens.length > 2) {
            // assume at most two layer array
            const base = tokens[0];
            [...new Array(check)].forEach((_, idx) => {
              schema = schema.test(
                'sum-up-100',
                'sum up should be 100',
                (value, testContext) => {
                  const subV = get(value, base)?.[idx] || [];
                  const scope = `${base}[${idx}].`;
                  if (idx >= subV.length) return true;
                  if (!matchCond(subV, scope)) return true;

                  const sum = getSum(subV, [f.substring(scope.length)]);
                  if (sum !== 100) {
                    return testContext.createError({
                      path: `${base}[${idx}].${f
                        .substring(scope.length)
                        .replace('[0]', '[-1]')}`,
                      message: t('validate.sum100', { ns: 'errors' }),
                    });
                  }
                  return true;
                },
              );
            });
          } else {
            schema = schema.test(
              'sum-up-100',
              'sum up should be 100',
              (value, testContext) => {
                if (!matchCond(value)) return true;
                const sum = getSum(value, r.fields);
                if (sum !== 100) {
                  return testContext.createError({
                    path: f.replace('[0]', '[-1]'),
                    message: t('validate.sum100', { ns: 'errors' }),
                  });
                }
                return true;
              },
            );
          }
        });
      }
    });
    return schema;
  }, [questionId, lookup, rules, check]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (formdata) => {
      const ln = {
        [currentQues?.attributes?.name]: overrideName,
        [`${currentQues?.attributes?.name}$Nested`]: `${overrideName}$Nested`,
      };
      const data = Object.entries(formdata).reduce(
        (memo, [k, v]) => ({
          ...memo,
          [ln[k] || k]: v,
        }),
        {} as Record<string, any>,
      );
      if (data['%locale%']) {
        // eslint-disable-next-line no-param-reassign
        data['%locale%'] = Object.entries(formdata['%locale%']).reduce(
          (memo, [k, v]) => ({
            ...memo,
            [ln[k] || k]: v,
          }),
          {},
        );
      }
      onSubmit(data);
    },
    enableReinitialize: true,
  });
  const handleClear = useCallback(
    (name: string) => {
      const gpath = name.replace(/\[\d+\]/g, '[0]');
      formik.setFieldValue(name, get(guessed, gpath));
    },
    [guessed, formik.setFieldValue],
  );
  useEffect(() => {
    if (previous === questionId) return;
    previous = String(questionId);
    if (questionId) {
      dispatch(deepFetchQuestion({ id: Number(questionId), ttl: 8 }));
    }
  }, [questionId]);
  useEffect(() => {
    const sumRules = rules.filter((r) => r.type === 'sum');
    const more = sumRules.some((r) => {
      const hasMore = r.fields.some((f) => {
        const tokens = f.split('[0].');
        return (
          tokens.length > 2 && (get(formik.values, tokens[0])?.length || 0) > 20
        );
      });
      return hasMore;
    });
    setCheck(more ? 60 : 20);
  }, [formik.values, rules]);

  return (
    <Container className={className} onSubmit={formik.handleSubmit}>
      <QuestionRender
        questionId={Number(questionId)}
        name=""
        value={formik.values}
        touched={formik.touched}
        error={formik.errors}
        onChange={formik.setFieldValue}
        onBlur={useCallback((n: string) => formik.setFieldTouched(n, true), [])}
        onClear={handleClear}
      />
      <ActionBar>
        {!isStart && (
          <ActionButton
            loading={loading}
            outlined
            intent="success"
            text={t('actions.previousStep')}
            prefix="chevron-left"
            onClick={() => onPreviousClick?.(formik.values)}
          />
        )}
        <ActionButton
          loading={loading}
          type="submit"
          intent="success"
          text={isEnd ? t('actions.preview') : t('actions.nextStep')}
          suffix={isEnd ? undefined : 'chevron-right'}
        />
      </ActionBar>
    </Container>
  );
};

export default QuestionForm;
