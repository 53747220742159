import { DocumentSubmission } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const updateSubmission = async (
  id: number | string,
  {
    formdata,
    label,
    status,
    skipReminder,
  }: {
    formdata: any;
    label?: string;
    status?: string;
    skipReminder?: boolean;
  },
): Promise<{ data: DocumentSubmission }> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher
    .withAuth()
    .json()
    .put(`/api/document-submissions/${id}`, {
      data: {
        formdata,
        label,
        status,
        skip_reminder: skipReminder,
      },
    });
  const data = await res.json();
  return data;
};

export default updateSubmission;
