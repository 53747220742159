import { FC } from 'react';

type Props = {
  size?: number | string;
  color?: string;
};
const Card: FC<Props> = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.07 5.25H3.93C3.28552 5.25 2.66744 5.50602 2.21173 5.96173C1.75602 6.41744 1.5 7.03552 1.5 7.68V17.07C1.5 17.7145 1.75602 18.3326 2.21173 18.7883C2.66744 19.244 3.28552 19.5 3.93 19.5H20.07C20.7145 19.5 21.3326 19.244 21.7883 18.7883C22.244 18.3326 22.5 17.7145 22.5 17.07V7.68C22.5 7.03552 22.244 6.41744 21.7883 5.96173C21.3326 5.50602 20.7145 5.25 20.07 5.25ZM3.93 6.75H20.07C20.316 6.75196 20.5515 6.85057 20.7254 7.02456C20.8994 7.19854 20.998 7.43395 21 7.68V9H3V7.68C3.00196 7.43395 3.10057 7.19854 3.27456 7.02456C3.44854 6.85057 3.68395 6.75196 3.93 6.75ZM20.07 18H3.93C3.68395 17.998 3.44854 17.8994 3.27456 17.7254C3.10057 17.5515 3.00196 17.316 3 17.07V10.5H21V17.07C20.998 17.316 20.8994 17.5515 20.7254 17.7254C20.5515 17.8994 20.316 17.998 20.07 18Z"
      fill={color}
    />
    <path
      d="M18 15H15.75C15.5511 15 15.3603 15.079 15.2197 15.2197C15.079 15.3603 15 15.5511 15 15.75C15 15.9489 15.079 16.1397 15.2197 16.2803C15.3603 16.421 15.5511 16.5 15.75 16.5H18C18.1989 16.5 18.3897 16.421 18.5303 16.2803C18.671 16.1397 18.75 15.9489 18.75 15.75C18.75 15.5511 18.671 15.3603 18.5303 15.2197C18.3897 15.079 18.1989 15 18 15Z"
      fill={color}
    />
  </svg>
);

export default Card;
