import APIFetcher from 'utils/APIFetcher';
import APIError from 'utils/APIError';

import {
  getAuthToken,
  getRefreshToken,
  setAuthToken,
  logout,
  setRenewToken,
} from 'state/app';
import renewToken from './publicUsers/renewToken';

const { STRAPI_READ_TOKEN, API_URL } = process.env;

let renewPromise: ReturnType<typeof renewToken> | undefined;
const oneRenewToken = async (token: string) => {
  if (renewPromise) return renewPromise;
  renewPromise = renewToken(token);
  renewPromise.then(() => {
    renewPromise = undefined;
  });
  return renewPromise;
};

export default class InstaPicFetcher extends APIFetcher {
  baseURL = API_URL || '';

  withAuth = (): this =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new this.constructor({
      ...this,
      headers: {
        ...this.headers,
        Authorization: `Bearer ${getAuthToken(this.store?.getState())}`,
      },
    });

  withPublicToken = (): this =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new this.constructor({
      ...this,
      headers: {
        ...this.headers,
        Authorization: `Bearer ${STRAPI_READ_TOKEN}`,
      },
    });

  async request(
    method: string,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: { [key: string]: any } = {},
  ): Promise<Response> {
    // eslint-disable-next-line no-restricted-syntax
    for await (const retry of [0, 1]) {
      try {
        const result = await super.request(method, path, data);
        return result;
      } catch (e) {
        if (e instanceof APIError) {
          if (e.response.status === 401 && retry !== 1) {
            try {
              const { accessToken, refreshToken } = await oneRenewToken(
                getRefreshToken(this.store?.getState()),
              );
              this.store?.dispatch(setAuthToken(accessToken));
              this.store?.dispatch(setRenewToken({ token: refreshToken }));
              this.headers = {
                ...this.headers,
                Authorization: `Bearer ${accessToken}`,
              };
              // eslint-disable-next-line no-continue
              continue;
            } catch (err) {
              console.error(e);
              console.error(err);
              this.store?.dispatch(logout());
              throw new Error('Token expired. Please Login again.');
            }
          }
          if (e.response.status === 401) {
            this.store?.dispatch(logout());
            throw new Error('Token expired. Please Login again.');
          }
          console.error(e);
          let message = await e.response.clone().text();
          try {
            const error = await e.response.clone().json();
            message = error?.error?.message || message;
            // eslint-disable-next-line no-empty
          } catch {}
          throw new APIError(message, e.response);
        }
        throw e;
      }
    }
    throw new Error('never reach');
  }
}
