import { DocumentCategory } from '@type';

import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchDocumentCategoriesResponse = DocumentCategory[];

const fetchDocumentCategories =
  async (): Promise<FetchDocumentCategoriesResponse> => {
    const fetcher = FetcherFactory.make(StrapiPublicFetcher);

    const res = await fetcher.withAuth().get('/api/document-categories', {
      'populate[localizations][populate]': '*',
    });
    const data = await res.json();
    return data;
  };

export default fetchDocumentCategories;
