import FetcherFactory from 'utils/FetcherFactory';
import { UserSubscription } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchUserSubscriptionResponse = {
  userSubscription: UserSubscription;
  stripeSubscriptionId?: string;
  stripePaymentIntentId?: string;
  isSetup?: boolean;
  clientSecret: string;
  invoiceURL?: string;
};
const fetchUserSubscription = async (
  subId: number | string,
): Promise<FetchUserSubscriptionResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher
    .withAuth()
    .get(`/api/public-user-subscriptions/${subId}`);
  const data = await res.json();
  return data;
};

export default fetchUserSubscription;
