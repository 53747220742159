import APIError from 'utils/APIError';

type RenewTokenResponse = {
  accessToken: string;
  refreshToken: string;
};

const { API_URL } = process.env;

const renewToken = async (token: string): Promise<RenewTokenResponse> => {
  const res = await fetch(`${API_URL}/api/public-user/renewToken`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
  });
  if (res.status !== 200) {
    const message = await res.text();
    throw new APIError(message, res);
  }
  const data = await res.json();
  return data as RenewTokenResponse;
};

export default renewToken;
