import { MeMisc } from '@type/publicUser';
import FetcherFactory from 'utils/FetcherFactory';

import StrapiPublicFetcher from '../StrapiPublicFetcher';

type UpdateMeMiscRequest = {
  autocomplete: any;
};
const updateMeMisc = async (param: UpdateMeMiscRequest): Promise<MeMisc> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher
    .json()
    .withAuth()
    .put(`/api/public-user/me/misc`, param);
  const data = await res.json();
  return data;
};

export default updateMeMisc;
