import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import { Tooltip2, Tooltip2Props } from '@blueprintjs/popover2';

const Wrapper = styled.span`
  label.bp4-label & .bp4-popover2-target {
    display: inline-block;
    display: inline-block;
    margin-top: 0;
  }
`;

const Tooltip: FC<Tooltip2Props> = ({ className, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip2 {...rest} popoverClassName={className} />
);
const StyledTooltip2 = styled(Tooltip)`
  .bp4-popover2-content {
    background: white;
    ${({ theme }) => theme.text.inputLabel}
    color: ${({ theme }) => theme.primary.label};
    opacity: 1;
    padding: 8px;
  }
`;
const Content = styled.div``;
const StyledButton = styled(Button)`
  display: inline-block;
  min-width: 16px;
  padding: 0;
  min-height: 0;

  .bp4-icon:first-child:last-child {
    margin: 0;
  }
`;

type Props = {
  className?: string;
  content: string;
  buttonClassName?: string;
};
const InfoTooltip: FC<Props> = ({ className, content, buttonClassName }) => (
  <Wrapper className={className}>
    <StyledTooltip2
      className={className}
      position="bottom-left"
      minimal
      content={<Content>{content}</Content>}
    >
      <StyledButton className={buttonClassName} minimal icon="info-sign" />
    </StyledTooltip2>
  </Wrapper>
);

export default InfoTooltip;
