import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const generateDocument = async (id: number, locale?: string): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher
    .withAuth()
    .get(`/api/document-submissions/${id}/generate`, { format: 'pdf', locale });
  const header =
    res.headers.get('Content-Disposition') ||
    res.headers.get('content-disposition');
  const parts = header?.split?.(';') || [];
  const filename = parts[1]?.split?.('=')?.[1]?.replace?.(/"/g, '') || '';
  const data = await res.blob();
  return [filename, data];
};

export default generateDocument;
