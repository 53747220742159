import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Health, Bank, SecureDoc } from 'components/icons';
import { MEDIA_SM, MEDIA_LG, MEDIA_MD } from 'styles/media';

const Container = styled.div`
  text-align: center;
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 17px;
  padding: 0 20px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 480px;
  margin: auto;
  ${({ theme }) => theme.text.description};

  @media ${MEDIA_SM} {
    padding: 0 20px;
  }
`;

const Card = styled.div`
  width: 290px;
  box-shadow: ${({ theme }) => theme.shadows.base};
  border-radius: 100px 24px 24px 24px;
  text-align: center;
  padding: 32px 20px;
  height: 600px;

  @media ${MEDIA_LG} {
    width: 240px;
    padding: 24px 12px;
  }

  @media ${MEDIA_MD} {
    width: 290px;
    padding: 32px 16px;
  }
`;

const CardContainer = styled.div`
  position: relative;
  display: inline-flex;
  margin: auto;
  margin-top: 80px;
  justify-content: center;
  gap: 32px;

  @media ${MEDIA_MD} {
    flex-wrap: wrap;
  }

  @media ${MEDIA_SM} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 63px;
    gap: 56px;
  }
`;

const CardTite = styled.h4`
  font-family: 'Open Sans';
  font-size: 24px;
  line-height: 32px;
  font-weight: ${({ theme }) => theme.font.extraBold};
  color: ${({ theme }) => theme.primary.text};
  margin-top: 29px;
  margin-bottom: 17px;
`;
const CardDescrription = styled.p`
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 32px;
  color: rgba(51, 51, 51, 0.5);
`;
const IconContainer = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: -54px;
`;
const FirstIcon = styled(IconContainer)`
  background: linear-gradient(180deg, #f1f6f2 0%, #e6f7e2 100%);
  color: #69d795;
`;
const SecondCard = styled(Card)`
  margin-top: 48px;

  @media ${MEDIA_MD} {
    margin-top: 0;
  }

  @media ${MEDIA_SM} {
    margin-top: 48px;
  }
`;
const SecondIcon = styled(IconContainer)`
  background: linear-gradient(180deg, #e4fffc 0%, #ceeeed 100%);
  color: #7ed6d3;
`;
const ThirdCard = styled(Card)`
  border-radius: 24px 24px 100px 24px;

  @media ${MEDIA_SM} {
    margin-top: 48px;
  }
`;
const ThirdIcon = styled(IconContainer)`
  background: linear-gradient(180deg, #fff8ec 0%, #ffefd4 100%);
  color: #ffae10;
`;

type Props = {
  className?: string;
};
const ValuesSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <Header>{t('sections.value.header')}</Header>
      <Title>{t('sections.value.title')}</Title>
      <Description>{t('sections.value.description')}</Description>
      <CardContainer>
        <Card>
          <FirstIcon>
            <Health size={64} />
          </FirstIcon>
          <CardTite>{t('sections.value.title1')}</CardTite>
          <CardDescrription>
            {t('sections.value.description1')}
          </CardDescrription>
        </Card>
        <SecondCard>
          <SecondIcon>
            <Bank size={64} />
          </SecondIcon>
          <CardTite>{t('sections.value.title2')}</CardTite>
          <CardDescrription>
            {t('sections.value.description2')}
          </CardDescrription>
        </SecondCard>
        <ThirdCard>
          <ThirdIcon>
            <SecureDoc size={64} />
          </ThirdIcon>
          <CardTite>{t('sections.value.title3')}</CardTite>
          <CardDescrription>
            {t('sections.value.description3')}
          </CardDescrription>
        </ThirdCard>
      </CardContainer>
    </Container>
  );
};

export default ValuesSection;
