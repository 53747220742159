import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const createSubmission = async ({
  name,
  email,
  message,
}: {
  name: string;
  email: string;
  message: string;
}): Promise<void> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);

  const res = await fetcher.json().post('/api/feedbacks', {
    name,
    email,
    message,
  });
  const data = await res.json();
  return data;
};

export default createSubmission;
