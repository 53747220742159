import { FC, useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from '@blueprintjs/core';
import { useMedia } from 'react-use';

import { Knowledge } from '@type';
import { fetchKnowledges } from 'api/knowledge';
import AppToaster from 'components/AppToaster';
import { extractHtmlText } from 'utils/fn';
import { MEDIA_LG, MEDIA_SM } from 'styles/media';
import { locale2Lang } from 'utils/i18n';

import KnowledgeCard from './KnowledgeCard';

const Container = styled.div`
  text-align: center;
  position: relative;
`;
const CardBlock = styled.div`
  width: fit-content;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 32px;
  min-height: 476px;

  &.bp4-skeleton {
    width: 100%;
  }

  @media ${MEDIA_LG} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${MEDIA_SM} {
    grid-template-columns: 1fr;
    padding: 0 24px;

    &.bp4-skeleton {
      height: 476px;
    }
  }
`;
const StyledSpinner = styled(Spinner)`
  margin-top 20px;
`;
const ActionBar = styled.div`
  margin-top: 40px;
`;
const LoadMoreButton = styled(Button)`
  margin-top: 24px;

  &.bp4-button.bp4-outlined {
    border: 2px solid #69d795;
    border-radius: 24px;
    width: 170px;
    height: 48px;

    &:disabled {
      opacity: 0.3;
      display: none;
    }

    .bp4-button-text {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;

      text-align: center;
      text-transform: uppercase;
      color: #69d795;
    }
  }
`;

type Props = {
  className?: string;
};
const KnowledgeListSection: FC<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const isLG = useMedia(MEDIA_LG);
  const isMobile = useMedia(MEDIA_SM);
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState<Knowledge[]>([]);
  const [meta, setMeta] = useState({ page: 1, pageCount: 1 });

  const loadKnowledge = useCallback((p: number) => {
    (async () => {
      try {
        setLoading(true);
        let size = 9;
        if (isLG) size = 6;
        if (isMobile) size = 3;
        const result = await fetchKnowledges({ size, page: p });
        setNews((n) => [
          ...n.slice(0, (p - 1) * size),
          ...result.data,
          ...n.slice((p + 1) * size),
        ]);
        setMeta({
          page: result.meta.pagination.page,
          pageCount: result.meta.pagination.pageCount,
        });
      } catch (e: any) {
        AppToaster.apiError(e);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    loadKnowledge(1);
  }, []);

  return (
    <Container className={className} ref={ref}>
      <CardBlock>
        {news.map((p) => {
          const langData = p.attributes.localizations?.data?.find(
            (x) => x.attributes.locale === locale2Lang(i18n.language),
          );
          return (
            <KnowledgeCard
              key={p.id}
              id={p.id}
              title={langData?.attributes?.title || p.attributes.title}
              content={extractHtmlText(
                langData?.attributes?.content || p.attributes.content,
              )}
              imgSrc={p.attributes.cover?.data.attributes.url}
              category={p.attributes.category}
              createAt={p.attributes.createdAt}
            />
          );
        })}
      </CardBlock>
      {loading && <StyledSpinner />}
      <ActionBar>
        <LoadMoreButton
          onClick={() => {
            loadKnowledge(meta.page + 1);
            // ref.current?.scrollIntoView({ block: 'start' });
          }}
          large
          outlined
          disabled={meta.page >= meta.pageCount || loading}
          text={t('actions.loadMore')}
        />
      </ActionBar>
    </Container>
  );
};

export default KnowledgeListSection;
