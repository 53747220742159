import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type LoginResponse = {
  accessToken: string;
  refreshToken: string;
};
const login = async (
  username: string,
  rawPassword: string,
): Promise<LoginResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  // const password = await sha256(rawPassword);
  const res = await fetcher.json().post(`/api/public-user/login`, {
    username,
    password: rawPassword,
  });
  const data = await res.json();
  return data;
};

export default login;
