import {
  FC,
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem, Icon, Collapse } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useTranslation } from 'react-i18next';
import { useMedia, useClickAway } from 'react-use';

import { Bill, Card, DoorOut, Logo, UserSolid } from 'components/icons';
import ToTopButton from 'components/ToTopButton';
import { getRefreshToken, getUser, logout } from 'state/app';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';
import BeforeNavigatePubSub from 'utils/beforeNavigatePubSub';
import Tracker from 'utils/tracker';

const Container = styled.div`
  position: relative;
  background: #ebf6ea;
  min-height: 100vh;
  display: flex;
`;
const LeftBar = styled.div`
  background: #424b4c;
  min-width: 275px;
  padding: 42px 32px;
  height: 100vh;
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  overflow-y: auto;
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  margin: 0;
  margin-top: 40px;

  @media ${MEDIA_MD} {
    display: flex;
    flex-direction: column;
    align-item: center;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;

    * + * {
      margin-left: 0;
    }

    button {
      padding: 11px 8px;
    }
  }
`;
const StyledButton = styled(Button)`
  border-radius: 6px;

  &.bp4-button {
    color: #bfbfbf;
    justify-content: flex-start;
    padding: 20px 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    svg {
      margin-right: 15px;
    }
  }

  .active &.bp4-button {
    color: ${({ theme }) => theme.success.text};
    background: ${({ theme }) => theme.success.background};

    &:hover {
      color: ${({ theme }) => theme.success.text};
    }
  }

  &.bp4-button:hover {
    color: ${({ theme }) => theme.secondary.text};
  }
`;
const Content = styled.div`
  flex: 1;
`;
const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 42px 57px;

  > * + * {
    margin-left: 16px;
  }

  .bp4-button {
    min-width: 0;
    min-height: 0;

    svg {
      width: 27px;
      height: 27px;
    }
  }
`;
const PicContainer = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
  }
`;
const TabletHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.68);
  backdrop-filter: blur(2px);
`;
const TabletHeader = styled.div`
  height: 76px;
  padding: 12px 24px 6px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${MEDIA_SM} {
    height: 56px;
    padding: 12px 12px 6px 12px;
  }
`;
const IconButton = styled(Button)`
  &.bp4-button.bp4-minimal {
    color: ${({ theme }) => theme.secondary.text};
  }
  &.bp4-button.bp4-minimal .bp4-icon {
    color: ${({ theme }) => theme.secondary.text};
  }
`;
const HR = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.35);
  width: 100%;
  margin: 11px 0;
`;
const LinkButton = styled(Button)`
  &.bp4-button {
    color: ${({ theme }) => theme.secondary.text};

    &:hover {
      color: ${({ theme }) => theme.secondary.text};
    }
  }

  ${({ theme }) => theme.text.headerLinkButton};
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * + * {
    margin-left: 8px;
  }

  .bp4-button {
    min-width: 0;
    min-height: 0;

    svg {
      width: 27px;
      height: 27px;
      color: #fff;
    }
  }
`;

const UserLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMedia(MEDIA_MD);
  const [open, setOpen] = useState(false);

  const isLogin = Boolean(useSelector(getRefreshToken));
  const user = useSelector(getUser);
  const onLinkClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(false);
    const target = new URL(e.currentTarget.href).pathname;
    BeforeNavigatePubSub.warp(() => {
      navigate(target);
    });
  }, []);
  const onLogoutClick = useCallback(() => {
    BeforeNavigatePubSub.warp(() => {
      dispatch(logout());
      Tracker.reset();
    });
  }, []);

  useEffect(() => {
    if (!isLogin) {
      navigate('/login');
    }
  }, [isLogin]);

  useClickAway(headerRef, () => {
    setOpen(false);
  });

  if (!isLogin) {
    return null;
  }

  if (isTablet) {
    return (
      <Container>
        <TabletHeaderContainer ref={headerRef}>
          <TabletHeader>
            <IconButton
              minimal
              icon={<Icon icon="menu" size={24} />}
              onClick={() => setOpen((o) => !o)}
            />
            <ButtonGroup>
              <Button
                minimal
                icon="globe-network"
                onClick={() => setOpen((o) => !o)}
              />
              <a href="/faq" target="_blank">
                <Button minimal icon="help" />
              </a>
              <PicContainer>
                {user?.profile_pic?.url ? (
                  <img src={user?.profile_pic?.url} alt="profile" />
                ) : (
                  <Icon icon="media" />
                )}
              </PicContainer>
            </ButtonGroup>
          </TabletHeader>
          <Collapse isOpen={open}>
            <List>
              <NavLink to="/user/documents" onClick={onLinkClick}>
                <LinkButton
                  icon={<UserSolid />}
                  minimal
                  text={t('user.layout.folder')}
                />
              </NavLink>
              <NavLink to="/user/payments/new" onClick={onLinkClick}>
                <LinkButton
                  icon={<Bill />}
                  minimal
                  text={t('user.layout.paymentsNew')}
                />
              </NavLink>
              <NavLink to="/user" onClick={onLinkClick}>
                <LinkButton
                  icon={<Card />}
                  minimal
                  text={t('user.layout.profile')}
                />
              </NavLink>
              <HR />
              <LinkButton
                minimal
                text="English"
                onClick={() => {
                  setOpen(false);
                  i18n.changeLanguage('en');
                }}
              />
              <LinkButton
                minimal
                text="繁體中文"
                onClick={() => {
                  setOpen(false);
                  i18n.changeLanguage('zh-HK');
                }}
              />
              <LinkButton
                minimal
                text="简体中文"
                onClick={() => {
                  setOpen(false);
                  i18n.changeLanguage('zh-CN');
                }}
              />
              <HR />
              <LinkButton
                minimal
                fill
                text={t('user.layout.logout')}
                icon={<DoorOut />}
                onClick={onLogoutClick}
              />
            </List>
          </Collapse>
        </TabletHeaderContainer>
        {children}
      </Container>
    );
  }

  return (
    <Container>
      <LeftBar>
        <Logo />
        <List>
          <NavLink to="/user/documents" onClick={onLinkClick}>
            <StyledButton
              minimal
              fill
              text={t('user.layout.folder')}
              icon={<UserSolid />}
            />
          </NavLink>
          <NavLink to="/user/payments/new" onClick={onLinkClick}>
            <StyledButton
              minimal
              fill
              text={t('user.layout.paymentsNew')}
              icon={<Bill />}
            />
          </NavLink>
          <NavLink to="/user" end onClick={onLinkClick}>
            <StyledButton
              minimal
              fill
              text={t('user.layout.profile')}
              icon={<Card />}
            />
          </NavLink>
          <StyledButton
            minimal
            fill
            text={t('user.layout.logout')}
            icon={<DoorOut />}
            onClick={onLogoutClick}
          />
        </List>
      </LeftBar>
      <Content>
        <TopBar>
          <Popover2
            position="bottom-left"
            content={
              <Menu>
                <MenuItem
                  text="English"
                  onClick={() => i18n.changeLanguage('en')}
                />
                <MenuItem
                  text="繁體中文"
                  onClick={() => i18n.changeLanguage('zh-HK')}
                />
                <MenuItem
                  text="简体中文"
                  onClick={() => i18n.changeLanguage('zh-CN')}
                />
              </Menu>
            }
          >
            <Button minimal icon="globe-network" />
          </Popover2>
          <a href="/faq" target="_blank">
            <Button minimal icon="help" />
          </a>
          <PicContainer>
            {user?.profile_pic?.url ? (
              <img src={user?.profile_pic?.url} alt="profile" />
            ) : (
              <Icon icon="media" />
            )}
          </PicContainer>
        </TopBar>
        {children}
      </Content>
      {ReactDOM.createPortal(
        <ToTopButton />,
        document.getElementById('bubble-box') || document.body,
      )}
    </Container>
  );
};

export default UserLayout;
