import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import { useWindowScroll } from 'react-use';

const Wrapped = styled(Button)`
  border-radius: 100%;
  width: 56px;
  height: 56px;
  background: rgba(168, 168, 168, 0.68) !important;
  backdrop-filter: blur(2px);
  box-shadow: none !important;

  &.hide {
    display: none;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;

type Props = {
  className?: string;
};
const ToTopButton: FC<Props> = () => {
  const { y } = useWindowScroll();
  return (
    <Wrapped
      className={y === 0 ? 'hide' : ''}
      icon="chevron-up"
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    />
  );
};
export default ToTopButton;
