import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { Button } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { MEDIA_SM, MEDIA_MD } from 'styles/media';
import { updateMe, createCheckout } from 'api';
import { AppToaster, MuiPhoneField, MuiSelectField } from 'components';
import { getRefreshToken, getUser } from 'state/app';
import { useRefreshMe } from 'hooks';
import { industries, companySizes } from 'constants/kyc';

import { AccountType } from '@type';

const Description = styled.p`
  margin-top: 24px;
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #222222;
  opacity: 0.4;
`;

const NameBlock = styled.div`
  display: flex;
  gap: 10px;
`;
const InputField: typeof TextField = styled(TextField)`
  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;
const Form = styled.form`
  margin-top: 50px;
  color: ${({ theme }) => theme.primary.text};
  max-width: 360px;
  text-align: left;

  > div + div {
    margin-top: 20px;
  }

  @media ${MEDIA_MD} {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 80px;
  }

  @media ${MEDIA_SM} {
    margin: auto;
    margin-top: 40px;
  }
`;
const SubmitButton = styled(Button)`
  margin-top: 28px;

  &.bp4-button {
    height: 60px;
    background: ${({ theme }) => theme.success.text};
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
  }

  &.bp4-button:hover {
    background: ${({ theme }) => theme.success.textHover};
  }
`;

// eslint-disable-next-line complexity
const KYCPage: FC = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = Boolean(useSelector(getRefreshToken));
  const user = useSelector(getUser);
  const isCompany = user?.account_type === AccountType.COMPANY;
  const { t, i18n } = useTranslation();
  const refreshMe = useRefreshMe();

  const schema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        lastName: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        contactPhone: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        mobilePhone: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
      }),
    [i18n.language],
  );
  const companySchema = useMemo(
    () =>
      schema.shape({
        companyAddress: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        industry: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        companySize: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
      }),
    [schema],
  );
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyAddress: '',
      contactPhone: '',
      mobilePhone: '',
      industry: '',
      companySize: '',
    },
    validateOnChange: true,
    validationSchema: isCompany ? companySchema : schema,
    onSubmit: async (data) => {
      try {
        setLoading(true);
        await updateMe({
          firstName: data.firstName,
          lastName: data.lastName,
          meta: {
            contactPhone: data.contactPhone,
            mobilePhone: data.mobilePhone,
            ...(isCompany && {
              companyAddress: data.companyAddress,
              industry: data.industry,
              companySize: data.companySize,
            }),
          },
        });
        refreshMe();
        if (location.state?.planId) {
          const { planId } = location.state;
          const result = await createCheckout(planId);
          window.location.assign(result.url);
          return;
        }
        navigate('/user');
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!isLogin) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    (async () => {
      let u = user;
      if (!u) {
        u = await refreshMe();
      }
      if (u?.first_name) {
        navigate('/user');
      }
    })();
  }, []);

  return (
    <>
      <Description>
        {isCompany
          ? t('pages.kycPage.descriptionCompany')
          : t('pages.kycPage.descriptionIndividual')}
      </Description>
      <Form onSubmit={handleSubmit}>
        <NameBlock>
          <InputField
            variant="outlined"
            label={t('forms.kyc.lastName')}
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName && Boolean(errors.lastName)}
            fullWidth
            helperText={touched.lastName ? errors.lastName : ''}
            autoComplete="family-name"
          />
          <InputField
            variant="outlined"
            label={t('forms.kyc.firstName')}
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName && Boolean(errors.firstName)}
            fullWidth
            helperText={touched.firstName ? errors.firstName : ''}
            autoComplete="given-name"
          />
        </NameBlock>
        {isCompany && (
          <InputField
            variant="outlined"
            label={t('forms.kyc.companyAddress')}
            name="companyAddress"
            value={values.companyAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyAddress && Boolean(errors.companyAddress)}
            fullWidth
            helperText={touched.companyAddress ? errors.companyAddress : ''}
          />
        )}
        <MuiPhoneField
          label={t('forms.kyc.contactPhone')}
          name="contactPhone"
          value={values.contactPhone}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          touched={touched.contactPhone}
          error={errors.contactPhone}
        />
        <MuiPhoneField
          label={t('forms.kyc.mobilePhone')}
          name="mobilePhone"
          value={values.mobilePhone}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          touched={touched.mobilePhone}
          error={errors.mobilePhone}
        />
        {isCompany && (
          <MuiSelectField
            name="industry"
            label={t('forms.kyc.industry')}
            value={values.industry}
            touched={touched.industry}
            error={errors.industry}
            onChange={handleChange}
            onBlur={handleBlur}
            options={industries.map((ind) => ({
              value: ind,
              label: t(
                `constants.industry.${ind.replace(/\s/g, '').toLowerCase()}`,
              ),
            }))}
          />
        )}
        {isCompany && (
          <MuiSelectField
            name="companySize"
            label={t('forms.kyc.companySize')}
            value={values.companySize}
            touched={touched.companySize}
            error={errors.companySize}
            onChange={handleChange}
            onBlur={handleBlur}
            options={companySizes.map((c) => ({
              value: c,
              label: c,
            }))}
          />
        )}
        <SubmitButton
          loading={loading}
          type="submit"
          large
          fill
          text={t('forms.kyc.submit')}
        />
      </Form>
    </>
  );
};

export default KYCPage;
