import { FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { updatePassword } from 'api';
import { TextField, AppToaster, Button } from 'components';
import { Lock, Pencil } from 'components/icons';
import { MEDIA_MD, MEDIA_SM, MEDIA_LG } from 'styles/media';

const Form = styled.form`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 100px 24px 24px 24px;
  padding: 48px 64px 48px 120px;

  @media ${MEDIA_LG} {
    padding: 28px 24px 40px 34px;
    border-radius: 60px 24px 24px 24px;
  }

  .bp4-form-group.bp4-disabled label.bp4-label {
    color: rgb(0, 0, 0) !important;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  ${({ theme }) => theme.text.sectionTitle};
`;
const Content = styled.div`
  margin-top: 24px;
`;
const InputBlock = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  @media ${MEDIA_MD} {
    grid-template-columns: 1fr;
  }
`;
const ActionBar = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 28px;

  @media ${MEDIA_SM} {
    margin-top: 26px;
    justify-content: center;
  }
`;
const BottomButton = styled(Button)`
  width: 200px;

  @media ${MEDIA_MD} {
    width: 100%;
    max-width: 200px;
    flex: 1fr;
  }

  @media ${MEDIA_SM} {
    font-size: 14px !important;
    line-height: 19px !important;
  }
`;
const EditButton = styled(Button)`
  width: 186px;
  max-width: 186px;
  flex: 1;

  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;

  @media ${MEDIA_SM} {
    font-size: 12px !important;
    line-height: 16px !important;
  }
`;

type Props = {
  className?: string;
};
const ChangePasswordSection: FC<Props> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMedia(MEDIA_SM);

  const schema = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        newPassword: yup
          .string()
          .required(t('validate.required', { ns: 'errors' })),
        confirmPassword: yup
          .string()
          .required(t('validate.required', { ns: 'errors' }))
          .oneOf(
            [yup.ref('newPassword')],
            t('validate.samePassword', { ns: 'errors' }),
          ),
      }),
    [i18n.language],
  );

  const [edit, setEdit] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: schema,
    onSubmit: async (data) => {
      try {
        await updatePassword({
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        });
        AppToaster.success('Password changed');
        setEdit(false);
        resetForm();
      } catch (e: any) {
        AppToaster.apiError(e);
      }
    },
  });

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <Header>
        <Title>{t('forms.changePasswordSection.title')}</Title>
        {!edit && !isMobile && (
          <EditButton
            type="button"
            text={t('forms.changePasswordSection.edit')}
            prefix={<Pencil />}
            round
            onClick={() => setEdit(true)}
          />
        )}
      </Header>
      <Content>
        <InputBlock>
          <TextField
            label={t('forms.changePasswordSection.oldPassword')}
            name="oldPassword"
            type="password"
            prefix={<Lock />}
            value={values.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.oldPassword}
            error={errors.oldPassword}
            disabled={!edit}
          />
          <div />
          <TextField
            label={t('forms.changePasswordSection.newPassword')}
            name="newPassword"
            type="password"
            prefix={<Lock />}
            value={values.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.newPassword}
            error={errors.newPassword}
            disabled={!edit}
          />
          <TextField
            label={t('forms.changePasswordSection.confirmPassword')}
            name="confirmPassword"
            type="password"
            prefix={<Lock />}
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.confirmPassword}
            error={errors.confirmPassword}
            disabled={!edit}
          />
        </InputBlock>
        {!edit && isMobile && (
          <ActionBar>
            <EditButton
              type="button"
              text={t('forms.changePasswordSection.edit')}
              prefix={<Pencil />}
              round
              onClick={() => setEdit(true)}
            />
          </ActionBar>
        )}
        {edit && (
          <ActionBar>
            <BottomButton
              type="submit"
              text={t('forms.changePasswordSection.submit')}
              round
              intent="success"
            />
            <BottomButton
              type="button"
              text={t('forms.changePasswordSection.cancel')}
              round
              intent="success"
              outlined
              onClick={() => setEdit(false)}
            />
          </ActionBar>
        )}
      </Content>
    </Form>
  );
};

export default ChangePasswordSection;
