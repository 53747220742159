import { FC } from 'react';

const maskStyle = {
  maskType: 'alpha' as const,
};
type Props = {
  width?: number;
  height?: number;
  color?: string;
};
const Logo: FC<Props> = ({ width = 116, height = 44, color = 'white' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 116 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_89_1085"
      style={maskStyle}
      maskUnits="userSpaceOnUse"
      x="17"
      y="0"
      width="10"
      height="22"
    >
      <path
        d="M17.4072 0.616211C25.3258 0.933146 27.321 7.50582 26.4726 15.9422L21.6869 21.0436V6.58247C21.0974 2.98039 19.4134 1.16533 17.4137 0.62785L17.4072 0.616211Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask0_89_1085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 150.844H116.154V0.45166H0V150.844Z"
        fill="url(#paint0_linear_89_1085)"
      />
    </g>
    <mask
      id="mask1_89_1085"
      style={maskStyle}
      maskUnits="userSpaceOnUse"
      x="21"
      y="15"
      width="20"
      height="11"
    >
      <path
        d="M26.4725 15.9422L21.6846 21.046H35.2496C38.6326 21.6754 40.3415 23.4719 40.8434 25.6087H40.8445C40.5898 18.338 35.981 15.75 29.6513 15.75C28.6332 15.75 27.5704 15.8163 26.4725 15.9422Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask1_89_1085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4725 15.9422L21.6846 21.046H35.2496C38.6326 21.6754 40.3415 23.4719 40.8434 25.6087H40.8445C40.5898 18.338 35.981 15.75 29.6513 15.75C28.6332 15.75 27.5704 15.8163 26.4725 15.9422Z"
        fill="url(#paint1_linear_89_1085)"
      />
    </g>
    <mask
      id="mask2_89_1085"
      style={maskStyle}
      maskUnits="userSpaceOnUse"
      x="7"
      y="13"
      width="5"
      height="4"
    >
      <path
        d="M11.8243 13.2119C11.7347 14.1726 11.6899 15.1935 11.6779 16.2625H7.08641C7.06346 15.2251 7.06682 14.2051 7.10852 13.2119H11.8243Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask2_89_1085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 150.844H116.154V0.45166H0V150.844Z"
        fill="url(#paint2_linear_89_1085)"
      />
    </g>
    <mask
      id="mask3_89_1085"
      style={maskStyle}
      maskUnits="userSpaceOnUse"
      x="1"
      y="17"
      width="12"
      height="28"
    >
      <path
        d="M11.6776 17.4683C11.7826 29.881 15.2289 47.1715 1.19922 43.7163C8.84457 41.9967 7.6538 37.4071 7.47438 23.4077C7.34422 21.4201 7.19894 19.4197 7.12336 17.4683H11.6776Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask3_89_1085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 150.844H116.154V0.45166H0V150.844Z"
        fill="url(#paint3_linear_89_1085)"
      />
    </g>
    <mask
      id="mask4_89_1085"
      style={maskStyle}
      maskUnits="userSpaceOnUse"
      x="7"
      y="0"
      width="15"
      height="13"
    >
      <path
        d="M13.2534 1.09627V1.09508C13.2489 1.09866 13.2433 1.10224 13.238 1.10463C10.8161 2.21301 8.65436 4.62166 7.96772 7.56032C7.54561 8.93132 7.30404 10.4283 7.1792 12.0067H11.9663C12.7075 6.93331 15.0935 4.07254 21.687 6.58236C21.0975 2.98028 19.4135 1.16521 17.4138 0.627735C16.9721 0.508661 16.5161 0.45166 16.0514 0.45166C15.1263 0.45166 14.1718 0.679066 13.2534 1.09627Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask4_89_1085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2534 1.09627V1.09508C13.2489 1.09866 13.2433 1.10224 13.238 1.10463C10.8161 2.21301 8.65436 4.62166 7.96772 7.56032C7.54561 8.93132 7.30404 10.4283 7.1792 12.0067H11.9663C12.7075 6.93331 15.0935 4.07254 21.687 6.58236C21.0975 2.98028 19.4135 1.16521 17.4138 0.627735C16.9721 0.508661 16.5161 0.45166 16.0514 0.45166C15.1263 0.45166 14.1718 0.679066 13.2534 1.09627Z"
        fill="url(#paint4_linear_89_1085)"
      />
    </g>
    <mask
      id="mask5_89_1085"
      style={maskStyle}
      maskUnits="userSpaceOnUse"
      x="0"
      y="21"
      width="42"
      height="22"
    >
      <path
        d="M35.2498 21.0459C38.6329 21.6753 40.3418 23.4719 40.8437 25.6086C41.1751 27.0157 40.9825 28.5709 40.3944 30.0374C40.3933 30.043 40.3899 30.049 40.3877 30.0547C39.3467 32.637 37.0889 34.9415 34.3311 35.6736C30.9036 36.8713 26.6428 36.7209 22.2902 36.4106L21.7778 36.3734C21.4359 36.3482 21.0937 36.3224 20.7514 36.2964L20.238 36.2573C19.9813 36.2379 19.7248 36.2185 19.4684 36.1994C6.33757 36.0084 2.03274 34.7389 0.419867 42.8897C-2.30064 30.3334 8.69167 30.9082 19.1787 31.4827C29.3878 32.0422 39.1183 32.6018 35.2498 21.0459Z"
        fill={color}
      />
    </mask>
    <g mask="url(#mask5_89_1085)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 150.844H116.154V0.45166H0V150.844Z"
        fill="url(#paint5_linear_89_1085)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.042 24.7495H61.6813V22.6011H55.042V24.7495Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.4358 27.5601H65.8022V24.1072H69.4358C70.4077 24.1072 71.793 24.7402 71.793 25.9491C71.793 27.2139 70.4077 27.5601 69.4358 27.5601ZM72.1353 22.9753C73.3042 22.419 73.7723 21.4213 73.7723 20.3861C73.7723 17.6814 71.9189 16.4912 69.4358 16.4912H63.4624V18.7936H69.4358C70.9479 18.7936 71.4148 19.8289 71.4148 20.4431C71.4148 21.2103 70.9294 21.8818 69.4358 21.8818H63.4624V29.9183H69.4358C72.0446 29.9183 74.1493 28.96 74.1493 25.9676C74.1493 25.1237 73.6105 23.4542 72.1353 22.9753Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.182 25.1714C85.5745 24.6539 86.3661 22.7932 86.3661 20.9513C86.3661 18.6303 84.8013 16.5204 81.8328 16.5016C79.8351 16.5016 77.8384 16.4819 75.8418 16.4819V18.8613H81.8328C83.3254 18.8613 83.9913 19.9348 83.9913 21.0098C83.9913 22.0832 83.3438 23.157 81.8328 23.157H75.8418V29.9287H78.2158V25.4394H80.501L84.1715 29.9287H86.9959V29.7562L83.182 25.1714Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.9863 29.9288H90.3427V16.5005H87.9863V29.9288Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.9495 16.5017V27.5882H101.426V29.9288H92.5747V16.5017H94.9495Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.475 16.5017V27.5882H111.951V29.9288H103.1V16.5017H105.475Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.643 29.9288H116V16.5005H113.643V29.9288Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5291 29.9287H52.7169V20.4642H50.5291V29.9287ZM52.9148 17.8445C52.9148 19.6608 50.332 19.6608 50.332 17.8445C50.332 16.0271 52.9148 16.0271 52.9148 17.8445Z"
      fill={color}
    />
    <defs>
      <linearGradient
        id="paint0_linear_89_1085"
        x1="34.8179"
        y1="14.472"
        x2="33.4648"
        y2="8.32167"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1D240" />
        <stop offset="1" stopColor="#E2E331" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_89_1085"
        x1="28.4348"
        y1="20.4575"
        x2="34.8141"
        y2="21.173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1D240" />
        <stop offset="1" stopColor="#E2E331" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_89_1085"
        x1="25.6938"
        y1="7.29316"
        x2="23.7998"
        y2="20.7155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1D240" />
        <stop offset="1" stopColor="#E2E331" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_89_1085"
        x1="25.8514"
        y1="7.32596"
        x2="23.9574"
        y2="20.7483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1D240" />
        <stop offset="1" stopColor="#E2E331" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_89_1085"
        x1="14.5925"
        y1="7.15969"
        x2="13.5151"
        y2="14.795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1D240" />
        <stop offset="1" stopColor="#E2E331" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_89_1085"
        x1="40.0621"
        y1="31.5652"
        x2="31.47"
        y2="31.7879"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A39B43" />
        <stop offset="1" stopColor="#E2E136" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
