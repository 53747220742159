import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Icon } from '@blueprintjs/core';

import { MEDIA_LG, MEDIA_MD, MEDIA_SM } from 'styles/media';
import Map from './Map';
import { WhatsApp } from 'components/icons';

const Container = styled.div`
  position: relative;

  @media ${MEDIA_MD} {
    display: flex;
    flex-direction: column-reverse;
  }
`;
const StyledMap = styled(Map)`
  height: 576px;

  @media ${MEDIA_SM} {
    height: 427px;
  }
`;
const Content = styled.div`
  position: absolute;
  top: -88px;
  left: 0;
  background: rgba(97, 114, 125, 0.85);
  backdrop-filter: blur(2px);
  border-radius: 0px 100px 24px 0px;
  padding: 6em 5em 6em 8em;
  width: 50%;

  @media ${MEDIA_LG} {
    padding: 60px 48px 70px 36px;
  }

  @media ${MEDIA_MD} {
    position: relative;
    width: 100%;
    top: 0;
    border-radius: 0px 100px 0px 0px;
  }
`;
const Inner = styled.div`
  margin-left: auto;
  width: fit-content;

  @media ${MEDIA_MD} {
    margin-left: 0;
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
  color: #d6dd37;
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};
  color: ${({ theme }) => theme.secondary.text};

  @media ${MEDIA_SM} {
    margin: 0;
    margin-bottom: 12px;
  }
`;
const Description = styled.div`
  display: block;
  max-width: 480px;
  ${({ theme }) => theme.text.description};
  color: ${({ theme }) => theme.secondary.text};

  > .address {
    white-space: pre-line;
    text-decoration: none;
    font-size: 20px;
    color: ${({ theme }) => theme.secondary.text};
  }

  > .phone,
  > .whatsapp,
  > .mail,
  > .dollar {
    margin-top: 18px;
    display: flex;
    align-items: center;
    .bp4-button.bp4-minimal {
      color: ${({ theme }) => theme.secondary.text};
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    border: 1px solid #fff;
    border-color: ${({ theme }) => theme.secondary.text};
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }

  .title {
    font-weight: 600;
    line-height: 1;
  }
`;
const StyledButton = styled(Button)`
  white-space: pre-line;
  font-size: 16px;
  padding: 0;
  line-height: 1;
`;

type Props = {
  className?: string;
};
const ContactSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  // const searchParam = new URLSearchParams({
  //   center: '22.2842,114.1525',
  //   zoom: '16',
  //   size: '640x240',
  //   scale: '4',
  //   maptype: 'roadmap',
  //   markers:
  //     'color:orange|Central+88,+88-98+Des+Voeux+Rd+Central,+Central,+香港',
  //   visible: 'Hong+Kong+Station',
  //   key: process.env.MAP_API_TOKEN || '',
  // });
  return (
    <Container className={className}>
      <StyledMap />
      <Content>
        <Inner>
          <Header>{t('sections.contact.header')}</Header>
          <Title>{t('sections.contact.title')}</Title>
          <Description>
            <a
              className="address"
              href="https://www.google.com/maps/search/?api=1&query=Cyberport+1,+100+Cyberport+Rd,+Telegraph+Bay"
              target="_blank"
              rel="noreferrer"
            >
              {t('sections.contact.address')}
            </a>
            <div className="phone">
              <Icon className="icon" icon="phone" />
              <div className="container">
                <div className="title">{t('public.footer.phone')}</div>
                <a className="link" href="tel:+85226580003">
                  <StyledButton minimal text="+852 2658 0003" />
                </a>
              </div>
            </div>
            <div className="whatsapp">
              <div className="icon">
                <WhatsApp color='white' size={18}/>
              </div>
              <div className="container">
                <div className="title">{t('public.footer.whatsapp')}</div>
                <a className="link" href="https://wa.me/85291686489" target="_blank">
                  <StyledButton minimal text="+852 9168 6489" />
                </a>
              </div>
            </div>
            <div className="mail">
              <Icon className="icon" icon="envelope" />
              <div className="container">
                <div className="title">{t('public.footer.support')}</div>
                <a className="link" href="mailto:support@i-brilli.com">
                  <StyledButton minimal text="support@i-brilli.com" />
                </a>
              </div>
            </div>
            <div className="dollar">
              <Icon className="icon" icon="dollar" />
              <div className="container">
                <div className="title">{t('public.footer.sales')}</div>
                <a className="link" href="mailto:sales@i-brilli.com">
                  <StyledButton minimal text="sales@i-brilli.com" />
                </a>
              </div>
            </div>
          </Description>
        </Inner>
      </Content>
    </Container>
  );
};

export default ContactSection;
