import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OpenRoles } from '@type';
import { AppToaster } from 'components';
import { MEDIA_SM, MEDIA_XL } from 'styles/media';
import { locale2Lang, formatDate } from 'utils/i18n';
import Tracker from 'utils/tracker';
import fetchOpenRole from 'api/openRoles/fetchOpenRole';

const Container = styled.div`
  position: relative;
  padding-top: 148px;
  min-height: calc(100vh - 470px);
  padding-bottom: 120px;

  @media ${MEDIA_SM} {
    padding-top: 100px;
    padding-bottom: 60px;
  }
`;
const Main = styled.div`
  max-width: 1440px;
  margin: auto;
  width: 100%;
  min-height: 50vh;

  @media ${MEDIA_XL} {
    padding: 0 28px;
  }
`;
const DateTime = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 0px;
  margin-bottom: 17px;
  ${({ theme }) => theme.text.header};
`;
const Description = styled.p`
  display: block;
  max-width: 470px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;
const Content = styled.div`
  line-height: 28px;
  margin-bottom: 30px;

  span {
    font-size: 16px;
  }
`;

const CareerDetailPage: FC<{}> = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [openRole, setOpenRole] = useState<OpenRoles | null>(null);
  // const [formId, setFormId] = useState<string>(t('pages.careerDetail.formId'));

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchOpenRole(Number(id));
        setOpenRole(result.data);

        if (result.data.attributes) {
          const { title } = result.data.attributes;
          Tracker.track('Visited Career Detail Page', { title });
        }
      } catch (e: any) {
        AppToaster.apiError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  if (loading)
    return (
      <Container>
        <Main className="bp4-skeleton" />
      </Container>
    );
  if (!openRole)
    return (
      <Container>
        <Main style={{ textAlign: 'center' }}>
          <Title>{t('errors.notFound.title')}</Title>
          <Description>{t('errors.notFound.description')}</Description>
        </Main>
      </Container>
    );

  const { createdAt, title, description } = openRole.attributes;
  const langData = openRole.attributes.localizations?.data?.find(
    (x) => x.attributes.locale === locale2Lang(i18n.language),
  );
  const buildForm = () => {
    return (
      <>
        {i18n.language === 'en' && <div data-paperform-id="rugmi69b" />}
        {i18n.language === 'zh-HK' && <div data-paperform-id="lmfemo02" />}
        {i18n.language === 'zh-CN' && <div data-paperform-id="hyxv1q4l" />}
      </>
    );
  };
  return (
    <Container>
      <Main>
        <DateTime>
          {t('pages.careerDetail.createdAt')}{' '}
          {formatDate(new Date(createdAt), 'MMM dd, yyyy')}
        </DateTime>
        <Title>{langData?.attributes?.title || title}</Title>
        <Content
          className="ck-content"
          dangerouslySetInnerHTML={{
            __html: langData?.attributes?.description || description,
          }}
        />
        {buildForm()}
      </Main>
    </Container>
  );
};

export default CareerDetailPage;
