import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { mapValues, set } from 'lodash';

import { fetchQuestions, FetchQuestionsResponse } from 'api/questions';
import { Question } from '@type';
import { deepFetchQuestion, addQuestions } from './questionReducer';

function* handleDeepFetchQuestion({
  payload,
}: PayloadAction<{ id: number; ttl: number }>) {
  let toFetch = [payload.id];
  let remain = payload.ttl;
  const fetched = new Set([payload.id]);
  while (remain > 0 && toFetch.length > 0) {
    const result: FetchQuestionsResponse = yield call(fetchQuestions, toFetch);
    toFetch = [];
    const langToFetch: Record<string, number[]> = {};
    // eslint-disable-next-line no-loop-func
    result.data.forEach((q) => {
      (q.attributes.groupMeta?.questions?.data || []).forEach((gq) => {
        if (fetched.has(gq.id)) return;
        toFetch.push(gq.id);
        fetched.add(gq.id);
      });
      (q.attributes.radioMeta?.options || []).forEach((o) => {
        if (!o.question?.data) return;
        if (fetched.has(o.question.data.id)) return;
        toFetch.push(o.question.data.id);
        fetched.add(o.question.data.id);
      });
    });

    result.data.forEach((q) => {
      if (['Select', 'Radio'].includes(q.attributes.type)) {
        (q.attributes.localizations?.data || []).forEach((lq) => {
          langToFetch[lq.attributes.locale] = [
            ...(langToFetch[lq.attributes.locale] || []),
            lq.id,
          ];
        });
      }
    });
    const langResults: Record<string, FetchQuestionsResponse> = yield all(
      mapValues(langToFetch, (v, k) => call(fetchQuestions, v, k)),
    );
    const lookup: Record<number, Question> = {};
    Object.values(langResults)
      .flatMap((l) => l.data)
      .forEach((q) => {
        lookup[q.id] = q;
      });
    const data = result.data.map((q) => {
      const temp = q.attributes.localizations.data.map(
        (lq) => lookup[lq.id] || lq,
      );
      return set(q, 'attributes.localizations.data', temp);
    });

    remain -= 1;
    yield put(addQuestions(data));
  }
}

export default function* questionsSaga() {
  yield takeEvery(deepFetchQuestion.type, handleDeepFetchQuestion);
}
