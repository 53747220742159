import { FC, PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import frontPage from 'assets/images/frontpage.webp';
import { Logo } from 'components/icons';
import { MEDIA_MD } from 'styles/media';
import { useTranslation } from 'react-i18next';
import Tracker from 'utils/tracker';

const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  gap: 24px;
  min-height: 100vh;
  margin: auto;
`;

const LeftBlock = styled.div`
  flex: 1;
  margin-left: 12%;
  margin-top: 100px;

  @media ${MEDIA_MD} {
    margin: auto;
    margin-top: 40px;
    text-align: center;
    padding: 0 16px;
  }
`;
const RightBlock = styled.div`
  margin: 20px;
  margin-left: 0;
  background-image: url(${frontPage});
  background-size: cover;
  flex: 1;
  position: relative;

  > div {
    font-family: 'Super Mario Bros. 2';
    pointer-events: none;
    color: #fff;
    position: absolute;
    top: 100px;
    right: 40px;
    font-size: 30px;
    font-weight: 900;
    line-height: 1.3;
    max-width: 480px;
    white-space: pre-line;
  }

  @media ${MEDIA_MD} {
    display: none;
  }
`;

type Props = PropsWithChildren<{
  className?: string;
}>;
const AuthLayout: FC<Props> = ({ children, className }) => {
  const { t } = useTranslation();

  useEffect(() => {
    Tracker.track('Visited Login Page');
  }, []);

  return (
    <Container className={className}>
      <Inner>
        <LeftBlock>
          <Link to="/">
            <Logo color="#939598" />
          </Link>
          {children}
        </LeftBlock>
        <RightBlock>
          <div>{t('pages.home.slogan')}</div>
        </RightBlock>
      </Inner>
    </Container>
  );
};

export default AuthLayout;
