import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { SecureDoc } from 'components/icons';

const Container = styled.div`
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.shadows.base};
  border-radius: 100px 24px 24px 24px;
  width: 270px;
  height: 317px;
  padding: 42px 36px 31px 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.div`
  margin-top: 20px;
  ${({ theme }) => theme.text.cardTitle};
`;
const Description = styled.div`
  margin-top: 12px;
  ${({ theme }) => theme.text.cardDescription};
`;
const DownloadLink = styled.a``;
const DownloadButton = styled(Button)`
  &.bp4-button.bp4-outlined {
    border: 2px solid #69d795;
    border-radius: 24px;

    &:disabled {
      opacity: 0.3;
    }

    .bp4-button-text {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;

      text-align: center;
      text-transform: uppercase;
      color: #69d795;
    }
  }
`;

type Props = {
  className?: string;
  title: string;
  description: string;
  fileUrl: string;
  ext: string;
};
const BrochureCard: FC<Props> = ({
  className,
  title,
  description,
  fileUrl,
  ext,
}) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <div>
        <SecureDoc size={64} />
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
      <DownloadLink href={fileUrl} download={`${title}${ext}`} target="_blank">
        <DownloadButton disabled={!fileUrl} outlined large>
          {t('actions.download')}
        </DownloadButton>
      </DownloadLink>
    </Container>
  );
};

export default BrochureCard;
