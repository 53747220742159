/* eslint-disable import/prefer-default-export */
type Fn = (...args: any[]) => Promise<any>;
export const retryFn = <T extends Fn>(fn: T, time = 5) => {
  const retrys = [...Array(time)].map((_, idx) => idx);
  let lastError: any;
  return async (...p: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
    // eslint-disable-next-line no-restricted-syntax
    for (const retry of retrys) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const result = await fn(...p);
        return result;
      } catch (e) {
        lastError = e;
        // eslint-disable-next-line no-await-in-loop
        await new Promise((res) => {
          // eslint-disable-next-line no-bitwise
          setTimeout(res, (1000 * 2) >> (retry + 1));
        });
      }
    }
    throw lastError;
  };
};

export const extractHtmlText = (html: string): string => {
  const el = document.createElement('html');
  el.innerHTML = html;
  return el.innerText;
};

// eslint-disable-next-line no-control-regex
const isASCII = (str: string) => /^[\x00-\x7F]*$/.test(str);

export const firstNWords = (str: string, count: number): string => {
  const token = str.split(/\s+/);
  const needSuffix = isASCII(token[0])
    ? token.length > count
    : str.length > count;
  const suffix = needSuffix ? '...' : '';
  const prefix = isASCII(token[0])
    ? token.slice(0, count).join(' ')
    : str.slice(0, count);
  return `${prefix}${suffix}`;
};

export const replaceLast = (
  str: string,
  patt: string,
  dest: string,
): string => {
  const idx = str.lastIndexOf(patt);
  if (idx < 0) return str;
  return `${str.substring(0, idx)}${dest}${str.substring(idx + patt.length)}`;
};

const IGNORE_LIST = new Set(['%locale%']);
export const deepFlatten = (x: any): [string, any][] => {
  if (!x) return [['', x]];
  if (Array.isArray(x)) {
    if (x.length === 0) return [];
    const arr = x.flatMap((v, idx) => {
      const temp = deepFlatten(v);
      return temp.map(
        ([n, sv]) => [n ? `${idx}.${n}` : `${idx}`, sv] as [string, any],
      );
    });
    return arr;
  }
  if (typeof x === 'object') {
    const out = Object.entries(x).flatMap(([name, value]) => {
      if (IGNORE_LIST.has(name)) return [];
      const temp = deepFlatten(value);
      return temp.map(
        ([n, v]) => [n ? `${name}.${n}` : name, v] as [string, any],
      );
    });
    return out;
  }
  return [['', x]];
};
