import { MeMisc } from '@type/publicUser';
import FetcherFactory from 'utils/FetcherFactory';

import StrapiPublicFetcher from '../StrapiPublicFetcher';

type MeMiscResponse = MeMisc;
const fetchMeMisc = async (): Promise<MeMiscResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withAuth().get(`/api/public-user/me/misc`);
  const data = await res.json();
  return data;
};

export default fetchMeMisc;
