import { useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { AppToaster } from 'components';
import { ProductSection } from 'containers';
import { userCategories, companyCategories } from 'constants/plan';
import { getUser } from 'state/app';
import { AccountType } from '@type';
import { createCheckout } from 'api';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  flex: 1;
  display: grid;
  margin-bottom: 60px;

  @media ${MEDIA_MD} {
    margin-top: 89px;
    margin-bottom: 40px;
  }

  @media ${MEDIA_SM} {
    margin-top: 69px;
    padding: 0 10px;
    padding-bottom: 80px;
  }
`;

const NewPlanPage = () => {
  const user = useSelector(getUser);
  const allowed =
    user?.account_type === AccountType.COMPANY
      ? companyCategories
      : userCategories;

  const handlePlanClick = useCallback(async (planId: number) => {
    try {
      const result = await createCheckout(planId);
      window.location.assign(result.url);
    } catch (e: any) {
      AppToaster.apiError(e);
    }
  }, []);

  return (
    <Container>
      <ProductSection categories={allowed} onPlanClick={handlePlanClick} />
    </Container>
  );
};

export default NewPlanPage;
