import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import FetcherFactory from 'utils/FetcherFactory';

import rootSaga from './rootSaga';
import reducers, { RootState as State } from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const store = configureStore({
  reducer: reducers,
  middleware,
  devTools: true,
});

export type RootState = State;

export const createStore = async (): Promise<typeof store> => {
  FetcherFactory.bindStore(store);
  sagaMiddleware.run(rootSaga);
  // TODO: init store
  return store;
};
