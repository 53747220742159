import { FC } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';
import styled from 'styled-components';

const Container = styled.div``;
const Label = styled.label`
  ${({ theme }) => theme.text.inputLabel};
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  margin-bottom: 4px;
`;
const StyledButton = styled(Button)``;
const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  border: 1px solid;
  border-color: ${({ theme }) => theme.success.text};
  border-radius: 6px;

  ${StyledButton} {
    flex: 1;
  }

  ${StyledButton}.bp4-button.bp4-intent-success {
    color: ${({ theme }) => theme.success.text};
  }

  ${StyledButton}.bp4-button.bp4-intent-success.bp4-active {
    background: ${({ theme }) => theme.success.text};
    color: white;
  }
`;

type Props = {
  className?: string;
  label?: string;
  name?: string;
  value: string;
  options: { label: React.ReactNode; value: string }[];
  onChange?: (n: string, value: string) => void;
};
const RadioButtons: FC<Props> = ({
  className,
  name = '',
  label,
  value,
  options,
  onChange,
}) => (
  <Container className={className}>
    {Boolean(label) && <Label>{label}</Label>}
    <StyledButtonGroup minimal large>
      {options.map((o) => (
        <StyledButton
          key={o.value}
          active={value === o.value}
          onClick={() => onChange && onChange(name, o.value)}
          intent="success"
        >
          {o.label}
        </StyledButton>
      ))}
    </StyledButtonGroup>
  </Container>
);

export default RadioButtons;
