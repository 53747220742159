import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import { Button } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import { MEDIA_SM, MEDIA_MD } from 'styles/media';
import { forgetPassword } from 'api';
import { AppToaster } from 'components';

import Tracker from 'utils/tracker';
import useRedirectUserEffect from './useRedirectUserEffect';

const Title = styled.h2`
  margin-top: 24px;
  margin-bottom: 18px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin-bottom: 0;
  }
`;
const Description = styled.p`
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  color: #222222;
  opacity: 0.4;
`;

const InputField: typeof TextField = styled(TextField)`
  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }
  label.Mui-error {
    color: #d32f2f;
  }

  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.success.text};
  }
  .Mui-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
`;
const Form = styled.form`
  margin-top: 50px;
  color: ${({ theme }) => theme.primary.text};
  max-width: 360px;

  @media ${MEDIA_MD} {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 80px;
  }

  ${InputField as any} + ${InputField as any} {
    margin-top: 46px;

    @media ${MEDIA_MD} {
      margin-top: 30px;
    }
  }
`;
const SubmitButton = styled(Button)`
  margin-top: 28px;

  &.bp4-button {
    height: 60px;
    background: ${({ theme }) => theme.success.text};
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
  }

  &.bp4-button:hover {
    background: ${({ theme }) => theme.success.textHover};
  }
`;
const LeftFoot = styled.div`
  margin-top: 40px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #abafb3;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoginButton = styled(Link)`
  margin-left: 4px;
  font-size: inherit;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 0;
  color: #ffae10 !important;
`;
const SuccessBlock = styled.div`
  max-width: 360px;
  margin-top: 60px;
`;
const SuccessMessage = styled.div`
  ${({ theme }) => theme.text.description};
`;
const ReturnLink = styled(Link)`
  display: block;
  margin-top: 24px;
  font-family: 'Open Sans';
  font-size: 16px;
  color: #ffae10 !important;
`;

const ForgetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email()
          .required(t('validate.required', { ns: 'errors' })),
      }),
    [],
  );
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: '',
      },
      validateOnChange: true,
      validationSchema: schema,
      onSubmit: async ({ email }) => {
        try {
          setLoading(true);
          await forgetPassword(email);
          setDone(true);
        } catch (e: any) {
          AppToaster.apiError(e);
        } finally {
          setLoading(false);
        }
      },
    });

  useEffect(() => {
    Tracker.track('Visited Forgot Password Page');
  }, []);

  useRedirectUserEffect();

  return (
    <>
      <Title>{t('pages.forgetPage.title')}</Title>
      <Description>{t('pages.forgetPage.description')}</Description>
      {done ? (
        <SuccessBlock>
          <SuccessMessage>{t('forms.forgetPassword.success')}</SuccessMessage>
          <ReturnLink to="/login">
            {t('forms.forgetPassword.return')}
          </ReturnLink>
        </SuccessBlock>
      ) : (
        <Form onSubmit={handleSubmit}>
          <InputField
            variant="outlined"
            label={t('forms.forgetPassword.email')}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            fullWidth
            helperText={touched.email ? errors.email : ''}
            autoComplete="email"
          />
          <SubmitButton
            type="submit"
            large
            fill
            loading={loading}
            text={t('forms.forgetPassword.submit')}
          />
          <LeftFoot>
            <Trans
              i18nKey="forms.forgetPassword.login"
              defaults="Remember your password? <login>Login here</login>"
              components={{
                login: <LoginButton to="/login" />,
              }}
            />
          </LeftFoot>
        </Form>
      )}
    </>
  );
};

export default ForgetPasswordPage;
