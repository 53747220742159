import { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { QuestionRender } from 'components/questions';
import { deepFetchQuestion } from 'state/questions';
import { Node, RawFlow } from '@type';
import { MEDIA_MD, MEDIA_SM } from 'styles/media';

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 24px 48px rgba(0, 24, 52, 0.080899);
  border-radius: 24px;
  padding: 32px 42px 54px 44px;

  @media ${MEDIA_MD} {
    padding: 22px 12px 30px 20px;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${MEDIA_SM} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    > div:last-child {
      margin-left: auto;
    }
  }
`;
const Title = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #262626;
`;
const ActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const ActionButton = styled(Button)`
  width: 130px;

  &.back-button {
    font-weight: 400;
    font-size: 16px;
    color: #5d737e;

    .bp4-icon {
      color: #5d737e;
    }
  }

  @media ${MEDIA_SM} {
    width: 100px;
    height: auto !important;
    font-size: 14px !important;
    line-height: 15px !important;
  }
`;
const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 44px;

  @media ${MEDIA_SM} {
    gap: 30px;
  }
`;

type Props = {
  className?: string;
  label: string;
  flow: Omit<RawFlow, 'id'>;
  formdata: {
    // deprecated stepData but for backward compactable
    stepData: { node: string; data: any }[];
    step: string[];
    stepLookup: Record<string, any>;
    data: any;
  };
  loading?: boolean;
  onBackClick?: (step?: number) => void;
  onDoneClick?: () => void;
};
const QuestionPreviewSection: FC<Props> = ({
  className,
  flow,
  label,
  formdata,
  loading,
  onBackClick,
  onDoneClick,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nodeLookup = useMemo(
    () =>
      flow.nodes.reduce((memo, n) => {
        // eslint-disable-next-line no-param-reassign
        memo[n.id] = n;
        return memo;
      }, {} as Record<string, Node>),
    [flow],
  );
  const step = useMemo(
    () => formdata.step || formdata.stepData.map((x) => x.node),
    [formdata],
  );
  const lookup = useMemo(() => {
    return (
      formdata.stepLookup ||
      formdata.stepData.reduce((memo, x) => {
        // eslint-disable-next-line no-param-reassign
        memo[x.node] = x.data;
        return memo;
      }, {} as Record<string, any>)
    );
  }, [formdata]);

  useEffect(() => {
    step
      .filter((x) => Boolean(x))
      .forEach((s) => {
        dispatch(
          deepFetchQuestion({
            id: Number(nodeLookup[s].data.id),
            ttl: 8,
          }),
        );
      });
  }, [nodeLookup, formdata]);

  return (
    <Container className={className}>
      <Header>
        <Title>{label}</Title>
        <ActionBar>
          {onBackClick && (
            <ActionButton
              className="back-button"
              prefix="chevron-left"
              text={t('actions.back')}
              onClick={() => onBackClick()}
              loading={loading}
            />
          )}
          <ActionButton
            intent="success"
            text={t('actions.done')}
            onClick={onDoneClick}
            loading={loading}
          />
        </ActionBar>
      </Header>
      <Content>
        {step
          .filter((x) => Boolean(x))
          .map((s, i) => (
            <QuestionRender
              key={s}
              className="preview-question"
              questionId={Number(nodeLookup[s].data.id)}
              name=""
              overrideName={nodeLookup[s].data.overrideName}
              value={lookup[s]}
              readonly
              onEditClick={
                onBackClick
                  ? () => {
                      onBackClick(i);
                    }
                  : undefined
              }
            />
          ))}
      </Content>
    </Container>
  );
};

export default QuestionPreviewSection;
