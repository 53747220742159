import { appReducer, State as AppState } from './app/appReducer';
import {
  questionReducer,
  State as QuestionState,
} from './questions/questionReducer';
import { planReducer, State as PlanState } from './plans/planReducer';
import { documentReducer, State as DocumentState } from './documents';

export type RootState = {
  app: AppState;
  questions: QuestionState;
  plans: PlanState;
  documents: DocumentState;
};
export default {
  app: appReducer,
  questions: questionReducer,
  plans: planReducer,
  documents: documentReducer,
};
