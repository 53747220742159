import { Component, ErrorInfo, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Message = styled.div`
  color: ${({ theme }) => theme.primary.text};
`;
const reload = () => window.location.reload();

type State = {
  hasError: boolean;
  message: string;
};
export default class ErrorBoundary extends Component<
  PropsWithChildren<{}>,
  State
> {
  constructor(p: PropsWithChildren<{}>) {
    super(p);
    this.state = { hasError: false, message: '' };
  }

  componentDidCatch(e: Error, info: ErrorInfo) {
    this.setState({ hasError: true, message: info.componentStack });
    console.error(e, info);
  }

  render() {
    const { hasError, message } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div>
          <Message>{message}</Message>
          <button type="button" onClick={reload}>
            Reload
          </button>
        </div>
      );
    }
    return children;
  }
}
