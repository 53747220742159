import { FC, ReactNode, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { FormGroup, MenuItem } from '@blueprintjs/core';
import { Suggest2, ItemRenderer } from '@blueprintjs/select';
import { identity } from 'lodash';

import { MEDIA_SM } from 'styles/media';
import AutoCompleteMemo from 'utils/AutoCompleteMemo';

const StyleFormGroup = styled(FormGroup)`
  margin: 0;

  &.bp4-form-group.bp4-inline {
    align-items: center;
  }

  .bp4-form-content {
    flex: 1;
    margin-top: ${({ label }) => (label ? '4px' : 0)};
  }

  &.form-field--readonly .bp4-form-content {
    margin-bottom: ${({ label }) => (label ? '12px' : 0)};
  }

  label.bp4-label {
    ${({ theme }) => theme.text.inputLabel};
    margin-bottom: 0;
  }

  .bp4-input-group {
    background: #f8f8f8;
    border-radius: 8px;
    height: 52px;
    box-shadow: none;
    padding: 14px;
    display: flex;
    align-items: center;

    @media ${MEDIA_SM} {
      height: 38px;
      padding: 8px;
      padding-left: 16px;
    }
  }

  &.solid .bp4-input-group {
    background: rgba(106, 112, 126, 0.0001);
    border: 1px solid #dddfe1;
  }

  &.solid.bp4-intent-danger .bp4-input-group {
    border-color: #ac2f33;
  }

  .bp4-input-group > .bp4-icon {
    position: relative;
    margin: 0;
    z-index: 0;
  }

  input.bp4-input {
    ${({ theme }) => theme.text.inputValue};

    background: transparent;
    box-shadow: none;
    padding: 0;

    &:not(:first-child) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  input.bp4-input::placeholder {
    color: #000000;
    opacity: 0.4;
  }
`;

const Value = styled.div`
  ${({ theme }) => theme.text.previewText};
`;

const RenderItem: ItemRenderer<string> = (
  t,
  { modifiers, ref, handleClick, handleFocus },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      roleStructure="listoption"
      active={modifiers.active}
      disabled={modifiers.disabled}
      elementRef={ref}
      key={t}
      onClick={handleClick}
      onFocus={handleFocus}
      text={t}
    />
  );
};

type Props = {
  className?: string;
  label?: ReactNode;
  name?: string;
  value?: string;
  disabled?: boolean;
  touched?: boolean;
  readonly?: boolean;
  error?: string;
  placeholder?: string;
  inline?: boolean;
  solid?: boolean;
  onChange?: (name: string, value: any) => void;
  onBlur?: (name: string) => void;
  prefix?: string | ReactNode;
  autoComplete: string;
};
const SuggestField: FC<Props> = ({
  className,
  name = '',
  label,
  value,
  disabled,
  touched,
  readonly,
  error,
  placeholder,
  inline,
  solid,
  onChange,
  onBlur,
  prefix,
  autoComplete,
}) => {
  const items = useMemo(
    () => AutoCompleteMemo.search(autoComplete, ''),
    [autoComplete],
  );

  const listPredict = useCallback(
    (q: string) => AutoCompleteMemo.search(autoComplete, q).slice(0, 5),
    [autoComplete],
  );

  return (
    <StyleFormGroup
      className={classnames(className, {
        solid,
        'form-field--readonly': readonly,
      })}
      label={label}
      helperText={disabled ? '' : touched && error}
      intent={touched && error && !disabled ? 'danger' : 'none'}
      inline={inline}
      disabled={disabled}
      labelFor={name}
    >
      {readonly ? (
        <Value>{value}</Value>
      ) : (
        <Suggest2
          fill
          openOnKeyDown
          query={value}
          selectedItem={value}
          disabled={disabled}
          inputValueRenderer={identity}
          onQueryChange={(q) => onChange?.(name, q)}
          popoverProps={{ matchTargetWidth: true, minimal: true }}
          items={items}
          itemListPredicate={listPredict}
          itemRenderer={RenderItem}
          onItemSelect={(t, e) => {
            onChange?.(name, t);
            e?.stopPropagation?.();
          }}
          inputProps={{
            id: name,
            name,
            leftIcon: prefix as any,
            placeholder: placeholder || '',
            onBlur: (e) => onBlur?.(e.currentTarget.name),
            onKeyDown: (e) => {
              if (['Enter'].includes(e.key)) {
                e.stopPropagation();
              }
            },
          }}
        />
      )}
    </StyleFormGroup>
  );
};

export default SuggestField;
