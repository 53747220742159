import { ChangeEvent, FC, FocusEvent, ReactNode } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { TextArea, FormGroup } from '@blueprintjs/core';
import { MEDIA_SM } from 'styles/media';

const StyleFormGroup = styled(FormGroup)`
  margin: 0;

  &.bp4-form-group.bp4-inline {
    align-items: center;
  }

  .bp4-form-content {
    flex: 1;
    margin-top: 4px;
  }

  label.bp4-label {
    ${({ theme }) => theme.text.inputLabel};
    margin-bottom: 0;
  }

  .bp4-input {
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: none;
    padding: 14px;
    display: flex;
    align-items: center;

    @media ${MEDIA_SM} {
      padding: 8px;
      padding-left: 16px;
    }
  }

  &.solid .bp4-input {
    background: rgba(106, 112, 126, 0.0001);
    border: 1px solid #dddfe1;
  }

  &.solid.bp4-intent-danger .bp4-input {
    border-color: #ac2f33;
  }

  textarea.bp4-input {
    ${({ theme }) => theme.text.inputValue};

    box-shadow: none;
    resize: none;
  }
`;

const Value = styled.div`
  margin-top: 4px;
  ${({ theme }) => theme.text.previewText};
`;

type Props = {
  className?: string;
  label?: ReactNode;
  name?: string;
  value?: string;
  disabled?: boolean;
  touched?: boolean;
  readonly?: boolean;
  error?: string;
  placeholder?: string;
  inline?: boolean;
  solid?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
};
const TextAreaField: FC<Props> = ({
  className,
  name = '',
  label,
  value,
  disabled,
  touched,
  readonly,
  error,
  placeholder,
  inline,
  solid,
  onChange,
  onBlur,
}) => (
  <StyleFormGroup
    className={classnames(className, { solid })}
    label={label}
    helperText={touched && error}
    intent={touched && error ? 'danger' : 'none'}
    inline={inline}
    disabled={disabled}
  >
    {readonly ? (
      <Value>{value}</Value>
    ) : (
      <TextArea
        fill
        growVertically
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        rows={3}
      />
    )}
  </StyleFormGroup>
);

export default TextAreaField;
