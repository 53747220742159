export const industries = [
  'Accounting',
  'Advertising / Marketing',
  'Asset Management',
  'Banking',
  'Business Management & Consultancy',
  'E-Commerce',
  'Education & Training',
  'Event Organisation',
  'Financial Planning',
  'Food & Beverage',
  'Information Technology',
  'Insurance',
  'Legal',
  'Logistics & Transport',
  'Medical & Healthcare',
  'Public Relations',
  'Real Estate',
  'Retailing',
  'Others',
];

export const companySizes = [
  '1-10',
  '10-50',
  '50-100',
  '100-200',
  '200-500',
  '500+',
];
