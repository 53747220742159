import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEO: FC = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{t('og.title')}</title>
      <meta property="og:title" content={t('og.title')} />
      <meta name="twitter:title" content={t('og.title')} />
      <meta name="description" content={t('og.description')} />
      <meta property="og:description" content={t('og.description')} />
      <meta name="twitter:description" content={t('og.description')} />
    </Helmet>
  );
};

export default SEO;
