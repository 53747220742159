import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Interval } from '@type';
import { MEDIA_SM } from 'styles/media';

const Container = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primary.label};

  .price {
    font-family: 'Super Mario Bros. 2';
    font-weight: 900;
    font-size: 47px;
    line-height: 64px;
  }

  .symbol {
    font-family: 'Open Sans';
    font-size: 24px;
    line-height: 32px;
    align-self: flex-start;
    margin-right: 8px;
  }

  .interval {
    font-family: 'Open Sans';
    font-size: 24px;
    line-height: 32px;
    align-self: flex-end;
    margin-left: 4px;
  }

  @media ${MEDIA_SM} {
    .price {
      font-size: 46px;
    }
  }
`;

type Props = {
  className?: string;
  price: number | string;
  interval: Interval;
};
const lookup: Partial<Record<Interval, string>> = {
  day: 'products.interval.day',
  week: 'products.interval.week',
  month: 'products.interval.month',
  year: 'products.interval.year',
};
const Price: FC<Props> = ({ className, price, interval }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      {Number(price) !== 0 && <div className="symbol">$</div>}
      <div className="price">
        {Number(price) === 0 ? t('products.price.free') : price}
      </div>
      {interval !== 'never' && (
        <div className="interval">{`/${t(lookup[interval] || '')}`}</div>
      )}
    </Container>
  );
};

export default Price;
