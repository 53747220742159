import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { KnowledgeListSection, BrochureSection } from 'containers';
import { MEDIA_SM, MEDIA_MD } from 'styles/media';

import topImg from 'assets/images/knowledge_top_image.webp';
import Tracker from 'utils/tracker';

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding-bottom: 120px;

  @media ${MEDIA_SM} {
    padding-bottom: 0px;
  }
`;
const FrontBlock = styled.div`
  padding-top: 170px;
  text-align: center;
  padding-bottom: 40px;

  background: url(${topImg});
  border-radius: 0px 0px 100px 100px;
  background-size: cover;

  @media ${MEDIA_MD} {
    padding-top: 120px;
    height: 500px;
  }

  @media ${MEDIA_SM} {
    height: 360px;
    border-radius: 0px 0px 40px 40px;
    padding: 110px 20px 0;
  }
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    line-height: 1.5;
  }
`;
const Description = styled.p`
  display: block;
  white-space: pre-line;
  max-width: 470px;
  margin: auto;
  ${({ theme }) => theme.text.description};
`;
const StyledKnowledgeListSection = styled(KnowledgeListSection)`
  margin: auto;
  margin-top: 98px;
  max-width: 1440px;
  scroll-margin-top: 118px;

  @media ${MEDIA_MD} {
    scroll-margin-top: 86px;

  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;

const StyledBrochureSection = styled(BrochureSection)`
  margin: auto;
  margin-top: 120px;
  max-width: 1440px;
  @media ${MEDIA_SM} {
    margin-top: 67px;
  }
`;

const KnowledgePage: FC<{}> = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Tracker.track('Visited Knowledge Page');
  }, []);
  return (
    <Container>
      <FrontBlock>
        <Header>{t('pages.knowledge.header')}</Header>
        <Title>{t('pages.knowledge.title')}</Title>
        <Description>{t('pages.knowledge.description')}</Description>
      </FrontBlock>
      <StyledKnowledgeListSection />
      <StyledBrochureSection />
    </Container>
  );
};

export default KnowledgePage;
