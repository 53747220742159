import { UserSubscription } from './subscription';

export enum AccountType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export type PublicUser = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  account_type: AccountType;
  active: boolean;
  last_login: null | string;
  createdAt: string;
  updatedAt: string;
  stripe_customer: string;
  verified: boolean;
  public_user_subscriptions: UserSubscription[];
  active_public_user_subscriptions: UserSubscription[];
  meta: {
    contactPhone?: string;
    mobilePhone?: string;
    companySize?: string;
    industry?: string;
    jobTitle?: string;
    companyAddress?: string;
    companyName?: string;
  };
  document_settings?: {
    showProfile?: boolean;
  };
  profile_pic: null | {
    id: number;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: any;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: null;
    provider: string;
    provider_metadata: null;
    createdAt: string;
    updatedAt: string;
  };
  auth_profiles: {
    id: number;
    provider: 'local';
    identifier: string;
  }[];
};

export type MeMisc = {
  id: number;
  createdAt: string;
  updatedAt: string;
  autocomplete: any;
};
