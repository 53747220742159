import { FC } from 'react';

type Props = {
  size?: number | string;
  color?: string;
};
const Bill: FC<Props> = ({ size = '24', color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.19 1.5H6.81C6.19735 1.5 5.60979 1.74337 5.17658 2.17658C4.74337 2.60979 4.5 3.19735 4.5 3.81V21.75C4.50002 21.8824 4.53508 22.0124 4.60162 22.1268C4.66816 22.2413 4.7638 22.3361 4.87883 22.4016C4.99386 22.4671 5.12418 22.501 5.25655 22.4998C5.38892 22.4987 5.51863 22.4625 5.6325 22.395L8.955 20.4L11.55 22.35C11.6798 22.4474 11.8377 22.5 12 22.5C12.1623 22.5 12.3202 22.4474 12.45 22.35L15.045 20.4L18.3675 22.395C18.4814 22.4625 18.6111 22.4987 18.7435 22.4998C18.8758 22.501 19.0061 22.4671 19.1212 22.4016C19.2362 22.3361 19.3318 22.2413 19.3984 22.1268C19.4649 22.0124 19.5 21.8824 19.5 21.75V3.81C19.5 3.19735 19.2566 2.60979 18.8234 2.17658C18.3902 1.74337 17.8027 1.5 17.19 1.5ZM18 20.4225L15.3825 18.855C15.2549 18.7793 15.1076 18.7432 14.9594 18.7512C14.8112 18.7592 14.6687 18.811 14.55 18.9L12 20.8125L9.45 18.9C9.32018 18.8026 9.16228 18.75 9 18.75C8.86539 18.75 8.73327 18.7863 8.6175 18.855L6 20.4225V3.81C6 3.59517 6.08534 3.38915 6.23724 3.23724C6.38915 3.08534 6.59517 3 6.81 3H17.19C17.4048 3 17.6109 3.08534 17.7628 3.23724C17.9147 3.38915 18 3.59517 18 3.81V20.4225Z"
      fill={color}
    />
    <path
      d="M8.25 8.25H12C12.1989 8.25 12.3897 8.17098 12.5303 8.03033C12.671 7.88968 12.75 7.69891 12.75 7.5C12.75 7.30109 12.671 7.11032 12.5303 6.96967C12.3897 6.82902 12.1989 6.75 12 6.75H8.25C8.05109 6.75 7.86032 6.82902 7.71967 6.96967C7.57902 7.11032 7.5 7.30109 7.5 7.5C7.5 7.69891 7.57902 7.88968 7.71967 8.03033C7.86032 8.17098 8.05109 8.25 8.25 8.25Z"
      fill={color}
    />
    <path
      d="M15.75 10.5H8.25C8.05109 10.5 7.86032 10.579 7.71967 10.7197C7.57902 10.8603 7.5 11.0511 7.5 11.25C7.5 11.4489 7.57902 11.6397 7.71967 11.7803C7.86032 11.921 8.05109 12 8.25 12H15.75C15.9489 12 16.1397 11.921 16.2803 11.7803C16.421 11.6397 16.5 11.4489 16.5 11.25C16.5 11.0511 16.421 10.8603 16.2803 10.7197C16.1397 10.579 15.9489 10.5 15.75 10.5Z"
      fill={color}
    />
    <path
      d="M15.75 14.25H8.25C8.05109 14.25 7.86032 14.329 7.71967 14.4697C7.57902 14.6103 7.5 14.8011 7.5 15C7.5 15.1989 7.57902 15.3897 7.71967 15.5303C7.86032 15.671 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.671 16.2803 15.5303C16.421 15.3897 16.5 15.1989 16.5 15C16.5 14.8011 16.421 14.6103 16.2803 14.4697C16.1397 14.329 15.9489 14.25 15.75 14.25Z"
      fill={color}
    />
  </svg>
);

export default Bill;
