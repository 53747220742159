import * as yup from 'yup';
import { Question } from '@type';
import { set } from 'lodash';

import i18n from './i18n';

// eslint-disable-next-line complexity
export const guessInitialValue = (
  qid: number,
  lookup: Record<number, Question>,
): any => {
  const q = lookup[qid];
  if (!q) return {};
  const { name } = q.attributes;
  if (q.attributes.type === 'Text') return { [name]: '' };
  if (q.attributes.type === 'Number') return { [name]: 0 };
  if (q.attributes.type === 'Select') return { [name]: null };
  if (q.attributes.type === 'Date') return { [name]: null };
  if (q.attributes.type === 'Signature') return { [name]: '' };
  if (q.attributes.type === 'Group') {
    if (q.attributes.groupMeta?.multiple) {
      return {
        [name]: [
          q.attributes.groupMeta?.questions?.data?.reduce(
            (memo, { id }) => ({
              ...memo,
              ...guessInitialValue(id, lookup),
            }),
            {},
          ),
        ],
      };
    }
    return {
      [name]: q.attributes.groupMeta?.questions?.data?.reduce(
        (memo, { id }) => ({
          ...memo,
          ...guessInitialValue(id, lookup),
        }),
        {},
      ),
    };
  }
  if (q.attributes.type === 'Radio') {
    return {
      [name]: [],
      ...q.attributes.radioMeta?.options.reduce((memo, o) => {
        if (!o.question?.data) return memo;
        const temp = guessInitialValue(o.question.data.id, lookup);
        Object.entries(temp).forEach(([k, v]) => {
          const subName = `${o.namePrefix || ''}${k}`;
          const main = `${name}$Nested`;
          // eslint-disable-next-line no-param-reassign
          memo = set(memo, `${main}.${subName}`, v);
        });
        return memo;
      }, {}),
    };
  }

  return { [name]: null };
};

// eslint-disable-next-line complexity
export const guessSchema = (
  qid: number,
  lookup: Record<number, Question>,
): any => {
  const q = lookup[qid];
  if (!q) return {};
  const { name, type } = q.attributes;
  const { required } = q.attributes.constraints || {};
  const mapping = {
    Text: yup.string(),
    Number: yup.number(),
    Date: yup.date().nullable(),
    Signature: yup.string(),
  } as any;
  let base = mapping[type];
  if (required && base) {
    base = base.required(i18n.t('validate.required', { ns: 'errors' }));
  }
  if (base) {
    return { [name]: base };
  }
  if (type === 'Group') {
    const sig = yup.object().shape(
      (q.attributes.groupMeta?.questions?.data || []).reduce(
        (memo, { id }) => ({
          ...memo,
          ...guessSchema(id, lookup),
        }),
        {},
      ),
    );
    if (q.attributes.groupMeta?.multiple) {
      let multiS = yup.array().of(sig);
      if (q.attributes.groupMeta?.minItem) {
        const num = q.attributes.groupMeta?.minItem;
        multiS = multiS.min(
          num,
          i18n.t('validate.required', { ns: 'errors', num }),
        );
      }
      return { [name]: multiS };
    }
    return { [name]: sig };
  }

  if (type === 'Select') {
    let single = yup.mixed();
    if (required)
      single = single.required(i18n.t('validate.required', { ns: 'errors' }));
    if (!q.attributes.selectMeta?.multiple) return { [name]: single };

    let multi = yup.array().of(yup.mixed()).nullable();
    if (required)
      multi = multi
        .min(1, i18n.t('validate.required', { ns: 'errors' }))
        .required(i18n.t('validate.required', { ns: 'errors' }));
    return { [name]: multi };
  }

  if (type === 'Radio') {
    let b = yup.array().of(yup.string());
    if (required)
      b = b
        .min(1, i18n.t('validate.required', { ns: 'errors' }))
        .required(i18n.t('validate.required', { ns: 'errors' }));
    const options = q.attributes.radioMeta?.options;
    if (!options?.length) return { [name]: b };
    const nestedLookup: Record<string, any> = {};
    options.forEach((o) => {
      if (!o.question?.data) return;
      const temp = guessSchema(o.question.data.id, lookup);
      const out: Record<string, any> = {};
      Object.entries(temp).forEach(([k, v]) => {
        out[`${o.namePrefix || ''}${k}`] = v;
      });
      nestedLookup[o.value] = out;
    });
    return {
      [name]: b,
      [`${name}$Nested`]: yup
        .object()
        .shape(
          Object.values(nestedLookup).reduce(
            (memo, x) => ({ ...memo, ...x }),
            {},
          ),
        )
        .when(name, (radioValue: any[], s: yup.ObjectSchema<any>) => {
          let keys: string[] = [];
          options.forEach((o) => {
            if (radioValue?.includes?.(o.value)) return;
            keys = keys.concat(Object.keys(nestedLookup[o.value] || {}));
          });
          return s.shape(Object.fromEntries(keys.map((k) => [k, yup.mixed()])));
        }),
    };
  }

  return {};
};

export const tryDeepEmpty = <T extends Record<string, any>>(o: T): T =>
  Object.entries(o).reduce((memo, [key, value]) => {
    let tempV: any =
      // @ts-ignore
      {
        number: 0,
        string: '',
        boolean: false,
      }[typeof value] ?? null;
    if (value instanceof Date) {
      tempV = null;
    } else if (value === null || value === undefined) {
      tempV = null;
    } else if (Array.isArray(value)) {
      tempV = [];
      if (typeof value?.[0] === 'object') {
        tempV = value.map(tryDeepEmpty);
      }
    } else if (typeof value === 'object') {
      tempV = tryDeepEmpty(value as any);
    }
    // eslint-disable-next-line no-param-reassign
    (memo[key] as any) = tempV;
    return memo;
  }, {} as T);
