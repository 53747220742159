import FetcherFactory from 'utils/FetcherFactory';
import { DocumentCategory, DocumentSubCategory } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

type DocumentInfo = {
  id: number;
  title: string;
  localizations: {
    title: string;
    locale: string;
  }[];
};
export type FetchPlanDocumentsResponse = {
  categories: (Omit<DocumentCategory, 'document_sub_categories'> & {
    document_sub_categories: (DocumentSubCategory & {
      documents: DocumentInfo;
    })[];
  })[];
  raw: DocumentInfo[];
};
const fetchPlanDocuments = async (
  id: number | string,
): Promise<FetchPlanDocumentsResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher
    .withPublicToken()
    .get(`/api/subscription-plans/${id}/documents`);
  const data = await res.json();
  return data;
};

export default fetchPlanDocuments;
