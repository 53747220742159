import FetcherFactory from 'utils/FetcherFactory';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

const unsubscribePlan = async (planId: number): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher
    .withAuth()
    .json()
    .delete(`/api/public-user-subscriptions/${planId}`, {});
  const data = await res.json();
  return data;
};

export default unsubscribePlan;
