import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MEDIA_LG } from 'styles/media';

import { WrappedPlayer } from 'components';
import BulletBlock from '../BulletBlock';

const StyledBulletBlock = styled(BulletBlock)`
  .media-box {
    background: transparent;
    justify-content: flex-start;

    .inner-media {
      position: relative;
    }
  }

  @media ${MEDIA_LG} {
    .text-box {
      background: #f5e0da;
    }
  }
`;

type Props = {
  className?: string;
};
const IndividualSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <StyledBulletBlock
      className={className}
      header={t('sections.individual.header')}
      title={t('sections.individual.title')}
      description={t('sections.individual.description')}
      Media={
        <WrappedPlayer
          url={t('sections.individual.videoUrl')}
          width="100%"
          height="100%"
        />
      }
    />
  );
};

export default IndividualSection;
