import FetcherFactory from 'utils/FetcherFactory';

import StrapiPublicFetcher from '../StrapiPublicFetcher';

const register = async ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}): Promise<any> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  // const pass = await sha256(oldPassword);
  // const newPass = await sha256(newPassword);
  const res = await fetcher.json().put(`/api/public-user/me/password`, {
    oldPassword,
    newPassword,
  });
  const data = await res.json();
  return data;
};

export default register;
