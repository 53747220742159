import { FC, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import classnames from 'classnames';

const StyledFormControl = styled(FormControl)`
  label {
    left: 44px;
  }

  label.MuiInputLabel-shrink {
    left: 0;
  }

  label.Mui-focused {
    color: ${({ theme }) => theme.success.text};
  }

  &.shrink {
    fieldset legend {
      max-width: 100%;
      transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }
  }

  &:focus-within {
    fieldset {
      border-color: ${({ theme }) => theme.success.text};
      border-width: 2px;
    }
  }

  label.Mui-error {
    color: #d32f2f;
  }

  &.error fieldset {
    border-color: #d32f2f;
  }
`;

const InputContainer = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;

  fieldset {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
  }

  fieldset legend {
    float: unset;
    width: auto;
    overflow: hidden;
    display: block;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: hidden;
    max-width: 0.01px;
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    white-space: nowrap;
  }

  fieldset legend > span {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    opacity: 0;
    visibility: visible;
  }
`;
const StyledPhoneInput = styled(PhoneInput)`
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  margin: 0;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 0 14px;

  input {
    height: 1.4375em;
    outline: none;
    border: none;
    padding: 16.5px 0;
  }
`;

type Props = {
  className?: string;
  label?: React.ReactNode;
  name?: string;
  value?: string;
  onChange?: (name: string, value: string) => void;
  onBlur?: (name: string) => void;
  error?: string;
  touched?: boolean;
};
const MuiPhoneField: FC<Props> = ({
  className,
  label,
  name = '',
  value,
  onChange,
  onBlur,
  error,
  touched,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <StyledFormControl
      fullWidth
      className={classnames(className, {
        shrink: Boolean(value) || focus,
        error: touched && Boolean(error),
      })}
      error={touched && Boolean(error)}
      focused={focus}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <InputLabel shrink={Boolean(value) || focus} focused={focus}>
        {label}
      </InputLabel>
      <InputContainer>
        <StyledPhoneInput
          defaultCountry="HK"
          value={value}
          onChange={(v: string) => onChange && onChange(name, v as string)}
          onBlur={() => onBlur?.(name)}
        />
        <fieldset>
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>
      </InputContainer>
      {touched && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default MuiPhoneField;
