import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@blueprintjs/core';
// import FreshChat from 'utils/freshChat';

import ChatWidget from 'containers/ChatWidget';

import { MEDIA_SM } from 'styles/media';

const Container = styled.div`
  text-align: center;
`;
const Header = styled.label`
  ${({ theme }) => theme.text.headerLabel};
`;
const Title = styled.h2`
  margin-top: 24px;
  white-space: pre-line;
  padding: 0 20px;
  ${({ theme }) => theme.text.header};

  @media ${MEDIA_SM} {
    margin: 0;
  }
`;
const Description = styled.p`
  display: block;
  max-width: 480px;
  margin: auto;
  margin-top: 32px;
  padding: 0 20px;
  ${({ theme }) => theme.text.description};
`;
const ContactUsButton = styled(Button)`
  margin-top: 24px;

  &.bp4-button.bp4-outlined {
    border: 2px solid #69d795;
    border-radius: 24px;
    width: 170px;
    height: 48px;

    &:disabled {
      opacity: 0.3;
    }

    .bp4-button-text {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;

      text-align: center;
      text-transform: uppercase;
      color: #69d795;
    }
  }
`;

type Props = {
  className?: string;
};
const FAQNotFoundSection: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <Header>{t('sections.faqNotFound.header')}</Header>
      <Title>{t('sections.faqNotFound.title')}</Title>
      <Description>{t('sections.faqNotFound.description')}</Description>
      <ContactUsButton
        onClick={() => ChatWidget.open()}
        large
        outlined
        text={t('actions.contactUs')}
      />
    </Container>
  );
};

export default FAQNotFoundSection;
