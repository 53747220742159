import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Question } from '@type';

export type State = {
  lookup: Record<number, Question>;
  autoCompleteLookup: Record<string, string>;
};
export const initialState: State = {
  lookup: {},
  autoCompleteLookup: {},
};

export const getQuestion =
  (id: number) =>
  (state: { questions: State }): Question | undefined =>
    state.questions.lookup[id];

export const getAutoCompleteLookup = (state: { questions: State }) =>
  state.questions.autoCompleteLookup;

export const {
  reducer: questionReducer,
  actions: { addQuestions, addQuestion, deepFetchQuestion },
} = createSlice({
  name: 'Question',
  initialState,
  reducers: {
    deepFetchQuestion(_state, _: PayloadAction<{ id: number; ttl: number }>) {},
    addQuestions(state, { payload }: PayloadAction<Question[]>) {
      const temp = { ...state.lookup };
      return {
        ...state,
        lookup: payload.reduce((memo, x) => {
          // eslint-disable-next-line no-param-reassign
          memo[x.id] = {
            ...memo[x.id],
            ...x,
          };
          return memo;
        }, temp),
        autoCompleteLookup: payload.reduce(
          (memo, x) => {
            if (x?.attributes?.auto_complete_group?.data) {
              // eslint-disable-next-line no-param-reassign
              memo[x.attributes.name] =
                x.attributes.auto_complete_group.data.attributes.label;
            }
            return memo;
          },
          {
            ...state.autoCompleteLookup,
          },
        ),
      };
    },
    addQuestion(state, { payload }: PayloadAction<Question>) {
      return {
        ...state,
        lookup: {
          ...state.lookup,
          [payload.id]: payload,
        },
        ...(Boolean(payload.attributes?.auto_complete_group) && {
          autoCompleteLookup: {
            ...state.autoCompleteLookup,
            [payload.attributes.name]:
              payload.attributes.auto_complete_group?.data.attributes.label ||
              '',
          },
        }),
      };
    },
  },
});
