import FetcherFactory from 'utils/FetcherFactory';
import { SubscriptionPlan } from '@type';
import StrapiPublicFetcher from '../StrapiPublicFetcher';

export type FetchAllPlanResponse = {
  data: SubscriptionPlan[];
};
const fetchAllPlan = async (): Promise<FetchAllPlanResponse> => {
  const fetcher = FetcherFactory.make(StrapiPublicFetcher);
  const res = await fetcher.withPublicToken().get(`/api/subscription-plans`, {
    publicationState: 'preview',
    'pagination[pageSize]': 100,
    'pagination[page]': 1,
    'populate[documents][fields][0]': 'id',
    'populate[documents][fields][1]': 'title',
    'populate[documents][populate][localizations][fields][0]': 'title',
    'populate[documents][populate][localizations][fields][1]': 'locale',
    'populate[document_categories][fields][0]': 'id',
    'populate[document_categories][fields][1]': 'name',
    'populate[document_categories][populate][localizations][fields][0]': 'name',
    'populate[document_categories][populate][localizations][fields][1]':
      'locale',
    'populate[document_sub_categories][fields][0]': 'id',
    'populate[document_sub_categories][fields][1]': 'name',
    'populate[document_sub_categories][populate][localizations][fields][0]':
      'name',
    'populate[document_sub_categories][populate][localizations][fields][1]':
      'locale',
    'populate[localizations][fields][0]': 'name',
    'populate[localizations][fields][1]': 'description',
    'populate[localizations][fields][2]': 'features',
    'populate[localizations][fields][3]': 'locale',
  });
  const data = await res.json();
  return data;
};

export default fetchAllPlan;
